<template>
	<v-menu offset-y>
		<template v-slot:activator="{ on, attrs }">
			<v-btn
				depressed
				rounded
				v-bind="attrs"
				v-on="on"
				class="mx-5 website-header-btn"
			>
				<v-icon left>
					{{ $vuetify.icons.values.earth }}
				</v-icon>
				{{ selectedLangName }}
			</v-btn>
		</template>
		<v-list>
			<v-list-item
				v-for="(item, index) in locales"
				:key="index"
				link
				@click="page_language = item.code"
			>
				<v-list-item-title>{{ item.name }}</v-list-item-title>
			</v-list-item>
		</v-list>
	</v-menu>
</template>

<script>
import { mapGetters } from "vuex";
import { i18n } from "@/plugins/i18n";

export default {
	name: "WebsiteLocaleSwitcher",

	data() {
		return {
			i18n,
			page_language: null,
		};
	},

	computed: {
		...mapGetters({
			userLang: "user/getLanguage",
		}),

		locales() {
			return this.$defines.LOCALES;
		},

		selectedLangName() {
			return this.locales.find((item) => item.code == i18n.locale).name;
		},
	},

	watch: {
		page_language() {
			this.updateLanguage();
		},
	},

	mounted() {
		this.setRouteLanguage();
	},

	methods: {
		async setRouteLanguage() {
			try {
				this.$loader.start();

				// Parse the language param
				const queryString = window.location.search;
				const urlParams = new URLSearchParams(queryString);

				let route_locale = urlParams.get("lang");

				// Update user's language
				await this.$store.commit("user/UPDATE_LOCALE", {
					lang: route_locale,
				});

				// Update local language
				this.page_language = route_locale ?? i18n.locale;
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		async updateLanguage() {
			try {
				this.$loader.start();

				await this.$store.commit("user/UPDATE_LOCALE", {
					lang: this.page_language,
				});

				this.$router.push({
					name: this.$route.name,
					query: {
						lang: this.page_language,
					},
				});
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},
	},
};
</script>
