<template>
	<v-main>
		<!-- Fixed Side Navigation Bar -->
		<TheSideNav></TheSideNav>

		<!-- Router view -->
		<v-container fluid :style="styleObj" :class="container_classes">
			<router-view id="the-view-port" :key="$route.fullPath"></router-view>
		</v-container>
	</v-main>
</template>

<script>
import TheSideNav from "@/components/layout/TheSideNav";
import { layoutMixin } from "@/mixins/layoutMixin.js";

export default {
	name: "ProjectsLayout",

	mixins: [layoutMixin],

	components: {
		TheSideNav,
	},

	computed: {
		styleObj() {
			return {
				[this.$vuetify.rtl ? "paddingRight" : "paddingLeft"]: "100px",
				height: this.view_port_height,
			};
		},
	},
};
</script>
