import { callApi } from "./manager";

export const research = {
	getMenu: () => {
		return callApi(`/infra/menu/`, "get");
	},

	listMenus: () => {
		return callApi(`/infra/menus/`, "get");
	},

	listPlans: () => {
		return callApi(`/plan/plans/`, "get");
	},

	getLocales: () => {
		return callApi(`/infra/languages`, "get");
	},

	listPaperSchemes: () => {
		return callApi("/integration/templates", "get");
	},
};
