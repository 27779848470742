import { callApi } from "./manager";

export const standardMindmap = {
	list: (payload) => {
		return callApi(`/infra/mindmap?category=${payload.category}`, "get");
	},

	create: (payload) => {
		return callApi(`/infra/mindmap/`, "post", payload);
	},

	get: (payload) => {
		return callApi(`/infra/mindmap/${payload.id}`, "get");
	},

	getOfStudent: (payload) => {
		return callApi(`/infra/mindmap?user_id=${payload.student_id}`, "get");
	},

	save: (payload) => {
		return callApi(`/infra/mindmap/${payload.id}/`, "put", payload);
	},

	delete: (payload) => {
		return callApi(`/infra/mindmap/${payload.id}/`, "delete");
	},
};
