export const gatherInformation = [
	{
		path: "/research/gather_information",
		name: "GatherInformation",
		component: () =>
			import(
				/* webpackChunkName:"page.dq-gi"*/ "@/pages/research/modules/defineQuestion/stages/GatherInformation"
			),
		meta: {
			layout: "AppLayout",
			requiresAuth: true,
			header: {
				title: "app.gather_info.title",
				subtitle: "app.gather_info.subtitle",
			},
			footer: {
				component: "Buttons",
				data: {
					rightBtn: {
						title: "app.finish",
						event_name: "finish-gather-info",
					},
				},
			},
		},
	},
];
