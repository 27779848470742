import LifeLessons from "@/pages/research/modules/reflectJourney/stages/LifeLessons.vue";

export const basicRoutes = [
	{
		path: "/",
		redirect: "/research/home",
	},
	{
		path: "/research/home",
		name: "Home",
		component: () =>
			import(/* webpackChunkName:"page.h"*/ "@/pages/website/Index.vue"),
		meta: {
			layout: "WebsiteLayout",
			requiresAuth: false,
		},
	},
	{
		path: "/research/programs",
		name: "Programs",
		component: () =>
			import(
				/* webpackChunkName:"page.pr"*/ "@/pages/website/Programs.vue"
			),
		meta: {
			layout: "WebsiteLayout",
			requiresAuth: false,
		},
	},
	{
		path: "/research/accessibiliy",
		name: "Accessibility",
		component: () =>
			import(
				/* webpackChunkName:"page.ac"*/ "@/pages/website/Accessibiliy.vue"
			),
		meta: {
			layout: "WebsiteLayout",
			requiresAuth: false,
		},
	},
	// FIXME::This is temporary
	{
		path: "/research/life_lessons",
		name: "LifeLessons",
		component: LifeLessons,
		meta: {
			layout: "AppLayout",
			requiresAuth: true,
			header: {
				title: "app.lessons_for_life.title",
				subtitle: "app.lessons_for_life.subtitle",
			},
			footer: {
				component: "Buttons",
				data: {
					rightBtn: {
						title: "app.next",
						event_name: "finish-life-lessons",
					},
				},
			},
		},
	},
];
