import { callApi } from "./manager";

export const query = {
	list: (payload) => {
		return callApi(`/infra/query/`, "get", payload);
	},

	create: (payload) => {
		return callApi(`/infra/query/`, "post", payload);
	},

	get: (payload) => {
		return callApi(`/infra/query/${payload.query_id}`, "get");
	},
};
