<template>
	<v-row no-gutters>
		<v-col class="text-end">
			<AppButton
				v-if="rightBtn.show"
				:label="rightBtn.title"
				:icon="rightBtn.icon || null"
				:disabled="rightBtn.disabled"
				@click="$eventBus.$emit(rightBtn.event_name)"
			></AppButton>
		</v-col>
	</v-row>
</template>

<script>
import { mapState } from "vuex";

export default {
	name: "ResearchPlanningFooter",

	computed: {
		...mapState({
			current_module: (state) => state.research.current_module,
		}),

		rightBtn() {
			let btnObj = {
				title: "app.i_confirm_timeline",
				disabled: false,
				show: true,
				event_name: `${this.current_module}-confirm-module-plan`,
				icon: this.$vuetify.rtl ? "left_chevron" : "right_chevron",
			};

			return btnObj;
		},
	},
};
</script>
