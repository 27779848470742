<template>
	<div align="center">
		<component
			:is="currentComponent"
			class="class-based-color"
			:color="color"
			:scale="scale"
		></component>
		<div v-if="msg">{{ msg }}</div>
	</div>
</template>

<script>
import VueLoadersBallSpinFadeLoader from "vue-loaders/dist/loaders/ball-spin-fade-loader";
import VueLoadersBallPulseSync from "vue-loaders/dist/loaders/ball-pulse-sync";
import "vue-loaders/dist/vue-loaders.css";

export default {
	name: "Loading",

	props: {
		type: {
			default: "pulse",
		},
		scale: {
			default: 1,
		},
		color: {
			default: "blue",
		},
		msg: {
			required: false,
		},
	},

	components: {
		[VueLoadersBallPulseSync.component.name]: VueLoadersBallPulseSync.component,
		[VueLoadersBallSpinFadeLoader.component.name]:
			VueLoadersBallSpinFadeLoader.component,
	},

	computed: {
		currentComponent() {
			switch (this.type) {
				case "spin":
					return "vue-loaders-ball-spin-fade-loader";
				default:
					return "vue-loaders-ball-pulse-sync";
			}
		},
	},
};
</script>

<style></style>
