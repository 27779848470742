<template>
	<TheHeaderInterface
		:title="header.title"
		:subtitle="header.subtitle"
	></TheHeaderInterface>
</template>

<script>
import { mapState } from "vuex";
import TheHeaderInterface from "@/components/layout/TheHeaderInterface.vue";

export default {
	name: "InterviewHeader",

	data() {
		return {
			header: {
				title: null,
				subtitle: null,
			},
		};
	},

	components: {
		TheHeaderInterface,
	},

	watch: {
		current_tab: function(newVal, oldVal) {
			this.prepareHeader();

			if (newVal !== oldVal) {
				this.$eventBus.$emit("calculate-view-port-height");
			}
		},
	},

	computed: {
		...mapState({
			current_tab: (state) => state.interview.current_tab,
		}),
	},

	mounted() {
		this.prepareHeader();
	},

	methods: {
		prepareHeader() {
			if (this.current_tab === "plan-interview") {
				this.header.title = "app.interviews.plan_interview.header.title";

				this.header.subtitle = "app.interviews.plan_interview.subtitle.title";
			}
			if (this.current_tab === "document-interview") {
				this.header.title = "app.interviews.document_interview.header.title";

				this.header.subtitle =
					"app.interviews.document_interview.subtitle.title";
			}
		},
	},
};
</script>
