<template>
	<v-expansion-panels flat>
		<v-expansion-panel :class="prop_class">
			<v-expansion-panel-header class="pa-4">
				<template v-slot:actions>
					<v-icon color="wr_blue_1" class="expansion-left-icon-wr" size="24"
						>$vuetify.icons.values.arrow_down</v-icon
					>
				</template>

				<div
					class="font-weight-bold subtitle-1 expansion-left-icon-header-wr mx-2"
				>
					{{ $t("app.insights") }}
				</div>
			</v-expansion-panel-header>

			<v-expansion-panel-content>
				<v-card outlined :class="['mt-4 greyLighten4']">
					<v-card-text v-for="(item, index) in notes" :key="index" class="pt-0">
						<v-textarea
							rows="3"
							v-model="item.note"
							outlined
							color="greenAccent2"
							background-color="white"
							:label="$t('app.placeholder.insert_any_note_here')"
							:append-icon="editAccess && $vuetify.icons.values.delete"
							@click:append="
								(selected_note_id = item.id), (delete_dialog = true)
							"
							hide-details
							:disabled="!editAccess || isWorkRoute"
							@blur="updateNote(item)"
						></v-textarea>

						<a
							v-if="item.note && rules.isValidURL(item.note) == true"
							:href="item.note"
							target="_blank"
						>
							<div>
								{{ $t("app.access_the_note") }}
								<v-icon x-small color="wr_blue_1">{{
									$vuetify.icons.values[
										$vuetify.rtl ? "left_arrow" : "right_arrow"
									]
								}}</v-icon>
							</div>
						</a>
					</v-card-text>

					<TheConfirmation
						v-model="delete_dialog"
						:dialog="delete_dialog"
						@cancel="delete_dialog = false"
						@confirm="removeNote()"
					></TheConfirmation>

					<v-card-actions v-if="editAccess && !isWorkRoute">
						<AppButton
							label="app.add_note"
							color="success"
							icon="plus"
							:prop_class="['ms-3']"
							@click="createNote()"
						></AppButton>
					</v-card-actions>
				</v-card>
			</v-expansion-panel-content>
		</v-expansion-panel>
	</v-expansion-panels>
</template>

<script>
import TheConfirmation from "@/components/layout/TheConfirmation";
import rules from "@/utils/validation";

export default {
	name: "SourceNotes",

	props: {
		sourceId: {
			required: true,
			type: Number,
		},
		prop_class: {
			default: null,
		},
		editAccess: {
			default: true,
		},
	},

	data() {
		return {
			rules,
			notes: [],
			selected_note_id: null,
			delete_dialog: false,
		};
	},

	components: {
		TheConfirmation,
	},

	computed: {
		isWorkRoute() {
			return this.$route.name === "SourcesWork";
		},
	},

	mounted() {
		this.getNotes();
	},

	methods: {
		async getNotes() {
			try {
				this.$loader.start();

				let res = await this.$store.dispatch("sourceNote/list", {
					source_id: this.sourceId,
				});
				this.notes = res;
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		async createNote() {
			try {
				this.$loader.start();

				// Crete note
				await this.$store.dispatch("sourceNote/create", {
					source_id: this.sourceId,
					note: null,
				});

				await this.getNotes();
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		async updateNote(payload) {
			try {
				// Update note
				await this.$store.dispatch("sourceNote/update", {
					source_note_id: payload.id,
					note: this.$xss.sanitize(payload.note),
				});
			} catch (error) {
				this.$announce.error(error);
			}
		},

		async removeNote() {
			try {
				this.$loader.start();

				if (!this.selected_note_id) return;

				// Remove note
				await this.$store.dispatch("sourceNote/delete", {
					source_note_id: this.selected_note_id,
				});

				this.selected_note_id = null;

				this.delete_dialog = false;

				this.getNotes();
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},
	},
};
</script>
