var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"900","height":"800","persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-tooltip',_vm._g(_vm._b({attrs:{"left":_vm.$vuetify.rtl,"right":!_vm.$vuetify.rtl},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"cursor-wr",on:{"click":function($event){$event.stopPropagation();_vm.dialog = true}}},'v-icon',attrs,false),on),[_vm._v(_vm._s(_vm.$vuetify.icons.values.video))])]}}],null,true)},'v-tooltip',attrs,false),on),[_c('span',[_vm._v(_vm._s(_vm.$t("app.watch_video")))])])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[(_vm.dialog)?_c('AppDialog',{attrs:{"heading":{
			label: 'app.instructions',
			color: 'amberDarken1',
		}},on:{"close":function($event){_vm.dialog = false}},scopedSlots:_vm._u([{key:"dialog-content",fn:function(){return [_c('iframe',{staticClass:"wistia_embed",attrs:{"src":("https://fast.wistia.net/embed/iframe/" + _vm.video_id + "?videoFoam=true"),"title":"Interface Explanation M1S1 Video","allow":"autoplay; fullscreen","allowtransparency":"true","frameborder":"0","scrolling":"no","name":"wistia_embed","msallowfullscreen":"","width":"100%","height":"100%"}})]},proxy:true}],null,false,1598941285)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }