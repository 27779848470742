<template>
	<TheHeaderInterface :title="title" :subtitle="subtitle"></TheHeaderInterface>
</template>

<script>
import { mapState } from "vuex";
import TheHeaderInterface from "@/components/layout/TheHeaderInterface.vue";

export default {
	name: "ResearchPlanningHeader",

	components: {
		TheHeaderInterface,
	},

	watch: {
		current_tab: function(newVal, oldVal) {
			if (newVal !== oldVal) {
				this.$eventBus.$emit("calculate-view-port-height");
			}
		},
	},

	computed: {
		...mapState({
			current_tab: (state) => state.planAndManage.current_tab,
			current_module: (state) => state.research.current_module,
		}),

		title() {
			if (this.current_tab === "calendar") {
				return "app.confirm_timeline.calendar.title";
			}
			return "app.confirm_timeline.title";
		},

		subtitle() {
			if (this.current_tab === "calendar") {
				return "app.confirm_timeline.step.calendar.subtitle";
			}
			switch (this.current_module) {
				case 2:
					return "app.define_question.timeline.subtitle";
				case 3:
					return "app.gather_sources.timeline.subtitle";
			}
		},
	},
};
</script>
