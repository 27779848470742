const path_prefix = "/research/tools/knowledge_organizers/literature";

export const literature = [
	{
		path: `${path_prefix}/index`,
		name: "Literature",
		component: () =>
			import(
				/* webpackChunkName:"page.ko.literature"*/ "@/pages/users/student/tools/knowledgeOrganizers/literature/Index.vue"
			),
		meta: {
			layout: "AppLayout",
			requiresAuth: true,
			header: {
				title: "app.ko.literature.title",
				subtitle: "",
			},
			footer: {
				component: "ToolsKnowledgeOrganizers",
			},
		},
	},
	{
		path: `${path_prefix}/genres`,
		name: "Genres",
		component: () =>
			import(
				/* webpackChunkName:"page.ko.literature.genres"*/ "@/pages/users/student/tools/knowledgeOrganizers/literature/genres/Index.vue"
			),
		meta: {
			layout: "AppLayout",
			requiresAuth: true,
			header: {
				title: "app.ko.literature.genres.header.title",
				subtitle: "",
			},
			footer: {
				component: "ToolsKnowledgeOrganizers",
			},
		},
	},
];
