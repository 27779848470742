// Vue instance
import Vue from "vue";

// App file
import App from "./App.vue";

// Service workers
import "./registerServiceWorker";

// Vue router
import { router } from "./router";

// Vuex store
import { store } from "./store";

// Vuetify
import { vuetify } from "@/plugins/vuetify";

// i18n localization
import { i18n } from "@/plugins/i18n";

// Used packages
import packages from "@/plugins/packages";

// Custom filters
import { filters } from "@/utils/filters";

// Custom plugins
import plugins from "@/plugins/index";

Vue.config.productionTip = false;

new Vue({
	router,
	store,
	vuetify,
	i18n,
	render: (h) => h(App),
}).$mount("#app");
