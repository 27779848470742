export const sourceEvaluation = [
	{
		path: "/research/source_evaluation",
		name: "SourceEvaluation",
		component: () =>
			import(
				/* webpackChunkName:"page.gs-se"*/ "@/pages/research/modules/gatherSources/stages/SourceEvaluation.vue"
			),
		meta: {
			layout: "AppLayout",
			requiresAuth: true,
			header: {
				title: "app.source_evaluation.title",
				subtitle: "app.source_evaluation.subtitle",
			},
			footer: {
				component: "Buttons",
				data: {
					rightBtn: {
						title: "app.to_next_stage",
						event_name: "finish-source-evaluation",
					},
				},
			},
		},
	},
];
