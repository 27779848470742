export const tools = [
	{
		path: `/research/mentor/tools/index`,
		name: "MentorTools",
		component: () =>
			import(
				/* webpackChunkName:"page.m-tools"*/ "@/pages/users/mentor/tools/Index.vue"
			),
		meta: {
			layout: "MentorLayout",
			requiresAuth: true,
			header: {
				title: "app.tools.title",
				subtitle: "app.dashboard.subtitle",
			},
		},
	},
];
