export const calendar = [
	{
		path: "/research/calendar/index",
		name: "ResearchCalendar",
		component: () =>
			import(
				/* webpackChunkName:"page.calendar"*/ "@/pages/users/student/calendar/Index.vue"
			),
		meta: {
			layout: "AppLayout",
			requiresAuth: true,
			header: {
				title: "app.calendar.title",
				subtitle: "app.calendar.subtitle",
			},
		},
	},
];
