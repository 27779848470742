<template>
	<v-main>
		<!-- Fixed Side Navigation Bar -->
		<TheSideNav></TheSideNav>

		<!-- Header -->
		<TheHeader v-if="$route.meta.header"></TheHeader>

		<!-- Router view -->
		<v-container fluid :style="styleObj" :class="container_classes">
			<router-view
				id="the-view-port"
				:key="$route.fullPath"
				class="pa-4"
			></router-view>
		</v-container>

		<!-- Footer -->
		<TheFooter v-if="$route.meta.footer"></TheFooter>
	</v-main>
</template>

<script>
import TheHeader from "@/components/layout/TheHeader";
import TheFooter from "@/components/layout/TheFooter";
import { layoutMixin } from "@/mixins/layoutMixin.js";
import TheSideNav from "@/components/layout/TheSideNav";

export default {
	name: "UnrestrictedLayout",

	mixins: [layoutMixin],

	components: {
		TheHeader,
		TheFooter,
		TheSideNav,
	},

	computed: {
		styleObj() {
			return {
				[this.$vuetify.rtl ? "paddingRight" : "paddingLeft"]: "100px",
				height: this.view_port_height,
			};
		},
	},

	created() {
		this.$eventBus.$on("back-to-home", () => {
			this.$router.push({
				name: "Home",
			});
		});
	},

	beforeDestroy() {
		this.$eventBus.$off("back-to-home");
	},
};
</script>
