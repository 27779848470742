import axios from "axios";
import { getFullUrlForApiReq, isArrayHavingItem } from "@/utils/helpers";
import { store } from "@/store";
import { router } from "@/router";
import { DEFINES } from "@/utils/defines";

const buildHeaders = () => {
	let token = store.state.auth.token;
	// FIXME:: Find a better way to do this-
	if (
		isArrayHavingItem(DEFINES.UNAUTHORIZED_ROUTES, router.currentRoute.name)
	) {
		token = "3078416be5235027a4b2e5309d261713b42213b4	";
	}

	return {
		headers: {
			Authorization: "Token " + token,
		},
	};
};

const handleError = async (api_err) => {
	// if response is undefined then throw err as it is
	if (api_err.response == undefined) throw api_err;

	/**
	 * ELSE, PARSE THE ERROR FROM RESPONSE-------------
	 * Pick the first property from response data object because
	 * there is not a fix property in which api error will be present.
	 * The err message can be inside any property, i.e-
	 * {error_code: 'error},
	 * {error: 'error},
	 * {message: 'error},
	 */
	let final_err = Object.values(api_err.response.data)[0];

	// special errors to logout
	let TOKEN_EXPIRED = "Token has expired";
	let INVALID_TOKEN = "Invalid token";
	if ([TOKEN_EXPIRED, INVALID_TOKEN].includes(final_err)) {
		await store.dispatch("auth/logout");
	}
	throw new Error(`app.ntfy.err.${final_err}`);
};

async function callAxiosApiForS3Deletion(url, headers, data) {
	try {
		let response = await axios.request({
			method: "DELETE",
			url,
			headers,
			data,
		});
		return response.data;
	} catch (error) {
		return handleError(error);
	}
}

async function callApi(url, httpMethod, payload = {}) {
	let req_url = getFullUrlForApiReq(url);
	let headers = isArrayHavingItem(req_url.toString(), "auth")
		? null
		: buildHeaders().headers;

	// For s3-delete api request, handle diff syntax
	if (req_url.includes("s3access") && httpMethod == "delete") {
		return callAxiosApiForS3Deletion(req_url, headers, payload);
	}

	// Else, handle common axios request format for all http methods
	try {
		let response = await axios({
			method: httpMethod,
			url: req_url,
			headers,
			data: payload,
		});
		return response.data;
	} catch (error) {
		return handleError(error);
	}
}

export { callApi, buildHeaders };
