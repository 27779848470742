<template>
	<v-row no-gutters>
		<v-col>
			<AppButton
				v-if="leftBtn.show"
				:label="leftBtn.title"
				:left_icon="true"
				:icon="leftBtn.icon || null"
				:disabled="leftBtn.disabled"
				@click="redirectToBack()"
			></AppButton>
		</v-col>
	</v-row>
</template>

<script>
import { isArrayHavingItem } from "@/utils/helpers";

export default {
	name: "ToolsFooter",

	computed: {
		leftBtn() {
			let btnObj = {
				title: "app.back",
				disabled: false,
				show: true,
				icon: this.$vuetify.rtl ? "right_chevron" : "left_chevron",
			};
			return btnObj;
		},
	},

	methods: {
		redirectToBack() {
			try {
				let name = "Tools";

				switch (this.$route.name) {
					case "Interview":
						name = "InterviewsList";
						break;
					case "Observation":
						name = "ObservationsList";
						break;
					case "Meeting":
						name = "MeetingsList";
						break;
					case "WizardMindmapExplore":
						name = this.$route.query.collaborative_map
							? "ToolsWizardMindmap"
							: "WizardMindmapsList";
						break;
					case "WizardMindmapsList":
						name = "ToolsWizardMindmap";
						break;
					case "StandardMindmapExplore":
						name = "StandardMindmapList";
						break;
					case "MeetingSummaryExplore":
						return this.$eventBus.$emit("ms-validate-and-redirect-back");
				}

				this.$router.push({ name: name });
			} catch (error) {
				this.$announce.error(error);
			}
		},
	},
};
</script>
