<template>
	<v-menu
		v-model="menu"
		:disabled="disabled"
		ref="menu"
		offset-y
		:close-on-content-click="false"
		:return-value.sync="date"
		transition="scale-transition"
		min-width="290px"
	>
		<template v-slot:activator="{ on, attrs }">
			<v-text-field
				:value="formattedDate"
				v-bind="attrs"
				v-on="on"
				:solo="solo"
				flat
				hide-details
				readonly
				:disabled="disabled"
				:outlined="outlined"
				:label="$t(placeholder)"
				:dense="dense"
				:append-icon="append_icon"
				color="greenAccent2"
				background-color="white"
			></v-text-field>
		</template>
		<v-date-picker
			v-model="date"
			no-title
			scrollable
			:locale="lang"
			:first-day-of-week="0"
			:allowed-dates="allowedDates"
		>
			<AppButton
				label="app.okay"
				color="info"
				:text="true"
				@click="$refs.menu.save(date)"
			></AppButton>
			<v-spacer></v-spacer>

			<AppButton
				label="app.cancel"
				color="black"
				:text="true"
				@click="menu = false"
			></AppButton>
		</v-date-picker>
	</v-menu>
</template>

<script>
import moment from "moment";
import { datePickerLocaleMixin } from "@/mixins/datePickerLocaleMixin";

export default {
	name: "DatePicker",

	mixins: [datePickerLocaleMixin],

	props: {
		prop_date: {
			required: true,
		},
		disabled: {
			default: false,
		},
		outlined: {
			default: false,
		},
		placeholder: {
			default: "app.placeholder.select_date",
		},
		dense: {
			default: false,
		},
		append_icon: {
			default: null,
		},
		date_condition: {
			default: null,
		},
		solo: {
			default: false,
		},
	},

	data() {
		return {
			menu: false,
		};
	},

	watch: {
		date(newVal, oldVal) {
			if (newVal !== oldVal) {
				this.$emit("update-value");
			}
		},
	},

	computed: {
		formattedDate() {
			return this.formatDate(this.date);
		},

		date: {
			get() {
				return this.prop_date;
			},
			set(value) {
				this.$emit("input", value);
			},
		},
	},

	methods: {
		formatDate(date) {
			if (!date) return null;
			return moment(date).format("DD/MM/YYYY");
		},

		allowedDates(val) {
			if (!this.date_condition) return [];

			if (this.date_condition === "after-today") {
				return val >= new Date().toISOString().substr(0, 10);
			}
		},
	},
};
</script>
