<template>
	<v-dialog :value="dialog" scrollable width="550" persistent>
		<AppDialog
			v-model="loading"
			:heading="{
				label: 'app.sign_in',
			}"
			:action="{
				label: 'app.sign_in',
				event: 'login',
			}"
			@close="$emit('close')"
			@login="login()"
		>
			<template v-slot:dialog-content>
				<div class="mt-10">
					<v-form ref="form">
						<v-card flat>
							<v-card-text class="pa-0">
								<!-- Username -->
								<v-text-field
									v-model.trim="auth.username"
									:rules="[rules.required]"
									outlined
									color="greenAccent2"
									background-color="white"
									:prepend-inner-icon="$vuetify.icons.values.user"
									:placeholder="$t('app.placeholder.username')"
								></v-text-field>

								<!-- Password -->
								<v-text-field
									v-model="auth.password"
									:rules="[rules.required]"
									outlined
									color="greenAccent2"
									background-color="white"
									:prepend-inner-icon="$vuetify.icons.values.lock"
									:append-icon="
										$vuetify.icons.values[show_password ? 'eye' : 'eye_off']
									"
									:type="show_password ? 'text' : 'password'"
									:placeholder="$t('app.placeholder.password')"
									@click:append="show_password = !show_password"
								></v-text-field>
							</v-card-text>
						</v-card>
					</v-form>
				</div>
			</template>
		</AppDialog>
	</v-dialog>
</template>

<script>
import rules from "@/utils/validation";
import { mapGetters, mapState } from "vuex";
import { projectsManageMixin } from "@/mixins/projectsManageMixin.js";
import AppDialog from "@/components/ui/AppDialog.vue";

export default {
	name: "TheLogin",

	mixins: [projectsManageMixin],

	props: {
		dialog: {
			required: true,
		},
	},

	data() {
		return {
			rules,
			loading: false,
			auth: {
				username: null,
				password: null,
			},
			show_password: false,
		};
	},

	components: {
		AppDialog,
	},

	computed: {
		...mapGetters({
			isAuthorized: "auth/isAuthorized",
			userLang: "user/getLanguage",
			isMentor: "user/isMentor",
		}),

		...mapState({
			projects: (state) => state.user.projects,
		}),
	},

	methods: {
		async login() {
			try {
				this.loading = true;

				// Validate the form
				if (!this.$refs.form.validate()) return;

				// Send API request to login
				await this.$store.dispatch("auth/login", this.auth);

				await this.afterLoggedIn();
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.loading = false;
			}
		},

		async afterLoggedIn() {
			if (!this.isAuthorized) {
				throw new Error("app.ntfy.err.not_authenticated", {
					cause: "werCustom",
				});
			}

			// Set user's all inbox messages to state
			await this.$store.dispatch("notification/list", {
				type: this.$defines.NOTIFICATION_TYPE_INBOX,
			});

			// Fetch all projects of that user
			await this.$store.dispatch("user/listProjects");

			// If user has more than one projects, redirect to projects dashboard
			// Else, proceed for the default project
			this.projects && this.projects.length > 1 && !this.isMentor
				? await this.$router.push({
						name: "Projects",
				  })
				: await this.setUpDataForDashboard();

			// Announce success login
			this.$announce.success("app.ntfy.succ.logged_in");
		},
	},
};
</script>
