// INITIATE STATE
function initialState() {
	return {
		list: [],
		current_student: null,
	};
}

// STATE
const state = initialState();

// GETTERS
const getters = {};

// ACTIONS
const actions = {
	// async list(context) {
	// 	let response = await API.list();
	// 	context.commit("SET_LIST", response);
	// },
};

// MUTATIONS
const mutations = {
	SET_LIST: (state, payload) => {
		state.list = payload;
	},

	SET_CURRENT_STUDENT: (state, payload) => {
		state.current_student = payload.student;
	},

	RESET_STATE: (state) => {
		const s = initialState();
		Object.keys(s).forEach((key) => {
			state[key] = s[key];
		});
	},
};

// Export module
export const student = {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
