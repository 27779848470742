import Vue from "vue";
import VueRouter from "vue-router";
import { store } from "@/store";
import { routes } from "./routes/index";

Vue.use(VueRouter);

export const router = new VueRouter({
	mode: "history",
	base: Vue.prototype.$environments.base_url,
	routes: routes,
});

// Handle navigation duplication for router push (Globally)
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch((error) => {});
};

// Routes Middleware
router.beforeEach(async (to, from, next) => {
	// Decide which layout to use as per route changes.
	var oldLayout = store.getters["globals/layout"];
	var newLayout = to.meta.layout || "NoLayout";
	if (newLayout != oldLayout) {
		// Set-up new layout
		store.dispatch(
			"globals/setLayout",
			{
				layout: newLayout,
			},
			Vue.prototype.$defines.USE_ROOT,
		);
	}

	// Check if user is authorized or not
	if (to.matched.some((record) => record.meta.requiresAuth)) {
		if (!store.getters["auth/isAuthorized"]) {
			return next({
				name: "Home",
			});
		}
	}

	// If route is agora then store its previous route
	if (to.name && to.name.toLowerCase().indexOf("agora") !== -1) {
		store.commit(
			"agora/SET_BACK_ROUTE",
			{
				back_route: from.name,
			},
			Vue.prototype.$defines.USE_ROOT,
		);
	}

	next();
});
