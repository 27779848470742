import { DEFINES } from "@/utils/defines";
import { environments } from "@/environment/environment.js";
import { languages } from "@/utils/languages.js";

const getFullUrlForApiReq = (path) => {
	let endpoint = environments.base_api_url;
	return endpoint + path;
};

const getFullUrlForEtherpadApiReq = (path) => {
	let endpoint = environments.etherpad_base_api_url + "/api/1.2.16";
	return endpoint + path;
};

const isArrayHavingItem = (array, item) => {
	return array.includes(item);
};

const isTypeImage = (url) => {
	let image_extensions = ["jpeg", "jpg", "gif", "png", "svg"];
	let extension = url
		.split(/[#?]/)[0]
		.split(".")
		.pop()
		.trim();

	return isArrayHavingItem(
		image_extensions,
		extension.toString().toLowerCase(),
	);
};

const paginationLength = (numerator, denominator) => {
	if (denominator !== 0) {
		return Math.ceil(numerator / denominator);
	}
};

const getFileNameFromURL = (url) => {
	return url.substring(url.lastIndexOf("/") + 1);
};

const replaceTextWithDots = (text, start, end) => {
	return text.replace(text.substring(start, end), "...");
};

const convertNumberToAlphabet = (num) => {
	// if num is 1 then result is 'A'
	return String.fromCharCode(num + 64);
};

const filterArray = (arr, property, matcher, operation = false) => {
	if (!Array.isArray(arr)) return null;

	return operation
		? arr.filter((item) => {
				return item[property] == matcher;
		  })
		: arr.filter((item) => {
				return item[property] !== matcher;
		  });
};

const findFromArray = (arr, property, matcher) => {
	if (!Array.isArray(arr) || !arr.length) return null;

	return arr.find((item) => {
		return item[property] == matcher;
	});
};

const convertToNum = (item) => {
	return Number(item);
};

const findElIndex = (arr, key, matcher) => {
	if (!Array.isArray(arr)) return null;

	return arr.findIndex((obj) => obj[key] == matcher);
};

const getFullDayName = (item) => {
	var day = findFromArray(DEFINES.DAYS_OF_WEEK, "id", item);
	return day != undefined ? day.value : "";
};

const getDayKey = (id) => {
	var day = findFromArray(DEFINES.DAYS_OF_WEEK, "id", id);
	return day != undefined ? day.key : "";
};

const getElHeight = (id) => {
	let el = document.getElementById(id);
	return el ? el.offsetHeight : 0;
};

const generateRandomColor = () => {
	let letters = "BCDEF".split("");
	let color = "#";
	for (let i = 0; i < 6; i++) {
		color += letters[Math.floor(Math.random() * letters.length)];
	}
	return color;
};

const wordCount = (text) => {
	let res = [];
	let str = text.replace(/[\t\n\r\.\?\!]/gm, " ").split(" ");
	str.map((s) => {
		let trimStr = s.trim();
		if (trimStr.length > 0) {
			res.push(trimStr);
		}
	});
	return res.length;
};

const isJson = (json) => {
	try {
		JSON.parse(json);
		return true;
	} catch (error) {
		return false;
	}
};

const flatArrayOfObjects = (arr, key, result = []) => {
	arr.forEach((item) => {
		result.push(item);
		if (item[key]) {
			flatArrayOfObjects(item[key], key, result);
		}
	});
	return result;
};

const isColorDark = (color) => {
	if (!color) return true;

	var color = color.charAt(0) === "#" ? color.substring(1, 7) : color;
	var r = parseInt(color.substring(0, 2), 16); // hexToR
	var g = parseInt(color.substring(2, 4), 16); // hexToG
	var b = parseInt(color.substring(4, 6), 16); // hexToB
	var uicolors = [r / 255, g / 255, b / 255];
	var c = uicolors.map((col) => {
		if (col <= 0.03928) {
			return col / 12.92;
		}
		return Math.pow((col + 0.055) / 1.055, 2.4);
	});
	var L = 0.2126 * c[0] + 0.7152 * c[1] + 0.0722 * c[2];
	return L < 0.179;
};

const sortArrayOfObjects = (arr, key, increment = false) => {
	return arr.sort(function(a, b) {
		if (increment) {
			if (a[key] > b[key]) return -1;
			if (a[key] < b[key]) return 1;
			return 0;
		} else {
			if (a[key] < b[key]) return -1;
			if (a[key] > b[key]) return 1;
			return 0;
		}
	});
};

const isExactMatch = (str, match) => {
	const str_splitted = str.split(", ");
	return str_splitted.includes(match);
};

const sortArrayOfObjectsByDate = (arr, key) => {
	return arr.sort(function(a, b) {
		// Turn strings into dates, and then subtract them
		// to get a value that is either negative, positive, or zero.
		return new Date(b[key]) - new Date(a[key]);
	});
};

const generateRandomString = () => {
	return (
		Date.now().toString(36) +
		Math.random()
			.toString(36)
			.substr(2)
	);
};

const removeHtmlTags = (text) => {
	var div = document.createElement("div");
	div.innerHTML = text;
	return div.textContent || div.innerText || "";
};

const compactArray = (arr) => {
	return arr.filter((item) => !!item);
};

const mapObjectKeys = (obj, mapper) => {
	return Object.entries(obj).reduce(
		(acc, [key, value]) => ({
			...acc,
			[mapper(value, key)]: value,
		}),
		{},
	);
};

const convertToKebabCase = (string) => {
	return string
		.replace(/([a-z])([A-Z])/g, "$1-$2")
		.replace(/[\s_]+/g, "-")
		.toLowerCase();
};

const isLocaleRTL = (locale) => {
	let obj = findFromArray(languages, "code", locale);
	return obj ? obj.rtl : false;
};

export {
	wordCount,
	isJson,
	getFullUrlForApiReq,
	getFullUrlForEtherpadApiReq,
	isArrayHavingItem,
	isTypeImage,
	paginationLength,
	getFileNameFromURL,
	replaceTextWithDots,
	convertNumberToAlphabet,
	filterArray,
	findFromArray,
	convertToNum,
	findElIndex,
	getFullDayName,
	getDayKey,
	getElHeight,
	generateRandomColor,
	flatArrayOfObjects,
	isColorDark,
	sortArrayOfObjects,
	isExactMatch,
	sortArrayOfObjectsByDate,
	generateRandomString,
	removeHtmlTags,
	compactArray,
	mapObjectKeys,
	convertToKebabCase,
	isLocaleRTL,
};
