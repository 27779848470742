import Vue from "vue";

export const loader = {
	start: () => {
		Vue.prototype.$eventBus.$emit("start-loader");
	},

	stop: () => {
		Vue.prototype.$eventBus.$emit("stop-loader");
	},
};

Vue.prototype.$loader = loader;
