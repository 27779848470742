export const projects = [
	{
		path: "/research/projects",
		name: "Projects",
		component: () =>
			import(
				/* webpackChunkName:"page.projects"*/ "@/pages/users/student/project/Index.vue"
			),
		meta: {
			layout: "ProjectsLayout",
			requiresAuth: true,
		},
	},
];
