const path_prefix = "/research/user/work";

export const work = [
	{
		path: `${path_prefix}/index`,
		name: "Work",
		component: () =>
			import(
				/* webpackChunkName:"page.work"*/ "@/pages/users/student/work/Index.vue"
			),
		meta: {
			layout: "AppLayout",
			requiresAuth: true,
			header: {
				title: "app.research_work.sidebar.title",
				subtitle: "app.research_work.header.subtitle",
			},
		},
	},
	{
		path: `${path_prefix}/standard_map`,
		name: "StandardMapWork",
		component: () =>
			import(
				/* webpackChunkName:"page.standard_map_work"*/ "@/pages/users/student/work/StandardMap.vue"
			),
		meta: {
			layout: "AppLayout",
			requiresAuth: true,
			header: {
				title: "app.research_work.standard_mind_map.header.title",
				subtitle: "app.research_work.standard_mind_map.header.subtitle",
			},
			footer: {
				component: "Work",
			},
		},
	},
	{
		path: `${path_prefix}/wizard_map`,
		name: "WizardMapWork",
		component: () =>
			import(
				/* webpackChunkName:"page.wizard-map-work"*/ "@/pages/users/student/work/WizardMap.vue"
			),
		meta: {
			layout: "AppLayout",
			requiresAuth: true,
			header: {
				title: "app.research_work.wizard_mind_map.header.title",
				subtitle: "app.research_work.wizard_mind_map.header.subtitle",
			},
			footer: {
				component: "Work",
			},
		},
	},
	{
		path: `${path_prefix}/sources`,
		name: "SourcesWork",
		component: () =>
			import(
				/* webpackChunkName:"page.sources-work"*/ "@/pages/users/student/work/Sources.vue"
			),
		meta: {
			layout: "AppLayout",
			requiresAuth: true,
			header: {
				title: "app.research_work.sources.header.title",
				subtitle: "app.research_work.sources.header.subtitle",
			},
			footer: {
				component: "Work",
			},
		},
	},
	{
		path: `${path_prefix}/question_board`,
		name: "QuestionBoardWork",
		component: () =>
			import(
				/* webpackChunkName:"page.question-board-work"*/ "@/pages/users/student/work/QuestionBoard.vue"
			),
		meta: {
			layout: "AppLayout",
			requiresAuth: true,
			header: {
				title: "app.research_work.question_board.header.title",
				subtitle: "app.research_work.question_board.header.subtitle",
			},
			footer: {
				component: "Work",
			},
		},
	},
	{
		path: `${path_prefix}/questionnaire`,
		name: "QuestionnaireWork",
		component: () =>
			import(
				/* webpackChunkName:"page.questionnaire-work"*/ "@/pages/users/student/work/Questionnaire.vue"
			),
		meta: {
			layout: "AppLayout",
			requiresAuth: true,
			header: {
				title: "app.research_work.questionnaire.header.title",
				subtitle: "app.research_work.questionnaire.header.subtitle",
			},
			footer: {
				component: "Work",
			},
		},
	},
	{
		path: `${path_prefix}/research_paper`,
		name: "ResearchPaperWork",
		component: () =>
			import(
				/* webpackChunkName:"page.research-paper-work"*/ "@/pages/users/student/work/ResearchPaper.vue"
			),
		meta: {
			layout: "AppLayout",
			requiresAuth: true,
			header: {
				title: "app.research_paper.title",
			},
			footer: {
				component: "Work",
			},
		},
	},
];
