import Vue from "vue";

// Read more Read less package
import ReadMore from "vue-read-more";
Vue.use(ReadMore);

// For stylish tooltips
import VTooltip from "v-tooltip";
Vue.use(VTooltip);

// To detect that when user has stopped the typing
import vueDebounce from "vue-debounce";
Vue.use(vueDebounce);

// To rotate, drag, resize any element (we are using for arrow feature in mind map)
import drr from "@minogin/vue-drag-resize-rotate";
Vue.component("drr", drr);

// To manage any time schedule work
import DatetimePicker from "vuetify-datetime-picker";
import "vuetify-datetime-picker/src/stylus/main.styl";
Vue.use(DatetimePicker);

import VueResizable from "vue-resizable";
Vue.component("vue-resizable", VueResizable);

// To manage meta information
import VueHead from "vue-head";
Vue.use(VueHead);

// To copy the value at clipboard
import VueClipboard from "vue-clipboard2";
Vue.use(VueClipboard);

// Cookies
import VueCookies from "vue-cookies";
Vue.use(VueCookies);

// To detect inactivity on browser
import IdleVue from "idle-vue";
const eventsHub = new Vue();
Vue.use(IdleVue, {
	eventEmitter: eventsHub,
	idleTime: 3600000, // 1 hour
	startAtIdle: false,
});

import AppButton from "@/components/ui/AppButton.vue";
Vue.component("AppButton", AppButton);
