import { callApi } from "@/services/api/etherpad/manager.js";
import { environments } from "@/environment/environment.js";

const apiURL = (cmd, query = "") => {
	return `/${cmd}?apikey=${environments.etherpad_api_key}${query}`;
};

export const etherpad = {
	getHTML: (payload) => {
		return callApi(apiURL("getHTML", `&padID=${payload.pad_id}`), "get");
	},

	getText: (payload) => {
		return callApi(apiURL("getText", `&padID=${payload.pad_id}`), "get");
	},

	appendText: (payload) => {
		return callApi(
			apiURL("appendText", `&padID=${payload.pad_id}`),
			"post",
			payload,
		);
	},

	createAuthorIfNotExistsFor: (payload) => {
		return callApi(apiURL("createAuthorIfNotExistsFor"), "post", payload);
	},

	getAuthor: (payload) => {
		return callApi(apiURL("getAuthor", `&authorID=${payload.authorId}`), "get");
	},

	doesPadExists: (payload) => {
		return callApi(apiURL("doesPadExists", `&padID=${payload.pad_id}`), "get");
	},

	createPad: (payload) => {
		return callApi(
			apiURL("createPad", `&padID=${payload.pad_id}&text=${payload.text}`),
			"get",
		);
	},

	deletePad: (payload) => {
		return callApi(
			apiURL("deletePad", `&padID=${payload.pad_id}`),
			"post",
			payload,
		);
	},

	saveReferences: (payload) => {
		return callApi(
			apiURL("saveReferences", `&userID=${payload.user_id}`),
			"post",
			payload,
		);
	},

	addReference: (payload) => {
		return callApi(
			apiURL("addReference", `&userID=${payload.user_id}`),
			"post",
			payload,
		);
	},

	getUsedReferences: (payload) => {
		return callApi(
			apiURL("getUsedReferences", `&userID=${payload.user_id}`),
			"get",
		);
	},

	updateReference: (payload) => {
		return callApi(
			apiURL("updateReference", `&userID=${payload.user_id}`),
			"post",
			payload,
		);
	},
};
