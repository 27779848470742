export const setPriority = [
	{
		path: `/research/tools/set_priority`,
		name: "SetPriority",
		component: () =>
			import(
				/* webpackChunkName:"page.set-priority"*/ "@/pages/users/student/tools/SetPriority.vue"
			),
		meta: {
			layout: "AppLayout",
			requiresAuth: true,
			header: {
				component: "SetPriority",
			},
			footer: {
				component: "SetPriority",
			},
		},
	},
];
