const path_prefix = "/research";

export const dynamicRoutes = [
	{
		path: `${path_prefix}/cc3hhl_standard_map`,
		name: "cc3hhl_StandardMap",
		component: () =>
			import(
				/* webpackChunkName:"page.cc3hhl"*/ "@/pages/dynamic/cc3hhl_StandardMap.vue"
			),
		meta: {
			layout: "AppLayout",
			requiresAuth: true,
			header: {
				title: "app.choose_topic.mind_map.standard.board.title",
				subtitle: "app.tools.standard_mind_map.new_map.subtitle",
			},
			footer: {
				component: "Buttons",
				data: {
					rightBtn: {
						title: "app.next",
						event_name: "redirect-from-cc3hhl-standard-map",
					},
				},
			},
		},
	},
	{
		path: `${path_prefix}/bkvgew_standard_map`,
		name: "bkvgew_StandardMap",
		component: () =>
			import(
				/* webpackChunkName:"page.bkvgew"*/ "@/pages/dynamic/bkvgew_StandardMap.vue"
			),
		meta: {
			layout: "AppLayout",
			requiresAuth: true,
			header: {
				title: "app.choose_topic.mind_map.standard.board.title",
				subtitle: "app.tools.standard_mind_map.new_map.subtitle",
			},
			footer: {
				component: "Buttons",
				data: {
					rightBtn: {
						title: "app.next",
						event_name: "redirect-from-bkvgew-standard-map",
					},
				},
			},
		},
	},
	{
		path: `${path_prefix}/er4tfv_standard_map`,
		name: "er4tfv_StandardMap",
		component: () =>
			import(
				/* webpackChunkName:"page.er4tfv"*/ "@/pages/dynamic/er4tfv_StandardMap.vue"
			),
		meta: {
			layout: "AppLayout",
			requiresAuth: true,
			header: {
				title: "app.initial_field_mapping.title",
				subtitle: "app.tools.standard_mind_map.new_map.subtitle",
			},
			footer: {
				component: "Buttons",
				data: {
					rightBtn: {
						title: "app.next",
						event_name: "redirect-from-er4tfv-standard-map",
					},
				},
			},
		},
	},
	{
		path: `${path_prefix}/xl9baq_poster`,
		name: "xl9baq_Poster",
		component: () =>
			import(
				/* webpackChunkName:"page.xl9baq"*/ "@/pages/dynamic/xl9baq_Poster.vue"
			),
		meta: {
			layout: "AppLayout",
			requiresAuth: true,
			header: {
				title: "app.poster.title",
			},
			footer: {
				component: "Buttons",
				data: {
					rightBtn: {
						title: "app.next",
						event_name: "redirect-from-xl9baq_poster",
					},
				},
			},
		},
	},
	{
		path: `${path_prefix}/rt6lio_ai_practice`,
		name: "rt6lio_AI",
		component: () =>
			import(
				/* webpackChunkName:"page.rt6lio"*/ "@/pages/dynamic/rt6lio_AIPractice.vue"
			),
		meta: {
			layout: "UnrestrictedLayout",
			requiresAuth: false,
			header: {
				title: "app.tools.ai_practice.title",
				subtitle: "app.tools.ai_practice.subtitle",
			},
			footer: {
				component: "Buttons",
				data: {
					leftBtn: {
						title: "app.back",
						event_name: "back-to-home",
					},
				},
			},
		},
	},
	{
		path: `${path_prefix}/rt6lio_ai_practice/choose_question`,
		name: "rt6lio_AIChooseQuestion",
		component: () =>
			import(
				/* webpackChunkName:"page.rt6lio_cq"*/ "@/pages/dynamic/rt6lio_ChooseQuestion.vue"
			),
		meta: {
			layout: "UnrestrictedLayout",
			requiresAuth: false,
			header: {
				component: "ToolsQuery",
			},
			footer: {
				component: "Query",
			},
		},
	},
	{
		path: `${path_prefix}/rt6lio_ai_practice/mindmap`,
		name: "rt6lio_AIMindmap",
		component: () =>
			import(
				/* webpackChunkName:"page.rt6lio_mm"*/ "@/pages/dynamic/rt6lio_Mindmap.vue"
			),
		meta: {
			layout: "UnrestrictedLayout",
			requiresAuth: false,
			header: {
				component: "ToolsQuery",
			},
			footer: {
				component: "Query",
			},
		},
	},
];
