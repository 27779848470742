import { mapGetters, mapState } from "vuex";

export const jwtManagementMixin = {
	computed: {
		...mapState({
			jwt: (state) => state.user.jwt,
		}),
		...mapGetters({
			user: "user/getPersonalDetails",
		}),
	},

	methods: {
		async createJWT() {
			try {
				// return if already exists
				if (this.jwt) return;

				await this.$store.dispatch("user/createJWT", {
					data: {
						username: this.user.username,
					},
				});
			} catch (error) {
				this.$announce.error(error);
			}
		},
	},
};
