<template>
	<v-row no-gutters>
		<!-- Left button -->
		<v-col>
			<AppButton
				v-if="leftBtn.show"
				:label="leftBtn.title"
				:disabled="leftBtn.disabled"
				:icon="leftBtn.icon || null"
				:left_icon="true"
				@click="$eventBus.$emit(leftBtn.event_name)"
			></AppButton>
		</v-col>

		<!-- Right Button -->
		<v-col class="text-end">
			<AppButton
				v-if="rightBtn.show"
				:label="rightBtn.title"
				:disabled="rightBtn.disabled"
				:icon="rightBtn.icon || null"
				@click="$eventBus.$emit(rightBtn.event_name)"
			></AppButton>
		</v-col>
	</v-row>
</template>

<script>
export default {
	name: "KnowledgeOrganizersFooter",

	computed: {
		leftBtn() {
			let btnObj = {
				title: "app.back",
				disabled: false,
				show: true,
				event_name: "ko-redirect-back",
				icon: this.$vuetify.rtl ? "right_chevron" : "left_chevron",
			};

			if (this.$route.name === "PositiveNegativeStatement") {
				btnObj.event_name = "pns-redirect-back";
			}

			return btnObj;
		},

		rightBtn() {
			let btnObj = {
				title: "app.next",
				disabled: false,
				event_name: null,
				show: false,
				icon: this.$vuetify.rtl ? "left_chevron" : "right_chevron",
			};

			return btnObj;
		},
	},
};
</script>
