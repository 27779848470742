import { research as API } from "@/services/api";
import { getField, updateField } from "vuex-map-fields";
import { i18n, loadLocaleMessages } from "@/plugins/i18n";
import { EventBus } from "@/plugins/event-bus";
import { vuetify } from "@/plugins/vuetify";
import { isArrayHavingItem, findFromArray } from "@/utils/helpers";
import { DEFINES } from "@/utils/defines";

// INITIATE STATE
function initialState() {
	return {
		layout: null,
		mini: false,
		locales: null,
		version_updated: false,
	};
}
// STATE
const state = initialState();

// GETTERS
const getters = {
	getField,
	layout: (state) => {
		return state.layout;
	},

	getLocale: (state, getters, rootState, rootGetters) => {
		let locale = rootGetters["user/getLanguage"];
		return locale ? locale : i18n.locale;
	},

	getTheDrawerStatus: (state) => {
		return state.mini;
	},

	getLanguageName: (state) => (lang_code) => {
		return findFromArray(DEFINES.LOCALES, "code", lang_code).name;
	},
};

// ACTIONS
const actions = {
	setLayout: (context, payload) => {
		context.commit("SET_LAYOUT", payload);
	},

	async getLocales(context) {
		let response = await API.getLocales();
		context.commit("SET_LOCALES", response);
	},

	updateLocaleAndRtl: (context) => {
		context.commit("UPDATE_LOCALE_AND_RTL", {
			locale: context.getters.getLocale,
		});
	},

	announce: (context, payload) => {
		EventBus.$emit("announce", {
			body: payload.body,
			title: payload.title,
			color: payload.color,
		});
	},
};

// MUTATIONS
const mutations = {
	updateField,
	SET_LAYOUT: (state, payload) => {
		state.layout = payload.layout;
	},

	SET_LOCALES: (state, payload) => {
		state.locales = payload;
	},

	UPDATE_LOCALE_AND_RTL: (state, payload) => {
		// Update locale
		i18n.locale = payload.locale;

		/**
		 * Now, update current locale's messages as well because now we have
		 * multiple locale files for gender individual.
		 */
		i18n.setLocaleMessage(payload.locale, loadLocaleMessages()[payload.locale]);

		// Update RTL
		vuetify.framework.rtl = isArrayHavingItem(["he", "ar"], payload.locale);
	},

	TOGGLE_DRAWER_STATUS: (state, payload = {}) => {
		state.mini = payload.hasOwnProperty("status")
			? payload.status
			: !state.mini;
	},

	SHOW_UPDATE_ALERT: (state, payload) => {
		state.version_updated = payload.status;
	},

	RESET_STATE: (state) => {
		const s = initialState();
		Object.keys(s).forEach((key) => {
			state[key] = s[key];
		});
	},
};

// Export module
export const globals = {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
