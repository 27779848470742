export const achieveChl = [
	{
		path: "/research/achievements_and_challenges",
		name: "AchievementsAndChallenges",
		component: () =>
			import(
				/* webpackChunkName:"page.rj-ac"*/ "@/pages/research/modules/reflectJourney/stages/AchieveAndChl"
			),
		meta: {
			layout: "AppLayout",
			requiresAuth: true,
			header: {
				title: "app.achievements_and_challenges.header.title",
				subtitle: "app.achievements_and_challenges.subtitle",
			},
			footer: {
				component: "Buttons",
				data: {
					rightBtn: {
						title: "app.finish",
						event_name: "finish-achieve-chl",
					},
				},
			},
		},
	},
];
