import { callApi } from "./manager";

export const whiteboard = {
	list: (payload) => {
		return callApi(
			`/infra/whiteboard?category=${payload.category}&type=${payload.type}`,
			"get",
		);
	},

	get: (payload) => {
		return callApi(`/infra/whiteboard/${payload.uuid}/`, "get");
	},

	getOfStudent: (payload) => {
		return callApi(
			`/infra/whiteboard?category=${payload.category}&type=${payload.type}&user_id=${payload.student_id}`,
			"get",
		);
	},

	create: (payload) => {
		return callApi(`/infra/whiteboard/`, "post", payload);
	},

	update: (payload) => {
		return callApi(`/infra/whiteboard/${payload.uuid}/`, "put", payload);
	},

	delete: (payload) => {
		return callApi(`/infra/whiteboard/${payload.uuid}`, "delete");
	},

	getEssentialParams: () => {
		return callApi(`/rq/general/`, "get");
	},
};
