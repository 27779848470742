export const users = [
	{
		path: "/research/admin/users",
		name: "AdminUsers",
		component: () =>
			import(
				/* webpackChunkName:"page.a-users"*/ "@/pages/users/admin/users/Index.vue"
			),
		meta: {
			layout: "AppLayout",
			requiresAuth: true,
			header: {
				title: "app.manage_users",
			},
		},
	},
];
