import { research as API } from "@/services/api";
import { getField, updateField } from "vuex-map-fields";
import {
	flatArrayOfObjects,
	findFromArray,
	sortArrayOfObjects,
} from "@/utils/helpers";
import { DEFINES } from "@/utils/defines";
import { researchRoutes } from "@/router/authority";

// INITIATE STATE
function initialState() {
	return {
		current_module: null,
		current_stage: null,
		document_id: null,
		modules: null,
	};
}

// STATE
const state = initialState();

// GETTERS
const getters = {
	getField,

	getModuleColor: (state) => (module_id = null) => {
		let colors = DEFINES.COLORS_OF_MODULES;
		if (module_id) {
			return colors[module_id - 1];
		}

		if (!state.current_module || !state.current_stage) return;

		return colors[Number(state.current_stage.toString()[0]) - 1];
	},

	flatModules: (state) => {
		if (!state.modules) return;
		return flatArrayOfObjects(state.modules, "children");
	},

	getModuleName: (state) => (module_id) => {
		let result = findFromArray(state.modules, "value", module_id);
		return result && result.title ? result.title : result;
	},

	getCurrentStageName: (state, getters) => {
		let result = getters.flatModules.find(
			(item) => item.value == state.current_stage,
		);
		return result && result.title ? result.title : result;
	},

	getSortedModules: (state) => (modules) => {
		function sort(arr) {
			let result = sortArrayOfObjects(arr, "value");
			result.forEach((element) => {
				if (element.children) {
					element.children = sort(element.children);
				}
			});
			return result;
		}
		return sort(modules);
	},

	getModifiedModules: (state, getters) => (modules) => {
		function modify(arr) {
			arr.forEach((item) => {
				// update route
				if (item.route) {
					item.route = getters.getSuitableRoute(item.route);
				}
				// if children then proceed again
				if (item.children) {
					modify(item.children);
				}
			});
			return arr;
		}
		return modify(modules);
	},

	getRouteOfPosition: (state, getters) => (position) => {
		let result = getters.flatModules.find((item) => item.value == position);
		if (!result) return null;
		return result.route;
	},

	isFurtherStageAvailable: (state, getters) => {
		// Flat all modules because of nestedness
		let modules = getters.flatModules;
		if (!modules) return;

		// Parse only positions (values)
		let positions = modules.map((item) => {
			return !/^[0-9]{3}$/.test(item.value) ? item.value : 0;
		});

		// Find the latest position
		let max_position = Math.max.apply(null, positions);

		// If latest position is greater than current that means current
		// is not last, further is available.
		return max_position > state.current_stage;
	},

	getSuitableRoute: () => (route_label) => {
		let $r = researchRoutes;
		switch (route_label) {
			case "plan_research":
				return $r.planResearch;
			case "define_confirm_timeline":
				return $r.defineQuestionPlan;
			case "choose_theme":
				return $r.chooseTheme;
			case "choose_theme_database":
				return $r.chooseThemeDatabase;
			case "choose_theme_summary":
				return $r.chooseThemeSummary;
			case "choose_topic":
				return $r.chooseTopic;
			case "choose_topic_standard_map":
				return $r.chooseTopicStandardMindmap;
			case "choose_topic_wizard_map":
				return $r.chooseTopicWizardMindmap;
			case "choose_topic_table_chart":
				return $r.chooseTopicTableChart;
			case "choose_topic_priority_tool":
				return $r.chooseTopicPrioritizationTool;
			case "choose_topic_summary":
				return $r.chooseTopicSummary;
			case "gather_info":
				return $r.gatherInfo;
			case "choose_question":
				return $r.chooseQuestion;
			case "define_summary":
				return $r.defineQuestionSummary;
			case "gather_sources_confirm_timeline":
				return $r.gatherSourcesPlan;
			case "plan_search_strategy":
				return $r.planSearchStrategy;
			case "create_source_list":
				return $r.createSourceList;
			case "source_documentation":
				return $r.sourceDocumentation;
			case "source_evaluation":
				return $r.sourceEvaluation;
			case "source_selection":
				return $r.sourceSelection;
			case "gather_sources_summary":
				return $r.gatherSourcesSummary;
			case "write_confirm_timeline":
				return $r.writePlan;
			case "research_paper":
				return $r.researchPaper;
			case "write_summary":
				return $r.writeSummary;
			case "reflect_journey":
				return $r.reflectJourneyPlan;
			case "achievements_and_challenges":
				return $r.achievementsAndChallenges;
			case "interaction_and_networking":
				return $r.interactionsAndNetworking;
			case "lessons_for_life":
				return $r.lifeLessons;
			case "reflect_journey_summary":
				return $r.reflectJourneySummary;
			case "present":
				return $r.presentResearchPlan;
			case "prepare_presentation":
				return $r.preparePresentation;
			case "practice_presentation":
				return $r.practicePresentation;
			case "schedule_presentation":
				return $r.schedulePresentation;
			case "present_summary":
				return $r.presentSummary;
			case "cc3hhl_standard_map":
				return $r.cc3hhl_StandardMap;
			case "bkvgew_standard_map":
				return $r.bkvgew_StandardMap;
			case "er4tfv_standard_map":
				return $r.er4tfv_StandardMap;
			case "xl9baq_poster":
				return $r.xl9baq_Poster;
			default:
				return null;
		}
	},
};

// ACTIONS
const actions = {
	async getMenu(context) {
		let response = await API.getMenu();
		// Sort modules and stages
		let modules = context.getters.getSortedModules(response.children);
		// Modify response
		let modified_modules = context.getters.getModifiedModules(modules);
		// MUTATE
		context.commit("SET_MENU", modified_modules);
	},

	async listMenus(context) {
		return await API.listMenus();
	},

	async listPlans(context) {
		return await API.listPlans();
	},

	async listPaperSchemes(context) {
		return await API.listPaperSchemes();
	},
};

// MUTATIONS
const mutations = {
	updateField,

	ACTIVE_MODULE_AND_STAGE: (state, payload) => {
		state.current_module = payload.current_module;
		state.current_stage = payload.current_stage;
	},

	INACTIVE_MODULES_AND_STAGES: (state) => {
		state.current_stage = null;
		state.current_module = null;
	},

	SET_DOCUMENT_ID: (state, payload) => {
		state.document_id = payload.document_id;
	},

	SET_MENU: (state, payload) => {
		state.modules = payload;
	},

	RESET_STATE: (state) => {
		const s = initialState();
		Object.keys(s).forEach((key) => {
			state[key] = s[key];
		});
	},
};

// Export module
export const research = {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
