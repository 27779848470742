import { getField, updateField } from "vuex-map-fields";
import { agora as API } from "@/services/api";
import { v4 as uuidv4 } from "uuid";

// INITIATE STATE
function initialState() {
	return {
		back_route: null,
	};
}

// STATE
const state = initialState();

// GETTERS
const getters = {
	getField,
	isAuthorized: (state) => {
		return state.token ? true : false;
	},
};

// ACTIONS
const actions = {
	async generateToken(context, payload) {
		return await API.generateToken({ channel_name: uuidv4() });
	},
	async retrieveToken(context, payload) {
		return await API.retrieveToken(payload);
	},
	async acquireResourceId(context, payload) {
		return await API.acquireResourceId(payload);
	},
	async startRecording(context, payload) {
		return await API.startRecording(payload);
	},
	async queryRecordingStatus(context, payload) {
		return await API.queryRecordingStatus(payload);
	},
	async stopRecording(context, payload) {
		return await API.stopRecording(payload);
	},
	async updateScreenSharingStatus(context, payload) {
		return await API.updateScreenSharingStatus(payload);
	},
	async getScreenSharingStatus(context, payload) {
		return await API.getScreenSharingStatus(payload);
	},
};

// MUTATIONS
const mutations = {
	updateField,
	SET_BACK_ROUTE: (state, payload) => {
		state.back_route = payload.back_route;
	},

	RESET_STATE: (state) => {
		const s = initialState();
		Object.keys(s).forEach((key) => {
			state[key] = s[key];
		});
	},
};

// Export module
export const agora = {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
