export const meeting = [
	{
		path: "/research/mentor/tools/meeting",
		name: "MentorMeet",
		component: () =>
			import(
				/* webpackChunkName:"page.m-meet"*/ "@/pages/users/mentor/tools/meeting/Index.vue"
			),
		meta: {
			layout: "MentorLayout",
			requiresAuth: true,
			header: {
				title: "app.meetings.meet_with_mentor.title",
			},
			footer: {
				component: "MentorToolsFooter",
			},
		},
	},
];
