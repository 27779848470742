const path_prefix = "/research/tools/wizard_mind_map";

export const wizardMindmap = [
	{
		path: `${path_prefix}/index`,
		name: "ToolsWizardMindmap",
		component: () =>
			import(
				/* webpackChunkName:"page.wizard-onboard"*/ "@/pages/users/student/tools/wizardMindmap/OnBoard.vue"
			),
		meta: {
			layout: "AppLayout",
			requiresAuth: true,
			header: {
				title: "app.tools.wizard_mind_map.header.title",
				subtitle: "app.tools.wizard_mind_map.header.subtitle",
			},
			footer: {
				component: "Tools",
			},
		},
	},
	{
		path: `${path_prefix}/list`,
		name: "WizardMindmapsList",
		component: () =>
			import(
				/* webpackChunkName:"page.wizard-list"*/ "@/pages/users/student/tools/wizardMindmap/List.vue"
			),
		meta: {
			layout: "AppLayout",
			requiresAuth: true,
			header: {
				title: "app.tools.wizard_mind_map.header.title",
				subtitle: "app.tools.wizard_mind_map.list.subtitle",
			},
			footer: {
				component: "Tools",
			},
		},
	},
	{
		path: `${path_prefix}/:id`,
		name: "WizardMindmapExplore",
		component: () =>
			import(
				/* webpackChunkName:"page.wizard-explore"*/ "@/pages/users/student/tools/wizardMindmap/Index.vue"
			),
		meta: {
			layout: "AppLayout",
			requiresAuth: true,
			header: {
				component: "ToolsMindmap",
			},
			footer: {
				component: "Tools",
			},
		},
	},
];
