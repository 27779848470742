import { process as API } from "@/services/api";

// INITIATE STATE
function initialState() {
	return {
		process_status: null,
		completed_step: null,
		current_step: null,
		rsc_general: null,
		rq_general: null,
	};
}

// STATE
const state = initialState();

// GETTERS
const getters = {
	isCurrentStepCompleted: (state, getters, rootState, rootGetters) => {
		return (
			state.current_step &&
			state.current_step.value <= state.completed_step.value
		);
	},
};

// ACTIONS
const actions = {
	async getProcessStatus(context, payload) {
		if (payload.process && payload.step) {
			let response = await API.getProcessStatus(payload);

			// Do not set steps from here because some calculations are needed.
			context.commit("SET_PROCESS_STATUS", response.process_status);
			return response;
		}
	},

	async setProcessStatus(context, payload) {
		let response = await API.setProcessStatus(payload);
		context.commit("SET_PROCESS_STATUS", response);
		return response;
	},

	async getRqGeneral(context) {
		let response = await API.getRqGeneral();
		context.commit("SET_RQ_GENERAL", response);
		return response;
	},

	async getRscGeneral(context) {
		let response = await API.getRscGeneral();
		context.commit("SET_RSC_GENERAL", response);
		return response;
	},

	async convertHTMLToWord(context, payload) {
		return await API.convertHTMLToWord(payload);
	},
};

// MUTATIONS
const mutations = {
	SET_PROCESS_STATUS: (state, payload) => {
		state.process_status = payload;
	},

	SET_CURRENT_AND_COMPLETED_STEP: (state, payload) => {
		state.current_step = payload.current_step;
		state.completed_step = payload.completed_step;
	},

	SET_RQ_GENERAL: (state, payload) => {
		state.rq_general = payload;
	},

	SET_RSC_GENERAL: (state, payload) => {
		state.rsc_general = payload;
	},

	RESET_STATE: (state) => {
		const s = initialState();
		Object.keys(s).forEach((key) => {
			state[key] = s[key];
		});
	},
};

// Export module
export const process = {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
