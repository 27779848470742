import { planAndManageRoutes } from "./planAndManage";
import { defineQuestionRoutes } from "./defineQuestion";
import { gatherSourcesRoutes } from "./gatherSources";
import { writeResearchRoutes } from "./write";
import { reflectJourneyRoutes } from "./reflectJourney";
import { presentResearchRoutes } from "./presentResearch";

export const researchModulesRoutes = [
	...planAndManageRoutes,
	...defineQuestionRoutes,
	...gatherSourcesRoutes,
	...writeResearchRoutes,
	...reflectJourneyRoutes,
	...presentResearchRoutes,
];
