<template>
	<v-row v-if="current_step" no-gutters>
		<!-- Left button -->
		<v-col>
			<AppButton
				v-if="leftBtn.show"
				:label="leftBtn.title"
				:left_icon="true"
				:icon="leftBtn.icon || null"
				:disabled="leftBtn.disabled"
				@click="redirectBack()"
			></AppButton>
		</v-col>

		<!-- Step Progress -->
		<v-col align="center">
			<AppStepsProgress
				v-model="progress_step"
				:progress_step="progress_step"
				:steps="steps"
			></AppStepsProgress>
		</v-col>

		<!-- Right Button -->
		<v-col class="text-end">
			<AppButton
				v-if="rightBtn.show"
				:label="rightBtn.title"
				:icon="rightBtn.icon || null"
				:disabled="rightBtn.disabled"
				@click="$eventBus.$emit(current_step.next_event)"
			></AppButton>
		</v-col>
	</v-row>
</template>

<script>
import { setPrioritySteps } from "@/utils/steps";
import { footerStepsMixin } from "@/mixins/footerStepsMixin";
import { mapState } from "vuex";

export default {
	name: "SetPriorityFooter",

	mixins: [footerStepsMixin],

	computed: {
		...mapState({
			current_step: (state) => state.temporary.set_priority_current_step,
		}),

		steps() {
			return setPrioritySteps;
		},

		leftBtn() {
			let btnObj = {
				title: "app.back",
				disabled: false,
				show: true,
				icon: this.$vuetify.rtl ? "right_chevron" : "left_chevron",
			};
			return btnObj;
		},

		rightBtn() {
			let btnObj = {
				title: "app.next",
				disabled: false,
				show: this.current_step.value === 1,
				icon: this.$vuetify.rtl ? "left_chevron" : "right_chevron",
			};
			return btnObj;
		},
	},

	methods: {
		redirectBack() {
			if (this.current_step.value === 1) {
				this.$router.push({
					name: "Tools",
				});
			} else {
				this.$eventBus.$emit(this.current_step.back_event);
			}
		},
	},
};
</script>
