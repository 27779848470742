<template>
	<v-row no-gutters v-if="current_questionnaire">
		<v-col class="text-end">
			<AppButton
				v-if="rightBtn.show"
				:label="rightBtn.title"
				:icon="rightBtn.icon || null"
				:disabled="rightBtn.disabled"
				@click="$eventBus.$emit(rightBtn.event_name)"
			></AppButton>
		</v-col>
	</v-row>
</template>

<script>
import { mapState } from "vuex";

export default {
	name: "SummaryQuestionnaireFooter",

	computed: {
		...mapState({
			current_questionnaire: (state) => state.questionnaire.list,
			current_module: (state) => state.research.current_module,
		}),

		rightBtn() {
			let btnObj = {
				title: "app.take_to_next_module",
				show:
					this.current_questionnaire.status ===
					this.$defines.QUESTIONNAIRE_STATUS_WAIT_FOR_APPROVAL_STRING,
				event_name: `m${this.current_module}-finish-summary-questionnaire`,
				icon: this.$vuetify.rtl ? "left_chevron" : "right_chevron",
			};

			return btnObj;
		},
	},
};
</script>
