// Firebase dependencies
import firebase from "firebase/app";
import "firebase/database";
import { environments } from "@/environment/environment.js";

const firebaseConfig = {
	apiKey: environments.firebase_api_key,
	authDomain: environments.firebase_auth_domain,
	projectId: environments.firebase_project_id,
	storageBucket: environments.firebase_storage_bucket,
	messagingSenderId: environments.firebase_messaging_sender_id,
	appId: environments.firebase_app_id,
	measurementId: environments.firebase_measurement_id,
};

// If firebase is not loaded already then only initialize this.
if (!firebase.apps.length) {
	firebase.initializeApp(firebaseConfig);
}

export default firebase;
