<template>
	<v-card class="dialog-content-wr rounded-lg" :height="height">
		<v-container>
			<v-card-text class="pa-10">
				<v-row>
					<v-col
						sm="10"
						:class="[
							'font-weight-bold text-h6',
							heading && heading.color
								? `${heading.color}--text`
								: 'black--text',
						]"
					>
						{{ $t(heading.label) }}
					</v-col>
					<v-col class="text-end">
						<v-icon color="black" @click="$emit('close')"
							>$vuetify.icons.values.close</v-icon
						>
					</v-col>
				</v-row>

				<div class="mt-10">
					<slot name="dialog-content"></slot>
				</div>

				<div v-if="action" class="mt-12">
					<v-hover v-slot="{ hover }">
						<AppButton
							v-if="
								!Object(action).hasOwnProperty('condition') || action.condition
							"
							:label="action.label"
							:loading="value"
							:disabled="value"
							:color="hover ? 'amberDarken1' : 'greenAccent2'"
							@click="(loader = value), $emit(action.event)"
						></AppButton>
					</v-hover>

					<AppButton
						v-if="show_cancel"
						label="app.cancel"
						color="greyLighten2"
						:class="['mx-5']"
						@click="$emit('close')"
					></AppButton>
				</div>
			</v-card-text>
		</v-container>
	</v-card>
</template>

<script>
export default {
	name: "AppDialog",

	props: {
		value: {
			required: false,
		},
		heading: {
			type: Object,
			default() {
				return {
					label: null,
				};
			},
		},
		action: {
			required: false,
		},
		show_cancel: {
			default: true,
		},
		fluid: {
			default: false,
		},
		height: {
			default: "100%",
		},
	},

	data() {
		return {
			loader: null,
		};
	},
};
</script>

<style lang="scss">
.dialog-content-wr {
	.action {
		min-width: 120px !important;
		transition: all 0.6s;
	}
}
</style>
