<template>
	<v-dialog :value="dialog" scrollable max-width="850px" persistent>
		<AppDialog
			:heading="{
				label: 'app.edit_source',
			}"
			@close="onBeforeClose()"
		>
			<template v-slot:dialog-content>
				<!-- Source title and description -->
				<v-expansion-panels flat v-model="meta_panel" multiple>
					<v-expansion-panel>
						<v-expansion-panel-header class="pa-4">
							<template v-slot:actions>
								<v-icon color="info" class="expansion-left-icon-wr" size="24"
									>$vuetify.icons.values.arrow_down</v-icon
								>
							</template>

							<div
								class="font-weight-bold subtitle-1 expansion-left-icon-header-wr mx-2"
							>
								{{ $t("app.meta_info") }}
							</div>
						</v-expansion-panel-header>
						<v-expansion-panel-content>
							<v-card flat :class="['greyLighten4']">
								<v-card-text class="pb-0">
									<v-row no-gutters>
										<v-col sm="9">
											<v-form ref="form">
												<v-text-field
													:label="$t('app.placeholder.add_title')"
													v-model="source.title"
													outlined
													:rules="[rules.required, rules.max(250)]"
													dense
													color="greenAccent2"
													background-color="white"
												>
												</v-text-field>
												<v-textarea
													:label="$t('app.placeholder.add_desc')"
													v-model="source.description"
													outlined
													:rules="[rules.required]"
													color="greenAccent2"
													background-color="white"
													rows="2"
												>
												</v-textarea>
											</v-form>
										</v-col>
										<v-col v-if="to_be_edit" align="end">
											<div v-if="source.attachment || source.url">
												<v-icon left>$vuetify.icons.values.attachment</v-icon>
												<a
													:href="
														source.attachment
															? `${$environments.base_api_url}${source.attachment}`
															: source.url
													"
													target="_blank"
													>{{
														source.attachment
															? $t("app.attachment_added")
															: $t("app.website_added")
													}}</a
												>
											</div>
										</v-col>
									</v-row>
								</v-card-text>
							</v-card>
						</v-expansion-panel-content>
					</v-expansion-panel>
				</v-expansion-panels>
				<v-divider></v-divider>

				<!-- Source documentation (This is editable) -->
				<SourceDocumentation
					:sourceId="source.id"
					:sourceObj="source"
					:panelProp="0"
					:show_header="false"
					@save-to-etherpad="saveReference"
				/>

				<!-- Source notes -->
				<template v-if="to_be_edit">
					<SourceNotes :sourceId="source.id" :editAccess="false"></SourceNotes>
					<v-divider></v-divider>
				</template>

				<template v-if="to_be_edit">
					<!-- Tags -->
					<v-expansion-panels flat>
						<v-expansion-panel>
							<v-expansion-panel-header class="pa-4">
								<template v-slot:actions>
									<v-icon color="info" class="expansion-left-icon-wr" size="24"
										>$vuetify.icons.values.arrow_down</v-icon
									>
								</template>

								<div
									class="font-weight-bold subtitle-1 expansion-left-icon-header-wr mx-2"
								>
									{{ $t("app.tags") }}
								</div>
							</v-expansion-panel-header>
							<v-expansion-panel-content>
								<v-chip
									v-for="tag in source.related_types"
									:key="tag.id"
									:color="tag.color"
									class="rounded-0 ma-1"
									:dark="isColorDark(tag.color)"
								>
									{{ tag.name }}
								</v-chip>
							</v-expansion-panel-content>
						</v-expansion-panel>
					</v-expansion-panels>
					<v-divider></v-divider>
				</template>

				<template v-if="to_be_edit">
					<!-- Related Keywords -->
					<v-expansion-panels flat>
						<v-expansion-panel>
							<v-expansion-panel-header class="pa-4">
								<template v-slot:actions>
									<v-icon color="info" class="expansion-left-icon-wr" size="24"
										>$vuetify.icons.values.arrow_down</v-icon
									>
								</template>

								<div
									class="font-weight-bold subtitle-1 expansion-left-icon-header-wr mx-2"
								>
									{{ $t("app.keywords") }}
								</div>
							</v-expansion-panel-header>
							<v-expansion-panel-content>
								<v-chip
									v-for="keyword in source.related_keywords"
									:key="keyword.id"
									class="rounded-0 ma-1"
								>
									{{ keyword.name }}
								</v-chip>
							</v-expansion-panel-content>
						</v-expansion-panel>
					</v-expansion-panels>
				</template>
			</template>
		</AppDialog>
	</v-dialog>
</template>

<script>
import AppDialog from "@/components/ui/AppDialog.vue";
import SourceDocumentation from "@/components/research/modules/gatherSources/source/Documentation.vue";
import { referenceManagerMixin } from "@/mixins/researchPaper/referenceManagerMixin.js";
import { mapGetters } from "vuex";
import rules from "@/utils/validation";
import SourceNotes from "@/components/research/modules/gatherSources/source/Notes.vue";
import { isColorDark } from "@/utils/helpers";

export default {
	name: "PopulateReferenceDialog",

	mixins: [referenceManagerMixin],

	props: {
		dialog: {
			required: true,
			type: Boolean,
		},
		source: {
			required: true,
		},
		to_be_edit: {
			default: false,
		},
	},

	data() {
		return {
			rules,
			isColorDark,
			meta_panel: [0],
			edit: !this.source.title,
		};
	},

	components: {
		AppDialog,
		SourceDocumentation,
		SourceNotes,
	},

	computed: {
		...mapGetters({
			userProfile: "user/getProfile",
		}),
	},

	methods: {
		async isValid() {
			if (
				(this.$refs.form && !this.$refs.form.validate()) ||
				!this.source.title
			) {
				throw new Error("app.ntfy.err.fill_required_fields", {
					cause: "werCustom",
				});
			}
		},

		async updateAtDBAndEtherpad(only_meta_info_edit, payload) {
			if (only_meta_info_edit) {
				await this.$store.dispatch("source/update", this.source);
				this.edit = false;
			}

			this.$emit("update-to-etherpad", payload);
		},

		async addToEtherpad(payload) {
			await this.$store.dispatch("etherpad/addReference", {
				user_id: this.userProfile.id,
				data: payload,
			});

			this.$emit("close");
		},

		async saveReference(payload, only_meta_info_edit = false) {
			try {
				this.$loader.start();

				// check if form validated
				await this.isValid();

				/**
				 * payload = event data from "Documentation" component OR anything else.
				 * this.source = edited data of this component
				 * if payload title and current data title are not same then
				 * match both. (Give priority to current title)
				 */
				if (payload.title != this.source.title) {
					payload.title = this.source.title;
				}

				// prepare reference of that source
				let response = await this.prepareReferenceFormat([payload]);
				if (!response || !response.length) {
					this.$emit("close");
					return;
				}

				/**
				 * If prop is set to edit instead of create new
				 * then emit event to parent.
				 * Otherwise, add new reference at etherpad
				 */
				if (this.to_be_edit) {
					await this.updateAtDBAndEtherpad(only_meta_info_edit, response[0]);
				} else {
					await this.addToEtherpad(response[0]);
				}
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		async onBeforeClose() {
			try {
				this.$loader.start();

				let is_documented = await this.isSourceDocumented(this.source);
				// if not documented then delete that source
				if (!is_documented) {
					await this.$store.dispatch("source/del", {
						id: this.source.id,
					});
				}
				// close the dialog
				this.$emit("close");
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},
	},
};
</script>
