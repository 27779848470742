const path_prefix = "/research/admin/student";

export const student = [
	{
		path: `${path_prefix}/:id`,
		name: "AdminStudentOverview",
		component: () =>
			import(
				/* webpackChunkName:"page.a-so"*/ "@/pages/users/admin/student/Overview.vue"
			),
		meta: {
			layout: "AppLayout",
			requiresAuth: true,
			header: true,
			footer: {
				component: "AdminStudentFooter",
			},
		},
	},
	{
		path: `${path_prefix}/:id/research_paper`,
		name: "AdminStudentResearchPaper",
		component: () =>
			import(
				/* webpackChunkName:"page.a-srp"*/ "@/pages/users/admin/student/ResearchPaper.vue"
			),
		meta: {
			layout: "AppLayout",
			requiresAuth: true,
			header: true,
			footer: {
				component: "AdminStudentFooter",
			},
		},
	},
	{
		path: `${path_prefix}/:id/interviews`,
		name: "AdminStudentInterviews",
		component: () =>
			import(
				/* webpackChunkName:"page.a-si"*/ "@/pages/users/admin/student/Interviews.vue"
			),
		meta: {
			layout: "AppLayout",
			requiresAuth: true,
			header: true,
			footer: {
				component: "AdminStudentFooter",
			},
		},
	},
	{
		path: `${path_prefix}/:id/meeting_summary`,
		name: "AdminStudentMeetingSummary",
		component: () =>
			import(
				/* webpackChunkName:"page.a-ms"*/ "@/pages/users/admin/student/MeetingSummary.vue"
			),
		meta: {
			layout: "AppLayout",
			requiresAuth: true,
			header: true,
			footer: {
				component: "AdminStudentFooter",
			},
		},
	},
];
