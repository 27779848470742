import { callApi } from "./manager";

export const tag = {
	list: () => {
		return callApi(`/rsc/source_type`, "get");
	},

	create: (payload) => {
		return callApi(`/rsc/source_type/`, "post", payload);
	},

	delete: (payload) => {
		return callApi(`/rsc/source_type/${payload}/`, "delete");
	},
};
