<template>
	<v-dialog :value="dialog" scrollable max-width="550px" persistent>
		<v-card>
			<v-card-text class="px-14 pt-14" align="center">
				<v-img
					:src="require('@/assets/images/bin.png')"
					width="50"
					class="cursor-wr"
					@click="$emit('cancel')"
				></v-img>
				<v-card-text class="title font-weight-bold error--text">
					{{ $t("app.confirm_warning") }}
				</v-card-text>
			</v-card-text>

			<v-card-text class="greyLighten4">
				<v-card-text
					v-html="$t(warning_desc)"
					class="subtitle-1 greyDarken4--text"
				></v-card-text>

				<v-card-text>
					<v-row>
						<v-col>
							<AppButton
								:label="no_btn_label"
								:rounded="false"
								:block="true"
								:large="true"
								:tile="true"
								color="white"
								:prop_style="{
									border: '1px solid var(--v-greyLighten2-base) !important',
								}"
								@click="$emit('cancel')"
							>
							</AppButton>
						</v-col>
						<v-col class="ps-0">
							<AppButton
								:label="yes_btn_label"
								:rounded="false"
								:block="true"
								:large="true"
								:tile="true"
								color="error"
								@click="$emit('confirm')"
							>
							</AppButton>
						</v-col>
					</v-row>
				</v-card-text>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	name: "TheConfirmation",

	props: {
		dialog: {
			required: true,
			type: Boolean,
		},

		warning_desc: {
			default: "app.are_you_sure_warning_desc",
		},

		yes_btn_label: {
			default: "app.yes_delete_it",
		},

		no_btn_label: {
			default: "app.no_keep_it",
		},
	},
};
</script>

<style scoped>
button:focus {
	background-color: white !important;
	border: 2px solid black !important;
	color: black !important;
}
</style>
