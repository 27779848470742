<template>
	<v-row no-gutters v-if="current_step">
		<!-- Left button -->
		<v-col>
			<AppButton
				v-if="leftBtn.show"
				:label="leftBtn.title"
				:left_icon="true"
				:icon="leftBtn.icon || null"
				:disabled="leftBtn.disabled"
				@click="$eventBus.$emit(leftBtn.event_name)"
			></AppButton>
		</v-col>

		<!-- Step Progress -->
		<v-col align="center">
			<AppStepsProgress
				v-model="progress_step"
				:progress_step="progress_step"
				:steps="steps"
			></AppStepsProgress>
		</v-col>

		<!-- Right Button -->
		<v-col class="text-end">
			<AppButton
				v-if="rightBtn.show"
				:label="current_step.next_step_title || rightBtn.title"
				:icon="rightBtn.icon || null"
				:disabled="rightBtn.disabled"
				@click="$eventBus.$emit(rightBtn.event_name)"
			></AppButton>
		</v-col>
	</v-row>
</template>

<script>
import { prioritizationToolSteps } from "@/utils/steps";
import { mapState } from "vuex";
import { footerStepsMixin } from "@/mixins/footerStepsMixin";
import { mapFields } from "vuex-map-fields";

export default {
	name: "PrioritizationToolFooter",

	mixins: [footerStepsMixin],

	created() {
		this.$eventBus.$on("redirect-to-parent-route", (payload) => {
			this.$router.push({ name: "ChooseTopic" });
		});
	},

	computed: {
		...mapFields("questionnaire", {
			current_step: "prioritization_current_step",
		}),

		steps() {
			return prioritizationToolSteps;
		},

		leftBtn() {
			let btnObj = {
				title: "app.back",
				disabled: false,
				show: this.current_step.back_event,
				event_name: this.current_step.back_event,
				icon: this.$vuetify.rtl ? "right_chevron" : "left_chevron",
			};

			switch (this.current_step.name) {
				case "prepareTitleNode":
				case "prepareMapsAndSelectOne":
				case "prepareTitle":
					btnObj.event_name = "redirect-to-parent-route";
					break;
			}
			return btnObj;
		},

		rightBtn() {
			let btnObj = {
				title: "app.next",
				disabled: false,
				show: true,
				event_name: this.current_step.next_event,
				icon: this.$vuetify.rtl ? "left_chevron" : "right_chevron",
			};

			switch (this.current_step.name) {
				case "selectFavNodes":
					btnObj.title = "app.my_map_ready";
					break;
			}
			return btnObj;
		},
	},

	beforeDestroy() {
		this.$eventBus.$off("redirect-to-parent-route");
	},
};
</script>
