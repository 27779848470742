export const preparePresentation = [
	{
		path: "/research/prepare_presentation",
		name: "PreparePresentation",
		component: () =>
			import(
				/* webpackChunkName:"page.pr-pre"*/ "@/pages/research/modules/present/stages/Prepare.vue"
			),
		meta: {
			layout: "AppLayout",
			requiresAuth: true,
			header: {
				title: "app.prepare_presentation.title",
			},
			footer: {
				component: "Buttons",
				data: {
					rightBtn: {
						title: "app.next",
						event_name: "finish-prepare-presentation",
					},
				},
			},
		},
	},
];
