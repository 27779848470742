import { category as API } from "@/services/api";
import { isArrayHavingItem, filterArray } from "@/utils/helpers";
import { getField, updateField } from "vuex-map-fields";
import { DEFINES } from "@/utils/defines";

const root_category = {
	id: DEFINES.ROOT_CATEGORY_ID,
	name: "app.choose_theme.theme_database.all_theme.title",
};

// INITIATE STATE
function initialState() {
	return {
		list: null,
		favorites: null,
		category: {},

		// Pages
		list_page: 1,
		favorites_page: 1,
		per_page_count: 6,

		// This should be static initially
		current_category: root_category,
		breadcrumb_categories: [root_category],
	};
}

// STATE
const state = initialState();

// GETTERS
const getters = {
	getField,
	isAddedToFavorites: (state, getters, rootState, rootGetters) => (
		category,
	) => {
		let user = rootGetters["user/getPersonalDetails"];
		return user.hasOwnProperty("username")
			? isArrayHavingItem(category.user_favorits, user.username)
			: false;
	},
};

// ACTIONS
const actions = {
	async list(context, payload) {
		let response = await API.list(payload);
		context.commit("SET_LIST", response);
	},

	async get(context, payload) {
		let response = await API.get(payload);
		context.commit("SET_CATEGORY", response);
	},

	async getFavorites(context, payload) {
		let response = await API.listFavorites(payload);
		context.commit("SET_FAVORITES", response);
	},

	async getAllFavorites(context, payload) {
		return await API.getAllFavorites();
	},

	async addToFavorites(context, payload) {
		await API.addToFavorites(payload);
	},

	async removeFromFavorites(context, payload) {
		await API.removeFromFavorites(payload);
	},

	async getSubCategories(context, payload) {
		let response = await API.getSubCategories(payload);
		context.commit("SET_LIST", response);
	},
};

// MUTATIONS
const mutations = {
	updateField,
	SET_LIST: (state, payload) => {
		state.list = payload;
	},

	SET_FAVORITES: (state, payload) => {
		state.favorites = payload;
	},

	SET_CATEGORY: (state, payload) => {
		state.category = payload;
	},

	SET_CURRENT_CATEGORY: (state, payload) => {
		state.current_category = {
			id: payload.id,
			name: payload.name,
		};
	},

	PUSH_INTO_BREADCRUMB_CATEGORIES: (state, payload) => {
		// Push only if not exist already.
		let category = filterArray(
			state.breadcrumb_categories,
			"id",
			payload.id,
			true,
		);

		if (!category.length) {
			state.breadcrumb_categories.push({
				id: payload.id,
				name: payload.name,
			});
		}
	},

	POP_FROM_BREADCRUMB_CATEGORIES: (state, payload) => {
		/**
		 * Find the index of item which is going to pop and
		 * pop from founded index to array's length.
		 */
		state.breadcrumb_categories.forEach((item, index) => {
			if (item.id == payload.category_id) {
				state.breadcrumb_categories.splice(
					index + 1,
					state.breadcrumb_categories.length,
				);
			}
		});
	},

	DECREMENT_FAVORITES_PAGE: (state) => {
		// Decrement only if set page is more than 1 because page 1 is default.
		if (state.favorites_page > 1) {
			state.favorites_page--;
		}
	},

	RESET_LIST_PAGE: (state) => {
		state.list_page = initialState()["list_page"];
	},

	RESET_BREADCRUMB_CATEGORIES: (state) => {
		const s = initialState();
		state.current_category = s["current_category"];
		state.breadcrumb_categories = s["breadcrumb_categories"];
	},

	RESET_SELECTED_CATEGORY: (state) => {
		state.category = {};
	},

	RESET_STATE: (state) => {
		const s = initialState();
		Object.keys(s).forEach((key) => {
			state[key] = s[key];
		});
	},
};

// Export module
export const category = {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
