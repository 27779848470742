export const presentResearchSummary = [
	{
		path: "/research/present_summary",
		name: "PresentResearchSummary",
		component: () =>
			import(
				/* webpackChunkName:"page.pr-s"*/ "@/pages/research/modules/present/stages/Summary.vue"
			),
		meta: {
			layout: "AppLayout",
			requiresAuth: true,
			header: {
				title: "app.present.summary.title",
				subtitle: "app.present.summary.subtitle",
			},
			footer: {
				component: "SummaryQuestionnaire",
			},
		},
	},
];
