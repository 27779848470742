const path_prefix = "/research/mentor/tools/meeting_summary";

export const meetingSummary = [
	{
		path: `${path_prefix}/list`,
		name: "MentorMeetingsList",
		component: () =>
			import(
				/* webpackChunkName:"page.m-summaries"*/ "@/pages/users/mentor/tools/meetingSummary/List.vue"
			),
		meta: {
			layout: "MentorLayout",
			requiresAuth: true,
			header: {
				title: "app.meetings.meeting_summary.title",
				subtitle: "app.meetings.meeting_summary.list.header.subtitle",
			},
			footer: {
				component: "MentorToolsFooter",
			},
		},
	},
	{
		path: `${path_prefix}/:id`,
		name: "MentorMeetingSummaryExplore",
		component: () =>
			import(
				/* webpackChunkName:"page.m-summary"*/ "@/pages/users/mentor/tools/meetingSummary/Index.vue"
			),
		meta: {
			layout: "MentorLayout",
			requiresAuth: true,
			header: {
				title: "app.meetings.meeting_summary.title",
				subtitle: "app.meetings.meeting_summary.list.header.subtitle",
			},
			footer: {
				component: "MentorToolsFooter",
			},
		},
	},
];
