import { callApi } from "./manager";

export const notification = {
	list: (payload) => {
		return callApi(`/user/message?box=${payload.type}`, "get");
	},

	get: (payload) => {
		return callApi(`/user/message/${payload.id}`, "get");
	},

	send: (payload) => {
		return callApi(`/user/message/`, "post", payload);
	},

	update: (payload) => {
		return callApi(`/user/message/${payload.id}/`, "put", payload);
	},

	delete: (payload) => {
		return callApi(`/user/message/${payload.id}`, "delete");
	},
};
