import { callApi } from "./manager";

export const aws = {
	makeS3ObjectPublic: (payload) => {
		return callApi(`/infra/s3access`, "post", payload);
	},

	makeS3ObjectPrivate: (payload) => {
		return callApi(`/infra/s3access`, "post", payload);
	},

	DeleteS3Object: (payload) => {
		return callApi(`/infra/s3access`, "delete", payload);
	},
};
