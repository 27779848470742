<template>
	<v-row no-gutters>
		<v-col>
			<AppButton
				v-if="leftBtn.show"
				:label="leftBtn.title"
				:left_icon="true"
				:icon="leftBtn.icon"
				:disabled="leftBtn.disabled"
				@click="redirectBack()"
			></AppButton>
		</v-col>
	</v-row>
</template>

<script>
import { isArrayHavingItem } from "@/utils/helpers";

export default {
	name: "MentorToolsFooter",

	computed: {
		leftBtn() {
			let btnObj = {
				title: "app.back",
				disabled: false,
				show: true,
				icon: this.$vuetify.rtl ? "right_chevron" : "left_chevron",
			};
			return btnObj;
		},
	},

	methods: {
		redirectBack() {
			try {
				let name = "MentorTools";

				switch (this.$route.name) {
					case "Interview":
						name = "InterviewsList";
						break;
					case "Observation":
						name = "ObservationsList";
						break;
					case "Meeting":
						name = "MeetingsList";
						break;
					case "MentorWizardMindmapsList":
					case "MentorWizardMindmapExplore":
						name = "MentorToolsWizardmindMap";
						break;
					case "MentorStandardMindmapExplore":
						name = "MentorStandardMindmapList";
						break;
					case "MentorMeetingSummaryExplore":
						name = "MentorMeetingsList";
						break;
				}

				this.$router.push({ name: name });
			} catch (error) {
				this.$announce.error(error);
			}
		},
	},
};
</script>
