import firebase from "@/services/firebase";
import { mapGetters } from "vuex";

export const notificationUpdatesMixin = {
	computed: {
		...mapGetters({
			user: "user/getProfile",
		}),
	},

	methods: {
		async listenNewNotification() {
			try {
				// Listen if any new child (notification) has been added to inbox
				firebase
					.database()
					.ref(`notifications/${this.user.id}/inbox`)
					.on("child_added", async (data) => {
						// Push new notification to inbox state
						this.$store.commit("notification/PUSH_TO_INBOX", {
							notification: data.val(),
						});
					});
			} catch (error) {
				this.$announce.error(error);
			}
		},
	},
};

export const notificationRemoveMixin = {
	computed: {
		...mapGetters({
			user: "user/getProfile",
		}),
	},

	methods: {
		removeNotification(notification) {
			firebase
				.database()
				.ref(
					`notifications/${notification.reciever_id}/inbox/${notification.id}`,
				)
				.remove();
		},
	},
};

export const notificationWriteMixin = {
	computed: {
		...mapGetters({
			getRequiredInfo: "notification/getRequiredInfo",
		}),
	},

	methods: {
		writeNotification(notification) {
			firebase
				.database()
				.ref(
					`notifications/${notification.reciever.id}/inbox/${notification.id}`,
				)
				.set(this.getRequiredInfo(notification));
		},
	},
};
