import { aws as API } from "@/services/api";

// INITIATE STATE
function initialState() {
	return {};
}

// STATE
const state = initialState();

// GETTERS
const getters = {};

// ACTIONS
const actions = {
	async makeS3ObjectPublic(context, payload) {
		return await API.makeS3ObjectPublic(payload);
	},

	async makeS3ObjectPrivate(context, payload) {
		return await API.makeS3ObjectPrivate(payload);
	},

	async DeleteS3Object(context, payload) {
		return await API.DeleteS3Object(payload);
	},
};

// MUTATIONS
const mutations = {
	RESET_STATE: (state) => {
		const s = initialState();
		Object.keys(s).forEach((key) => {
			state[key] = s[key];
		});
	},
};

// Export module
export const aws = {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
