export const notifications = [
	{
		path: "/research/notifications/index",
		name: "Notifications",
		component: () =>
			import(
				/* webpackChunkName:"page.notifications"*/ "@/pages/users/student/notification/Index.vue"
			),
		meta: {
			layout: "AppLayout",
			requiresAuth: true,
			header: {
				title: "app.messages.title",
				subtitle: "app.messages.subtitle",
			},
		},
	},
];
