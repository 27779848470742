import { mapGetters } from "vuex";

export const datePickerLocaleMixin = {
	data() {
		return {
			lang: this.$defines.ENGLISH_BCP_LANG_CODE,
		};
	},

	computed: {
		...mapGetters({
			userLang: "user/getLanguage",
		}),
	},

	watch: {
		userLang(newVal, oldVal) {
			if (newVal !== oldVal) {
				this.setLanguage();
			}
		},
	},

	mounted() {
		this.setLanguage();
	},

	methods: {
		setLanguage() {
			switch (this.userLang) {
				case "he":
					this.lang = this.$defines.HEBREW_BCP_LANG_CODE;
					break;
				case "ar":
					this.lang = this.$defines.ARABIC_BCP_LANG_CODE;
					break;
				default:
					this.lang = this.$defines.ENGLISH_BCP_LANG_CODE;
					break;
			}
		},
	},
};
