import { callApi } from "./manager";

export const agora = {
	generateToken: (payload) => {
		return callApi(
			`/infra/agora?channel=${payload.channel_name}&action=create`,
			"get",
		);
	},

	retrieveToken: (payload) => {
		return callApi(
			`/infra/agora?channel=${payload.channel_name}&action=join`,
			"get",
		);
	},

	acquireResourceId: (payload) => {
		return callApi(`/infra/agora-acquire/`, "post", payload);
	},

	startRecording: (payload) => {
		return callApi(`/infra/agora-start/`, "post", payload);
	},

	queryRecordingStatus: (payload) => {
		return callApi(`/infra/agora-query`, "get");
	},

	stopRecording: (payload) => {
		return callApi(`/infra/agora-stop/`, "post", payload);
	},

	updateScreenSharingStatus: (payload) => {
		return callApi(`/infra/agora-share`, "post", payload);
	},

	getScreenSharingStatus: (payload) => {
		return callApi(
			`/infra/agora-share?channel_name=${payload.channel_name} `,
			"get",
			payload,
		);
	},
};
