import Vue from "vue";
import xss from "xss";

export const XSS = {
	sanitize: (html) => {
		// Custom rules
		let options = {};
		let myxss = new xss.FilterXSS(options);
		// then apply myxss.process()
		return myxss.process(html);
	},
};

Vue.prototype.$xss = XSS;
