// Module- Write Research
import { writeResearchPlan } from "./stages/confirm_timeline";
import { researchPaper } from "./stages/research_paper";
import { writeResearchSummary } from "./stages/summary";

export const writeResearchRoutes = [
	...writeResearchPlan,
	...researchPaper,
	...writeResearchSummary,
];
