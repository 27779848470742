<template>
	<v-dialog v-model="dialog" width="900" height="800" persistent>
		<template v-slot:activator="{ on, attrs }">
			<v-tooltip
				:left="$vuetify.rtl"
				:right="!$vuetify.rtl"
				v-bind="attrs"
				v-on="on"
			>
				<template v-slot:activator="{ on, attrs }">
					<v-icon
						v-bind="attrs"
						v-on="on"
						class="cursor-wr"
						@click.stop="dialog = true"
						>{{ $vuetify.icons.values.video }}</v-icon
					>
				</template>
				<span>{{ $t("app.watch_video") }}</span>
			</v-tooltip>
		</template>
		<AppDialog
			v-if="dialog"
			:heading="{
				label: 'app.instructions',
				color: 'amberDarken1',
			}"
			@close="dialog = false"
		>
			<template v-slot:dialog-content>
				<iframe
					:src="
						`https://fast.wistia.net/embed/iframe/${video_id}?videoFoam=true`
					"
					title="Interface Explanation M1S1 Video"
					allow="autoplay; fullscreen"
					allowtransparency="true"
					frameborder="0"
					scrolling="no"
					class="wistia_embed"
					name="wistia_embed"
					msallowfullscreen
					width="100%"
					height="100%"
				></iframe>
			</template>
		</AppDialog>
	</v-dialog>
</template>

<script>
import AppDialog from "@/components/ui/AppDialog.vue";

export default {
	name: "StageVideoDialog",

	props: {
		video_id: {
			required: true,
		},
	},

	data() {
		return {
			dialog: false,
		};
	},

	components: {
		AppDialog,
	},
};
</script>

<style></style>
