export const planResearch = [
	{
		path: "/research/plan_research",
		name: "PlanResearch",
		component: () =>
			import(
				/* webpackChunkName:"page.pm-pr"*/ "@/pages/research/modules/planAndManage/stages/PlanResearch"
			),
		meta: {
			layout: "AppLayout",
			requiresAuth: true,
			header: {
				component: "PlanResearch",
			},
			footer: {
				component: "PlanResearch",
				data: {
					rightBtn: {
						title: "app.finish",
					},
				},
			},
		},
	},
];
