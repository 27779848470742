export const languages = [
	{
		code: "he",
		name: "Hebrew",
		rtl: true,
		voice: null,
	},
	{
		code: "en",
		name: "English",
		rtl: false,
		voice: "UK English Female",
	},
	{
		code: "ar",
		name: "Arabic",
		rtl: true,
		voice: "Arabic Female",
	},
	{
		code: "af",
		name: "Afrikaans",
		rtl: false,
		voice: "Afrikaans Male",
	},
	{
		code: "am",
		name: "Amharic",
		rtl: false,
		voice: null,
	},

	{
		code: "az",
		name: "Azerbaijani",
		rtl: false,
		voice: null,
	},
	{
		code: "be",
		name: "Belarusian",
		rtl: false,
		voice: null,
	},
	{
		code: "bg",
		name: "Bulgarian",
		rtl: false,
		voice: null,
	},
	{
		code: "bn",
		name: "Bengali",
		rtl: false,
		voice: null,
	},
	{
		code: "bs",
		name: "Bosnian",
		rtl: false,
		voice: null,
	},
	{
		code: "ca",
		name: "Catalan; Valencian",
		rtl: false,
		voice: "Catalan Male",
	},
	{
		code: "co",
		name: "Corsican",
		rtl: false,
		voice: null,
	},
	{
		code: "cs",
		name: "Czech",
		rtl: false,
		voice: "Czech Female",
	},
	{
		code: "cy",
		name: "Welsh",
		rtl: false,
		voice: "Welsh Male",
	},
	{
		code: "da",
		name: "Danish",
		rtl: false,
		voice: "Danish Female",
	},
	{
		code: "de",
		name: "German",
		rtl: false,
		voice: null,
	},
	{
		code: "el",
		name: "Greek, Modern (1453-)",
		rtl: false,
		voice: "Greek Female",
	},

	{
		code: "eo",
		name: "Esperanto",
		rtl: false,
		voice: "Esperanto Male",
	},
	{
		code: "es",
		name: "Spanish; Castilian",
		rtl: false,
		voice: "Spanish Latin American Female",
	},
	{
		code: "et",
		name: "Estonian",
		rtl: false,
		voice: null,
	},
	{
		code: "eu",
		name: "Basque",
		rtl: false,
		voice: null,
	},
	{
		code: "fa",
		name: "Persian",
		rtl: true,
		voice: null,
	},
	{
		code: "fi",
		name: "Finnish",
		rtl: false,
		voice: "Finnish Female",
	},
	{
		code: "fr",
		name: "French",
		rtl: false,
		voice: "French Female",
	},
	{
		code: "fy",
		name: "Western Frisian",
		rtl: false,
		voice: null,
	},
	{
		code: "ga",
		name: "Irish",
		rtl: false,
		voice: null,
	},
	{
		code: "gd",
		name: "Gaelic; Scomttish Gaelic",
		rtl: false,
		voice: null,
	},
	{
		code: "gl",
		name: "Galician",
		rtl: false,
		voice: null,
	},
	{
		code: "gu",
		name: "Gujarati",
		rtl: false,
		voice: null,
	},
	{
		code: "ha",
		name: "Hausa",
		rtl: false,
		voice: null,
	},

	{
		code: "hi",
		name: "Hindi",
		rtl: false,
		voice: "Hindi Female",
	},
	{
		code: "hr",
		name: "Croatian",
		rtl: false,
		voice: null,
	},
	{
		code: "ht",
		name: "Haitian; Haitian Creole",
		rtl: false,
		voice: null,
	},
	{
		code: "hu",
		name: "Hungarian",
		rtl: false,
		voice: "Hungarian Female",
	},
	{
		code: "hy",
		name: "Armenian",
		rtl: false,
		voice: "Armenian Male",
	},
	{
		code: "id",
		name: "Indonesian",
		rtl: false,
		voice: "Indonesian Female",
	},
	{
		code: "ig",
		name: "Igbo",
		rtl: false,
		voice: null,
	},
	{
		code: "is",
		name: "Icelandic",
		rtl: false,
		voice: "Icelandic Male",
	},
	{
		code: "it",
		name: "Italian",
		rtl: false,
		voice: "Italian Female",
	},
	{
		code: "ja",
		name: "Japanese",
		rtl: false,
		voice: "Japanese Female",
	},
	{
		code: "ka",
		name: "Georgian",
		rtl: false,
		voice: null,
	},
	{
		code: "kk",
		name: "Kazakh",
		rtl: false,
		voice: null,
	},
	{
		code: "km",
		name: "Central Khmer",
		rtl: false,
		voice: null,
	},
	{
		code: "kn",
		name: "Kannada",
		rtl: false,
		voice: null,
	},
	{
		code: "ko",
		name: "Korean",
		rtl: false,
		voice: "Korean Female",
	},
	{
		code: "ku",
		name: "Kurdish",
		rtl: true,
		voice: null,
	},
	{
		code: "ky",
		name: "Kirghiz; Kyrgyz",
		rtl: false,
		voice: null,
	},
	{
		code: "lb",
		name: "Luxembourgish; Letzeburgesch",
		rtl: false,
		voice: null,
	},
	{
		code: "lo",
		name: "Lao",
		rtl: false,
		voice: null,
	},
	{
		code: "lt",
		name: "Lithuanian",
		rtl: false,
		voice: null,
	},
	{
		code: "lv",
		name: "Latvian",
		rtl: false,
		voice: "Latvian Male",
	},
	{
		code: "mg",
		name: "Malagasy",
		rtl: false,
		voice: null,
	},
	{
		code: "mi",
		name: "Maori",
		rtl: false,
		voice: null,
	},
	{
		code: "mk",
		name: "Macedonian",
		rtl: false,
		voice: null,
	},
	{
		code: "ml",
		name: "Malayalam",
		rtl: false,
		voice: null,
	},
	{
		code: "mn",
		name: "Mongolian",
		rtl: false,
		voice: null,
	},
	{
		code: "mr",
		name: "Marathi",
		rtl: false,
		voice: null,
	},
	{
		code: "ms",
		name: "Malay",
		rtl: false,
		voice: null,
	},
	{
		code: "mt",
		name: "Maltese",
		rtl: false,
		voice: null,
	},
	{
		code: "my",
		name: "Burmese",
		rtl: false,
		voice: null,
	},
	{
		code: "ne",
		name: "Nepali",
		rtl: false,
		voice: null,
	},
	{
		code: "nl",
		name: "Dutch; Flemish",
		rtl: false,
		voice: "Dutch Female",
	},
	{
		code: "no",
		name: "Norwegian",
		rtl: false,
		voice: "Norwegian Female",
	},
	{
		code: "ny",
		name: "Chichewa; Chewa; Nyanja",
		rtl: false,
		voice: null,
	},
	{
		code: "or",
		name: "Oriya",
		rtl: false,
		voice: null,
	},
	{
		code: "pa",
		name: "Panjabi; Punjabi",
		rtl: false,
		voice: null,
	},
	{
		code: "pl",
		name: "Polish",
		rtl: false,
		voice: "Polish Female",
	},
	{
		code: "ps",
		name: "Pushto; Pashto",
		rtl: false,
		voice: null,
	},
	{
		code: "pt",
		name: "Portuguese",
		rtl: false,
		voice: "Portuguese Female",
	},
	{
		code: "ro",
		name: "Romanian; Moldavian; Moldovan",
		rtl: false,
		voice: "Romanian Female",
	},
	{
		code: "ru",
		name: "Russian",
		rtl: false,
		voice: "Russian Female",
	},
	{
		code: "rw",
		name: "Kinyarwanda",
		rtl: false,
		voice: null,
	},
	{
		code: "sd",
		name: "Sindhi",
		rtl: false,
		voice: null,
	},
	{
		code: "si",
		name: "Sinhala; Sinhalese",
		rtl: false,
		voice: null,
	},
	{
		code: "sk",
		name: "Slovak",
		rtl: false,
		voice: "Slovak Female",
	},
	{
		code: "sl",
		name: "Slovenian",
		rtl: false,
		voice: null,
	},
	{
		code: "sm",
		name: "Samoan",
		rtl: false,
		voice: null,
	},
	{
		code: "sn",
		name: "Shona",
		rtl: false,
		voice: null,
	},
	{
		code: "so",
		name: "Somali",
		rtl: false,
		voice: null,
	},
	{
		code: "sq",
		name: "Albanian",
		rtl: false,
		voice: "Albanian Male",
	},
	{
		code: "sr",
		name: "Serbian",
		rtl: false,
		voice: "Serbian Female",
	},
	{
		code: "st",
		name: "Sotho, Southern",
		rtl: false,
		voice: null,
	},
	{
		code: "su",
		name: "Sundanese",
		rtl: false,
		voice: null,
	},
	{
		code: "sv",
		name: "Swedish",
		rtl: false,
		voice: "Swedish Female",
	},
	{
		code: "sw",
		name: "Swahili",
		rtl: false,
		voice: "Swahili Male",
	},
	{
		code: "ta",
		name: "Tamil",
		rtl: false,
		voice: "Tamil Male",
	},
	{
		code: "te",
		name: "Telugu",
		rtl: false,
		voice: null,
	},
	{
		code: "tg",
		name: "Tajik",
		rtl: false,
		voice: null,
	},
	{
		code: "th",
		name: "Thai",
		rtl: false,
		voice: "Thai Female",
	},
	{
		code: "tl",
		name: "Tagalog",
		rtl: false,
		voice: null,
	},
	{
		code: "tr",
		name: "Turkish",
		rtl: false,
		voice: "Turkish Female",
	},
	{
		code: "ug",
		name: "Uighur; Uyghur",
		rtl: false,
		voice: null,
	},
	{
		code: "uk",
		name: "Ukrainian",
		rtl: false,
		voice: null,
	},
	{
		code: "ur",
		name: "Urdu",
		rtl: true,
		voice: null,
	},
	{
		code: "uz",
		name: "Uzbek",
		rtl: false,
		voice: null,
	},
	{
		code: "vi",
		name: "Vietnamese",
		rtl: false,
		voice: "Vietnamese Female",
	},
	{
		code: "xh",
		name: "Xhosa",
		rtl: false,
		voice: null,
	},
	{
		code: "yi",
		name: "Yiddish",
		rtl: false,
		voice: null,
	},
	{
		code: "yo",
		name: "Yoruba",
		rtl: false,
		voice: null,
	},
	{
		code: "zu",
		name: "Zulu",
		rtl: false,
		voice: null,
	},
];
