import { mapState } from "vuex";

export const mapTypeSwitchingMixin = {
	computed: {
		...mapState({
			process_status: (state) => state.process.process_status,
			current_module: (state) => state.research.current_module,
			current_stage: (state) => state.research.current_stage,
		}),
	},

	methods: {
		async getTypeOfMindmap(route) {
			switch (route) {
				case "ChooseTopicWizardMindmap":
					return "wizard";
				case "ChooseTopicStandardMindmap":
					return "standard";
				case "ChooseTopicTableChart":
					return "table_chart";
			}
			return null;
		},

		async resetSteps(route) {
			// Get process status for module 2, step 2 (choose topic)
			await this.$store.dispatch("process/getProcessStatus", {
				process: "M" + this.current_module,
				step: this.current_stage,
			});

			// Check which map user is trying to access
			let accessing_map_type = await this.getTypeOfMindmap(route);

			let payload = {
				process_status_id: this.process_status.id,
				mm_type: accessing_map_type,
			};

			/**
			 * If user is switching from one type map to another type map
			 * then set steps to initial.
			 */
			if (this.process_status.mm_type !== accessing_map_type) {
				payload.user_completed_step = 0;
				payload.user_current_step = 1;
			}

			// Set process status for current map type
			await this.$store.dispatch("process/setProcessStatus", payload);
		},
	},
};
