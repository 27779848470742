import { mapState, mapGetters } from "vuex";
import moment from "moment";

export const accessManagementMixin = {
	computed: {
		...mapState({
			current_stage: (state) => state.research.current_stage,
		}),

		...mapGetters({
			isAdmin: "user/isAdmin",
			isFurtherStageAvailable: "research/isFurtherStageAvailable",
		}),

		latestAvailableStage() {
			return this.$store.getters["user/getLatestAvailableStage"]();
		},

		stagePlan() {
			return this.$store.getters["planAndManage/getStagePlan"](
				this.current_stage,
			);
		},
	},

	methods: {
		async redirectToRelevantRoute() {
			try {
				this.$loader.start();

				if (!this.latestAvailableStage) {
					throw new Error("app.ntfy.err.next_step_not_found", {
						cause: "werCustom",
					});
				}
				// Parse the step and stage from result and set in state.
				await this.$store.commit("research/ACTIVE_MODULE_AND_STAGE", {
					current_module: this.latestAvailableStage.module_id,
					current_stage: this.latestAvailableStage.value,
				});

				// Now, push to the relevant route
				await this.$router.push({
					name: this.latestAvailableStage.route,
				});
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		async updateDateAtServer(param) {
			var payload = this.stagePlan;
			// Set actual end date
			payload[param] = moment().format();
			// Update plan at server
			await this.$store.dispatch("planAndManage/updateUserPlan", payload);
		},

		async setActualStartDate() {
			try {
				this.$loader.start();

				// If actual start date is not set already
				if (this.stagePlan && !this.stagePlan.actual_start_date) {
					await this.updateDateAtServer("actual_start_date");
				}
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		async setActualEndDate() {
			if (this.stagePlan) {
				await this.updateDateAtServer("actual_end_date");
			}
		},

		async finishModuleAndRedirect() {
			try {
				this.$loader.start();

				// Before finish current stage, set it's actual end date
				await this.setActualEndDate();

				// Check if further stage is available
				if (!this.isFurtherStageAvailable) {
					throw new Error("app.ntfy.err.next_step_not_found", {
						cause: "werCustom",
					});
				}

				// Mark current step as completed
				await this.$store.dispatch("user/updateCompletedStep", {
					step_position: this.current_stage,
				});

				// Get user again, to set fresh info
				await this.$store.dispatch("user/get");

				// Redirect to the relavant route
				await this.redirectToRelevantRoute();
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},
	},
};
