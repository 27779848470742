import { callApi } from "./manager";

export const category = {
	list: (payload) => {
		return callApi(`/rq/category/?page=${payload.page}`, "get");
	},

	get: (payload) => {
		return callApi(`/rq/category/${payload.category_id}`, "get");
	},

	listFavorites: (payload) => {
		return callApi(`/user/category/?page=${payload.page}`, "get");
	},

	getAllFavorites: (payload) => {
		return callApi(`/rq/favorits?all=true`, "get");
	},

	addToFavorites: (payload) => {
		return callApi("/user/category/", "post", payload);
	},

	removeFromFavorites: (payload) => {
		return callApi(`/user/category/${payload.category_id}/`, "delete");
	},

	getSubCategories: (payload) => {
		return callApi(
			`/rq/category/?cat=${payload.cat}&page=${payload.page}`,
			"get",
		);
	},
};
