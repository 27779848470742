<template>
	<v-dialog v-model="dialog" scrollable max-width="800" persistent>
		<template v-slot:activator="{ on, attrs }">
			<v-tooltip top v-bind="attrs" v-on="on">
				<template v-slot:activator="{ on, attrs }">
					<v-icon
						v-bind="attrs"
						v-on="on"
						class="cursor-wr"
						@click="dialog = true"
						>$vuetify.icons.values.mic</v-icon
					>
				</template>
				<span>{{ $t("app.speak") }}</span>
			</v-tooltip>
		</template>

		<v-card height="500">
			<v-card-title class="title font-weight-bold black--text justify-center">
				<v-icon color="black"> $vuetify.icons.values.mic</v-icon>
				{{ $t("app.speech_to_text") }}
			</v-card-title>
			<v-divider></v-divider>

			<!-- Without Error -->
			<v-card-text>
				<v-card-text v-if="!error" class="px-0">
					<!-- If any text is not present already-->
					<div v-if="!sentences.length">
						{{ $t("app.placeholder.speak_to_text") }}
					</div>
					<div v-else class="white-space-pre-line-wr">
						<span
							v-for="(sentence, index) in sentences"
							:key="index"
							class="subtitle-1 black--text"
							>{{ sentence }}.&ensp;
						</span>
					</div>
				</v-card-text>

				<!-- With error -->
				<v-card-text v-else class="error--text">{{ error }}</v-card-text>
			</v-card-text>
			<v-divider></v-divider>

			<!-- Actions -->
			<v-card-actions>
				<v-row no-gutters>
					<v-col
						v-for="(item, index) in actions"
						:key="index"
						:class="item.align ? 'text-end' : null"
						:sm="item.sm"
					>
						<AppButton
							:label="item.title"
							:color="item.color"
							:dark="item.dark"
							:disabled="item.disabled"
							:icon="item.icon"
							:tile="true"
							@click="handleFunctionCall(item.method)"
						/>
					</v-col>
				</v-row>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import { mapState } from "vuex";
import { datePickerLocaleMixin } from "@/mixins/datePickerLocaleMixin";

let SpeechRecognition = null;
let recognition = null;

export default {
	name: "SpeechToText",

	mixins: [datePickerLocaleMixin],

	props: {
		text: {
			required: true,
		},
	},

	data() {
		return {
			error: false,
			speaking: false,
			toggle: false,
			runtimeTranscription: "",
			sentences: [],
			dialog: false,
		};
	},

	computed: {
		actions() {
			return [
				{
					title: "app.quit",
					icon: "close",
					disabled: false,
					dark: true,
					color: "error",
					icon_class: null,
					method: "closeWithoutSaving",
					sm: "2",
				},
				{
					title: "app.clear_text",
					icon: "broom",
					disabled: !this.sentences.length,
					dark: this.sentences.length > 0,
					color: "greyLighten2",
					icon_class: null,
					method: "clearText",
					sm: "4",
				},
				{
					title: !this.toggle ? "app.start_speaking" : "app.stop_recording",
					icon: !this.toggle ? "mic" : "start_recording",
					disabled: false,
					dark: true,
					align: true,
					color: !this.toggle ? "info" : "error",
					icon_class: this.speaking ? "pulse-red" : null,
					method: this.toggle
						? "stopSpeechRecognition"
						: "startSpeechRecognition",
					sm: "4",
				},
				{
					title: "app.save",
					icon: "save",
					disabled: false,
					dark: true,
					color: "success",
					icon_class: null,
					align: true,
					method: "saveRecognitionAndExit",
					sm: "2",
				},
			];
		},
	},

	watch: {
		dialog(newVal, oldVal) {
			if (newVal && this.text) {
				this.sentences.push(this.text);
			}
		},
	},

	methods: {
		capitalizeFirstLetter(string) {
			return string.charAt(0).toUpperCase() + string.slice(1);
		},

		checkCompatibility() {
			try {
				if (!recognition) {
					throw new Error("app.ntfy.err.recording_failed", {
						cause: "werCustom",
					});
				}
			} catch (error) {
				this.$announce.error(error);
			}
		},

		handleFunctionCall(method_name) {
			this[method_name]();
		},

		async setRecognition() {
			SpeechRecognition =
				window.SpeechRecognition || window.webkitSpeechRecognition;
			recognition = SpeechRecognition ? await new SpeechRecognition() : false;
		},

		async startSpeechRecognition() {
			try {
				await this.setRecognition();

				await this.checkCompatibility();

				this.toggle = true;
				recognition.lang = this.lang;
				recognition.interimResults = true;

				this.setSpeechStartListener();

				this.setSpeechEndListener();

				this.setResultListener();

				this.setEndListener();

				recognition.start();
			} catch (error) {
				this.$announce.error(error);
				return false;
			}
		},

		setSpeechStartListener() {
			recognition.addEventListener("speechstart", (event) => {
				this.speaking = true;
			});
		},

		setSpeechEndListener() {
			recognition.addEventListener("speechend", (event) => {
				this.speaking = false;
			});
		},

		setResultListener() {
			recognition.addEventListener("result", (event) => {
				const text = Array.from(event.results)
					.map((result) => result[0])
					.map((result) => result.transcript)
					.join("");
				this.runtimeTranscription = text;
			});
		},

		setEndListener() {
			recognition.addEventListener("end", () => {
				if (this.runtimeTranscription !== "") {
					this.sentences.push(
						this.capitalizeFirstLetter(this.runtimeTranscription),
					);
					this.$emit(
						"update:text",
						`${this.text}${this.sentences.slice(-1)[0]}. `,
					);
				}
				this.runtimeTranscription = "";
				recognition.stop();
				if (this.toggle) {
					// keep it going.
					recognition.start();
				}
			});
		},

		stopSpeechRecognition() {
			if (recognition) {
				recognition.stop();
				this.toggle = false;
			}
		},

		clearText() {
			// Before clearing check if recording is on and off it.
			this.stopSpeechRecognition();

			this.sentences = [];
			this.runtimeTranscription = "";
		},

		saveRecognitionAndExit() {
			try {
				// Save recorded text in local variable
				let result = this.sentences.join(". ");

				// Clear the text
				this.clearText();

				// Close dialog
				this.dialog = false;

				// Emit event with recorded result
				this.$emit("speechend", { text: result });
			} catch (error) {
				this.$announce.error(error);
				return false;
			}
		},

		closeWithoutSaving() {
			try {
				// Clear the text
				this.clearText();

				// Close dialog
				this.dialog = false;
			} catch (error) {
				this.$announce.error(error);
				return false;
			}
		},
	},
};
</script>

<style scoped>
.pulse-red {
	border-radius: 50%;
	transform: scale(1);
	animation: pulse-black 1s infinite;
	box-shadow: 0 0 0 0 #fff;
	animation: pulse-red 1s infinite;
}

@keyframes pulse-red {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 #fff;
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(255, 82, 82, 0.2);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.2);
	}
}
</style>
