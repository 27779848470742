import { callApi } from "./manager";

export const interview = {
	list: () => {
		return callApi(`/integration/interview`, "get");
	},

	create: (payload) => {
		return callApi(`/integration/interview/`, "post", payload);
	},

	update: (payload) => {
		return callApi(
			`/integration/interview/${payload.interview_id}/`,
			"put",
			payload.data,
		);
	},

	delete: (payload) => {
		return callApi(`/integration/interview/${payload.interview_id}`, "delete");
	},

	listOfStudent: (payload) => {
		return callApi(`/integration/interview?user_id=${payload.user_id}`, "get");
	},
};
