import { source as API } from "@/services/api";
import {
	findFromArray,
	sortArrayOfObjects,
	findElIndex,
} from "@/utils/helpers";
import Vue from "vue";

// INITIATE STATE
function initialState() {
	return {
		list: [],
	};
}

// STATE
const state = initialState();

// GETTERS
const getters = {
	getSource: (state) => (sourceId) => {
		var source = findFromArray(state.list, "id", sourceId);

		// As in API keyword key is different when update is done on keywords
		source.new_related_keywords = source.related_keywords.map(
			(item) => item.id,
		);
		source.new_related_types = source.related_types.map((item) => item.id);

		return source;
	},
};

// ACTIONS
const actions = {
	async list(context, payload) {
		let response = await API.list(payload);
		context.commit("SET_LIST", response);
		return response;
	},

	async get(context, payload) {
		return await API.get(payload);
	},

	async create(context, payload) {
		let response = await API.create(payload);
		context.commit("SET_SOURCES", response);
		return response;
	},

	async update(context, payload) {
		let response = await API.update(payload);
		context.commit("UPDATE_SOURCE", response);
		return response;
	},

	async del(context, payload) {
		let response = await API.del(payload);
		return response;
	},
};

// MUTATIONS
const mutations = {
	SET_LIST: (state, payload) => {
		state.list = sortArrayOfObjects(payload, "id");
	},

	UPDATE_SOURCE: (state, payload) => {
		//Find index of specific object using findIndex method.
		let objIndex = findElIndex(state.list, "id", payload.id);

		// If index found then update the data at index.
		if (objIndex !== -1) {
			Vue.set(state.list, objIndex, payload);
		}
	},

	SET_SOURCES: (state, payload) => {
		// If response is an array, update whole list
		if (Array.isArray(payload)) {
			state.list = sortArrayOfObjects(payload, "id");
		}
		// otherwise push the response to the list
		else {
			state.list.push(payload);
		}
	},

	RESET_STATE: (state) => {
		const s = initialState();
		Object.keys(s).forEach((key) => {
			state[key] = s[key];
		});
	},
};

// Export module
export const source = {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
