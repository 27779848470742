<template>
	<v-btn
		icon
		small
		:style="styleObj"
		class="white"
		@click="toggleDrawerStatus()"
	>
		<v-icon color="secondary">
			{{ $vuetify.icons.values[getSuitableIcon] }}
		</v-icon>
	</v-btn>
</template>

<script>
import { mapState } from "vuex";

export default {
	name: "TheDrawerButton",

	computed: {
		...mapState({
			mini: (state) => state.globals.mini,
		}),

		styleObj() {
			return {
				[this.$vuetify.rtl ? "right" : "left"]: "-15px",
				position: "absolute",
				top: "1rem",
				zIndex: 6 /* More than header because this should display on top of header */,
				border: "2px solid grey !important",
			};
		},

		getSuitableIcon() {
			/**
			 * -------------- CASE TO BE HANDLE -----------------------
			 * 1. When drawer is opened and rtl is true.
			 * 2. When drawer is closed and rtl is false.
			 * ( In both cases show icon of close_drawer(left_pointing))
			 */
			if (
				(this.mini && this.$vuetify.rtl) ||
				(!this.mini && !this.$vuetify.rtl)
			) {
				return "left_chevron";
			}
			/**
			 * -------------- ELSE CASES CAN BE -----------------------
			 * 1. When drawer is opened and rtl is true.
			 * 2. When drawer is closed and rtl is false.
			 * ( In both cases show icon of open_drawer(right_pointing))
			 */
			return "right_chevron";
		},
	},

	methods: {
		toggleDrawerStatus() {
			try {
				this.$store.commit("globals/TOGGLE_DRAWER_STATUS");
			} catch (error) {
				this.$announce.error(error);
			}
		},
	},
};
</script>
