<template>
	<v-list avatar class="ps-2" v-if="modules && modules.length">
		<div v-for="(research_module, index) in modules" :key="index">
			<v-list-group
				active-class="font-weight-bold"
				:append-icon="
					shouldDisabled(research_module.value)
						? $vuetify.icons.values.lock_outline
						: $vuetify.icons.values.down_arrow
				"
				:value="research_module.value == current_module"
				@click="current_module = research_module.value"
				:disabled="shouldDisabled(research_module.value)"
			>
				<!-- Complete Module Button -->
				<template v-slot:activator>
					<v-list-item-icon class="me-4 my-3">
						<v-btn
							fab
							outlined
							:class="[
								{
									'btn-active-wr': current_module == research_module.value,
									'btn-wr': true,
									greyDarken4: isCompletedModule(research_module.value),
								},
							]"
						>
							<v-icon
								v-if="isCompletedModule(research_module.value)"
								small
								color="amberDarken1"
								>$vuetify.icons.values.check</v-icon
							>
							<span v-else>{{ index + 1 }}</span>
						</v-btn>
					</v-list-item-icon>

					<!-- Module Title -->
					<v-list-item-title
						:class="[
							'the-navigation-steps-wr',
							getModuleColor(research_module.value) + '--text',
						]"
						>{{ $t(`app.${research_module.title}.title`) }}</v-list-item-title
					>
				</template>

				<!-- Module Content -->
				<v-list-item>
					<v-stepper
						v-model="current_stage"
						vertical
						non-linear
						class="white py-0 elevation-0 the-navigation-stepper-wr the-navigation-sub-steps-wr"
					>
						<!-- Modules' stages -->
						<template v-for="stage in research_module.children">
							<Stages
								:key="'stage-' + stage.value"
								:title="stage.title"
								:stage="stage.value"
								:steps_routes="getStepsRoutes(stage)"
								@mutate-step-and-redirect="
									mutateStageAndRedirect(stage.value, stage.route)
								"
								@close-step="closeCurrentStep()"
							></Stages>

							<!-- Stages' steps -->
							<v-stepper-content
								:key="'content-' + stage.value"
								:step="stage.value"
								:class="[
									'pb-7',
									'the-navigaton-stepper-step-content-wr',
									'cursor-wr',
									'mt-n2 mb-n4',
									{ 'pr-6': $vuetify.rtl },
								]"
								:style="styleObj"
							>
								<Steps v-if="current_stage" :steps="stage.children"></Steps>
							</v-stepper-content>
						</template>
					</v-stepper>
				</v-list-item>
			</v-list-group>
			<v-divider></v-divider>
		</div>
	</v-list>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { mapFields } from "vuex-map-fields";
import { researchModulesRoutes } from "@/router/authority";
import Stages from "@/components/research/modules/_common/stages/Arrangement.vue";
import Steps from "@/components/research/modules/_common/stages/StepsArrangement.vue";
import { isArrayHavingItem } from "@/utils/helpers";

export default {
	name: "ModulesIndex",

	data() {
		return {
			isArrayHavingItem,
		};
	},

	components: {
		Stages,
		Steps,
	},

	computed: {
		...mapState({
			modules: (state) => state.research.modules,
		}),

		...mapFields("research", {
			current_module: "current_module",
			current_stage: "current_stage",
		}),

		...mapGetters({
			isAdmin: "user/isAdmin",
			getModuleColor: "research/getModuleColor",
			userProfile: "user/getProfile",
		}),

		styleObj() {
			return {
				marginRight: this.$vuetify.rtl ? "29px" : "-36px",
				marginLeft: "29px",
			};
		},

		latestAvailableStage() {
			return this.$store.getters["user/getLatestAvailableStage"]();
		},
	},

	methods: {
		isCompletedModule(research_module) {
			return research_module < this.latestAvailableStage.module_id;
		},

		shouldDisabled(module_value) {
			try {
				if (this.isAdmin) return false;

				return module_value != this.latestAvailableStage.module_id;
			} catch (error) {
				this.$announce.error(error);
			}
		},

		mutateStageAndRedirect(stage, route) {
			try {
				// Mutate current step
				this.current_stage = stage;
				// Redirect to step's respected route (If any)
				if (!route) return;
				this.$router.push({ name: route });
			} catch (error) {
				this.$announce.error(error);
			}
		},

		getStepsRoutes(stage) {
			try {
				let result = [];
				// Stage's children are the steps, pick their routes
				if (stage.children) {
					stage.children.forEach((item, index) => {
						result.push(item.route);
					});
				}
				return result;
			} catch (error) {
				this.$announce.error(error);
			}
		},

		closeCurrentStep() {
			try {
				this.current_stage = null;
			} catch (error) {
				this.$announce.error(error);
			}
		},
	},
};
</script>

<style scoped>
>>> .v-list-group__header__append-icon {
	min-width: 0 !important;
}

.btn-active-wr {
	border: 1px solid var(--v-amberDarken1-base);
	font-weight: bold;
}

.btn-wr {
	width: 27px !important;
	height: 27px !important;
	font-size: 0.8125rem !important; /** 13px */
}

>>> .v-list-group--disabled .v-list-item {
	cursor: not-allowed !important;
}

>>> .v-list-group--disabled .v-list-item__title {
	color: var(--v-secondary-base) !important;
}

.the-navigation-stepper-wr {
	width: 300px !important;
}

.the-navigaton-stepper-step-content-wr {
	line-height: 26px !important;
}

.the-navigation-steps-wr {
	font-size: 0.9375rem !important; /** 15px */
}

.the-navigation-sub-steps-wr {
	font-size: 0.875rem !important; /** 14px */
}

>>> .v-list-group--disabled .mdi-lock-outline {
	color: var(--v-greyDarken2-base) !important;
}
</style>
