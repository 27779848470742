import { notifications } from "./notifications";
import { calendar } from "./calendar";
import { dashboard } from "./dashboard";
import { toolsRoutes } from "./tools";
import { settings } from "./settings";
import { help } from "./help";
import { projects } from "./projects";
import { work } from "./work";

export const studentRoutes = [
	...notifications,
	...calendar,
	...dashboard,
	...toolsRoutes,
	...settings,
	...help,
	...projects,
	...work,
];
