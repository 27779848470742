export const defineQuestionSummary = [
	{
		path: "/research/define_question_summary",
		name: "DefineQuestionSummary",
		component: () =>
			/* webpackChunkName:"page.dq-s"*/ import(
				"@/pages/research/modules/defineQuestion/stages/Summary.vue"
			),
		meta: {
			layout: "AppLayout",
			requiresAuth: true,
			header: {
				component: "SummaryQuestionnaire",
			},
			footer: {
				component: "SummaryQuestionnaire",
			},
		},
	},
];
