import { callApi } from "./manager";

export const keyword = {
	list: () => {
		return callApi(`/rsc/keywords`, "get");
	},

	mindmapList: () => {
		return callApi(`/rsc/keywords?mindmap=true`, "get");
	},

	create: (payload) => {
		return callApi(`/rsc/keywords/`, "post", payload);
	},

	toggleFav: (payload) => {
		return callApi(`/rsc/keywords/${payload.keywordId}/`, "put", payload);
	},

	toggleCompleted: (payload) => {
		return callApi(`/rsc/keywords/${payload.keywordId}/`, "put", payload);
	},

	favlist: () => {
		return callApi(`/rsc/keywords?selected=true`, "get");
	},

	del: (payload) => {
		return callApi(`/rsc/keywords/${payload.id}/`, "delete");
	},

	getOfStudent: (payload) => {
		return callApi(`/rsc/keywords?user_id=${payload.user_id}`, "get");
	},
};
