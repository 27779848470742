export const chooseQuestion = [
	{
		path: "/research/choose_question",
		name: "ChooseResearchQuestion",
		component: () =>
			import(
				/* webpackChunkName:"page.dq-cq"*/ "@/pages/research/modules/defineQuestion/stages/ChooseQuestion"
			),
		meta: {
			layout: "AppLayout",
			requiresAuth: true,
			header: {
				component: "ChooseQuestion",
			},
			footer: {
				component: "ChooseQuestion",
			},
		},
	},
];
