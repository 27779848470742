<template>
	<v-row no-gutters v-if="data">
		<!-- Left button -->
		<v-col>
			<AppButton
				v-if="data.leftBtn"
				:label="data.leftBtn.title"
				:icon="$vuetify.rtl ? 'right_chevron' : 'left_chevron'"
				:left_icon="true"
				@click="$eventBus.$emit(data.leftBtn.event_name)"
			></AppButton>
		</v-col>
		<!-- Right Button -->
		<v-col class="text-end">
			<AppButton
				v-if="data.rightBtn"
				:label="data.rightBtn.title"
				:icon="$vuetify.rtl ? 'left_chevron' : 'right_chevron'"
				@click="$eventBus.$emit(data.rightBtn.event_name)"
			></AppButton>
		</v-col>
	</v-row>
</template>

<script>
export default {
	name: "ButtonsTypeFooter",

	props: {
		data: {
			required: true,
		},
	},
};
</script>
