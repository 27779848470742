const path_prefix = "/research/tools/standard_mind_map";

export const standardMindmap = [
	{
		path: `${path_prefix}/list`,
		name: "StandardMindmapList",
		component: () =>
			import(
				/* webpackChunkName:"page.standard-list"*/ "@/pages/users/student/tools/standardMindmap/List.vue"
			),
		meta: {
			layout: "AppLayout",
			requiresAuth: true,
			header: {
				title: "app.tools.standard_mind_map.header.title",
				subtitle: "app.tools.standard_mind_map.list.subtitle",
			},
			footer: {
				component: "Tools",
			},
		},
	},
	{
		path: `${path_prefix}/:id`,
		name: "StandardMindmapExplore",
		component: () =>
			import(
				/* webpackChunkName:"page.standard-explore"*/ "@/pages/users/student/tools/standardMindmap/Index.vue"
			),
		meta: {
			layout: "AppLayout",
			requiresAuth: true,
			header: {
				component: "ToolsMindmap",
			},
			footer: {
				component: "Tools",
			},
		},
	},
];
