var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"value":_vm.dialog,"scrollable":"","width":"600px","persistent":""}},[_c('AppDialog',{attrs:{"heading":{
			label: _vm.current_step,
			color: 'amberDarken1',
		},"action":{
			label: 'app.okay',
			event: 'finish',
		}},on:{"close":function($event){return _vm.$emit('close')},"finish":function($event){return _vm.finishModuleAndRedirect()}},scopedSlots:_vm._u([{key:"dialog-content",fn:function(){return [_c('v-img',{attrs:{"src":require('@/assets/images/clapping.png'),"width":"80"}}),_c('div',{staticClass:"title font-weight-bold mt-8"},[_vm._v(" "+_vm._s(_vm.$t("app.good_work"))+" ")]),_c('div',{staticClass:"mt-2 subtitle-1",domProps:{"innerHTML":_vm._s(
					_vm.$t('app.finish_step_description', {
						current_step: _vm.$t(_vm.current_step),
					})
				)}}),_c('div',{staticClass:"body-1 mt-6",domProps:{"innerHTML":_vm._s(_vm.$t('app.good_luck'))}})]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }