import { reflectJourneyResearchPlan } from "./stages/confirm_timeline";
import { achieveChl } from "./stages/achieve_chl";
import { interactNetw } from "./stages/interact_netw";
// import { lifeLessons } from "./stages/life_lessons";
import { reflectJourneySummary } from "./stages/summary";

export const reflectJourneyRoutes = [
	...reflectJourneyResearchPlan,
	...achieveChl,
	...interactNetw,
	// ...lifeLessons,
	...reflectJourneySummary,
];
