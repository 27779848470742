import { CITATION_SOURCES } from "@/utils/helpers/source.js";
import moment from "moment";
import { isArrayHavingItem } from "@/utils/helpers";

export const referenceManagerMixin = {
	methods: {
		async isSourceDocumented(source) {
			if (source.source_type && source.accessed_by) {
				let valid = true;
				let citation_sources =
					CITATION_SOURCES[source.source_type][source.accessed_by];
				for (const item of citation_sources) {
					valid &=
						item.required &&
						![null, undefined, ""].includes(source[item.model]);
				}
				return valid;
			}
			return false;
		},

		async prepareForBook(item, attributes) {
			let publication_date = moment(item.publication_date).format("YYYY");
			let format = `${item.author_name}. (${publication_date}). ${item.title}. ${item.publisher}`;

			// if accessed by online
			if (isArrayHavingItem(attributes, "origin")) {
				format = `${format}. ${item.origin}`;
			}

			return {
				id: item.id,
				text: format,
				alias: `${item.author_name}, ${publication_date}`,
			};
		},

		async prepareForJournal(item, attributes) {
			let publication_date = moment(item.publication_date).format("YYYY");
			let format = `${item.author_name}. (${publication_date}). ${item.title}. ${item.paper_journal_name}. ${item.volume}(${item.issue}), ${item.from_page}-${item.to_page}`;

			// if accessed by online
			if (isArrayHavingItem(attributes, "origin")) {
				format = `${format}. ${item.origin}`;
			}

			return {
				id: item.id,
				text: format,
				alias: `${item.author_name}, ${publication_date}`,
			};
		},

		async prepareForNewsPaper(item, attributes) {
			let publication_date = moment(item.publication_date).format("YYYY");
			let format = `${item.author_name}. (${publication_date}). ${item.title}. ${item.paper_journal_name}, ${item.from_page}-${item.to_page}`;

			// if accessed by online
			if (isArrayHavingItem(attributes, "origin")) {
				format = `${format}. ${item.origin}`;
			}

			return {
				id: item.id,
				text: format,
				alias: `${item.author_name}, ${publication_date}`,
			};
		},

		async prepareForVisual(item) {
			let publication_date = moment(item.publication_date).format("YYYY");
			let format = `${item.creator}. (${publication_date}). ${item.title}. ${item.publication_place}. ${item.origin}`;

			return {
				id: item.id,
				text: format,
				alias: `${item.creator}, ${publication_date}`,
			};
		},

		async prepareForMusic(item, attributes) {
			let publication_date = moment(item.publication_date).format("YYYY");
			let format = `${item.creator}. (${publication_date}). ${item.title}. ${item.publication_place}`;

			// if accessed by online
			if (isArrayHavingItem(attributes, "origin")) {
				format = `${format}. ${item.origin}`;
			}

			return {
				id: item.id,
				text: format,
				alias: `${item.creator}, ${publication_date}`,
			};
		},

		async prepareForPhoto(item, attributes) {
			let publication_date = moment(item.publication_date).format("YYYY");
			let format = `${item.creator}. (${publication_date}). ${item.title}. ${item.publication_place}`;

			// if accessed by online
			if (isArrayHavingItem(attributes, "origin")) {
				format = `${format}. ${item.origin}`;
			}

			return {
				id: item.id,
				text: format,
				alias: `${item.creator}, ${publication_date}`,
			};
		},

		async prepareReferenceFormat(payload) {
			if (!Array.isArray(payload)) {
				throw new Error("input must be an array", {
					cause: "werCustom",
				});
			}

			let references = [];

			for (const item of payload) {
				let documented = await this.isSourceDocumented(item);
				if (documented) {
					let citation_sources =
						CITATION_SOURCES[item.source_type][item.accessed_by];
					let attributes = citation_sources.map((item) => item.model);

					switch (item.source_type) {
						case "book":
							var result = await this.prepareForBook(item, attributes);
							references.push(result);
							break;
						case "journal":
							var result = await this.prepareForJournal(item, attributes);
							references.push(result);
							break;
						case "newspaper":
							var result = await this.prepareForNewsPaper(item, attributes);
							references.push(result);
							break;
						case "visual":
							var result = await this.prepareForVisual(item);
							references.push(result);
							break;
						case "music":
							var result = await this.prepareForMusic(item, attributes);
							references.push(result);
							break;
						case "photo":
							var result = await this.prepareForPhoto(item, attributes);
							references.push(result);
							break;
						default:
							let format = [];
							for (const attrib of attributes) {
								format.push(item[attrib]);
							}
							format.join(". ");
							break;
					}
				}
			}
			// return sorted (by alphabetically) references
			return references.sort((a, b) => a.text.localeCompare(b.text));
		},
	},
};
