export const gatherSourcesSummary = [
	{
		path: "/research/gather_sources_summary",
		name: "GatherSourcesSummary",
		component: () =>
			import(
				/* webpackChunkName:"page.gs-s"*/ "@/pages/research/modules/gatherSources/stages/Summary.vue"
			),
		meta: {
			layout: "AppLayout",
			requiresAuth: true,
			header: {
				component: "SummaryQuestionnaire",
			},
			footer: {
				component: "SummaryQuestionnaire",
			},
		},
	},
];
