import { callApi } from "./manager";

export const statement = {
	list: () => {
		return callApi("/integration/statement", "get");
	},

	get: (payload) => {
		return callApi("/integration/statement/", "get", payload);
	},

	add: (payload) => {
		return callApi("/integration/statement/", "post", payload);
	},

	update: (payload) => {
		return callApi(
			`/integration/statement/${payload.statement_id}/`,
			"put",
			payload.data,
		);
	},

	delete: (payload) => {
		return callApi(`/integration/statement/${payload.statement_id}`, "delete");
	},
};
