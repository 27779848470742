import { globals } from "./globals";
import { category } from "./category";
import { research } from "./research";
import { auth } from "./auth";
import { user } from "./user";
import { questionnaire } from "./questionnaire";
import { agora } from "./agora";
import { mindmap } from "./mindmap";
import { whiteboard } from "./whiteboard";
import { keyword } from "./keyword";
import { source } from "./source";
import { tag } from "./tag";
import { process } from "./process";
import { planAndManage } from "./planAndManage";
import { statement } from "./statement";
import { interview } from "./interview";
import { temporary } from "./temporary";
import { researchPaper } from "./researchPaper";
import { notification } from "./notification";
import { standardMindmap } from "./tools/standardMindmap";
import { wizardMindmap } from "./tools/wizardMindmap";
import { student } from "./student";
import { sourceNote } from "./sourceNote";
import { meetingSummary } from "./tools/meetingSummary";
import { aws } from "./aws";
import { etherpad } from "./etherpad";
import { translator } from "./tools/translator";
import { group } from "./group";
import { toolsResearchPaper } from "./tools/researchPaper";
import { query } from "./tools/query";
import { tts } from "./tools/tts";

// Mentor modules
import { mentorBoard } from "./users/mentor/board";
import { mentorQuestionnaire } from "./users/mentor/questionnaire";
import { mentorSource } from "./users/mentor/source";
import { mentorMindmap } from "./users/mentor/mindmap";

export const modules = {
	globals,
	category,
	research,
	auth,
	user,
	questionnaire,
	agora,
	mindmap,
	whiteboard,
	keyword,
	source,
	tag,
	process,
	planAndManage,
	temporary,
	statement,
	interview,
	researchPaper,
	notification,
	standardMindmap,
	wizardMindmap,
	student,
	sourceNote,
	meetingSummary,
	aws,
	etherpad,
	translator,
	group,
	toolsResearchPaper,
	query,
	tts,
	// Mentor modules
	mentorBoard,
	mentorQuestionnaire,
	mentorSource,
	mentorMindmap,
};
