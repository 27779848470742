export const tools = [
	{
		path: `/research/tools/index`,
		name: "Tools",
		component: () =>
			import(
				/* webpackChunkName:"page.tools"*/ "@/pages/users/student/tools/Index.vue"
			),
		meta: {
			layout: "AppLayout",
			requiresAuth: true,
			header: {
				title: "app.tools.title",
				subtitle: "app.tools.subtitle",
			},
		},
	},
];
