<template>
	<v-stepper :value="progress_step" class="transparent elevation-0 stepper-wr">
		<v-stepper-header class="stepper-header-wr full-height-wr">
			<template v-for="(step, index) in steps">
				<!--
            Show outlined button only for active step.
            for others, display the button as text.
           -->
				<v-btn
					:key="index"
					fab
					small
					class="mt-4"
					color="amberDarken1"
					:outlined="progress_step == step.value"
					:text="progress_step !== step.value"
				>
					<v-stepper-step
						:complete="progress_step > step.value"
						:complete-icon="`${step.value}`"
						:key="index + 'step'"
						:step="step.value"
						:color="step.value <= progress_step ? 'amberDarken1' : ''"
					>
					</v-stepper-step>
				</v-btn>
				<v-divider
					v-if="step.value < steps.length"
					:key="index + 'd'"
					:class="[
						progress_step > step.value ? 'amberDarken1' : '',
						'divider-wr',
					]"
				>
				</v-divider>
			</template>
		</v-stepper-header>
	</v-stepper>
</template>

<script>
export default {
	name: "AppStepsProgress",

	props: {
		progress_step: {
			required: true,
		},
		steps: {
			required: true,
			type: Array,
		},
	},
};
</script>

<style scoped>
.stepper-wr {
	margin-top: -19px;
}

.divider-wr {
	border: solid 1px;
	margin-top: 14px;
}

>>> .v-stepper__step__step {
	height: 32px;
	width: 32px;
	margin: 0px !important;
	background: var(--v-greyLighten2-base) !important;
	color: black !important;
}

>>> .v-stepper__step__step .v-icon {
	font-style: normal !important;
	font-size: 0.75rem !important; /** 12px */
	color: black !important;
}

>>> .v-btn--outlined {
	border: 2px solid;
}
</style>
