var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.modules && _vm.modules.length)?_c('v-list',{staticClass:"ps-2",attrs:{"avatar":""}},_vm._l((_vm.modules),function(research_module,index){return _c('div',{key:index},[_c('v-list-group',{attrs:{"active-class":"font-weight-bold","append-icon":_vm.shouldDisabled(research_module.value)
					? _vm.$vuetify.icons.values.lock_outline
					: _vm.$vuetify.icons.values.down_arrow,"value":research_module.value == _vm.current_module,"disabled":_vm.shouldDisabled(research_module.value)},on:{"click":function($event){_vm.current_module = research_module.value}},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-icon',{staticClass:"me-4 my-3"},[_c('v-btn',{class:[
							{
								'btn-active-wr': _vm.current_module == research_module.value,
								'btn-wr': true,
								greyDarken4: _vm.isCompletedModule(research_module.value),
							} ],attrs:{"fab":"","outlined":""}},[(_vm.isCompletedModule(research_module.value))?_c('v-icon',{attrs:{"small":"","color":"amberDarken1"}},[_vm._v("$vuetify.icons.values.check")]):_c('span',[_vm._v(_vm._s(index + 1))])],1)],1),_c('v-list-item-title',{class:[
						'the-navigation-steps-wr',
						_vm.getModuleColor(research_module.value) + '--text' ]},[_vm._v(_vm._s(_vm.$t(("app." + (research_module.title) + ".title"))))])]},proxy:true}],null,true)},[_c('v-list-item',[_c('v-stepper',{staticClass:"white py-0 elevation-0 the-navigation-stepper-wr the-navigation-sub-steps-wr",attrs:{"vertical":"","non-linear":""},model:{value:(_vm.current_stage),callback:function ($$v) {_vm.current_stage=$$v},expression:"current_stage"}},[_vm._l((research_module.children),function(stage){return [_c('Stages',{key:'stage-' + stage.value,attrs:{"title":stage.title,"stage":stage.value,"steps_routes":_vm.getStepsRoutes(stage)},on:{"mutate-step-and-redirect":function($event){return _vm.mutateStageAndRedirect(stage.value, stage.route)},"close-step":function($event){return _vm.closeCurrentStep()}}}),_c('v-stepper-content',{key:'content-' + stage.value,class:[
								'pb-7',
								'the-navigaton-stepper-step-content-wr',
								'cursor-wr',
								'mt-n2 mb-n4',
								{ 'pr-6': _vm.$vuetify.rtl } ],style:(_vm.styleObj),attrs:{"step":stage.value}},[(_vm.current_stage)?_c('Steps',{attrs:{"steps":stage.children}}):_vm._e()],1)]})],2)],1)],1),_c('v-divider')],1)}),0):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }