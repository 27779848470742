<template>
	<v-navigation-drawer
		permanent
		absolute
		dark
		mini-variant
		mini-variant-width="79"
		class="wr_indigo_2"
		:right="$vuetify.rtl"
		:left="!$vuetify.rtl"
		:style="{ zIndex: 10 }"
	>
		<v-divider></v-divider>

		<!-- Top Icons -->
		<TheSideNavIcons :icons="topIcons"></TheSideNavIcons>

		<!-- Bottom Icons -->
		<template v-slot:append>
			<TheSideNavIcons :icons="bottomIcons"></TheSideNavIcons>
		</template>
	</v-navigation-drawer>
</template>

<script>
import TheSideNavIcons from "@/components/layout/TheSideNavIcons";
import { mapGetters, mapState } from "vuex";
import { mentorRoutes, studentRoutes, adminRoutes } from "@/router/authority";

export default {
	name: "TheSideNav",

	components: {
		TheSideNavIcons,
	},

	computed: {
		...mapState({
			projects: (state) => state.user.projects,
		}),

		...mapGetters({
			isMentor: "user/isMentor",
			isAdmin: "user/isAdmin",
		}),

		isProjectsRoute() {
			return this.$route.name == "Projects";
		},

		notification() {
			return {
				name: "notification",
				title: "app.messages.title",
				route: {
					name: this.isMentor ? "MentorNotifications" : "Notifications",
				},
				related_routes: this.isMentor
					? mentorRoutes.notifications
					: ["Notifications"],
			};
		},

		calendar() {
			return {
				name: "calendar",
				title: "app.calendar.title",
				route: {
					name: this.isMentor ? "MentorCalendar" : "ResearchCalendar",
				},
				related_routes: this.isMentor
					? mentorRoutes.calendar
					: ["ResearchCalendar"],
			};
		},

		dashboard() {
			return {
				name: "dashboard",
				title: "app.dashboard.title",
				route: {
					name: this.isMentor ? "MentorDashboard" : "UserDashboard",
				},
				related_routes: this.isMentor
					? mentorRoutes.dashboard
					: ["UserDashboard"],
			};
		},

		tools() {
			return {
				name: "wrench",
				title: "app.tools.title",
				route: {
					name: this.isMentor ? "MentorTools" : "Tools",
				},
				related_routes: this.isMentor
					? mentorRoutes.tools
					: studentRoutes.tools,
			};
		},

		project() {
			return {
				name: "projects",
				title: "app.my_projects.title",
				route: {
					name: "Projects",
				},
				related_routes: studentRoutes.projects,
			};
		},

		group() {
			return {
				name: "network-group",
				title: "app.groups.title",
				route: {
					name: this.isMentor ? "MentorGroups" : "AdminGroups",
				},
				related_routes: this.isMentor ? mentorRoutes.group : adminRoutes.group,
			};
		},

		work() {
			return {
				name: "work",
				title: "app.research_work.sidebar.title",
				route: {
					name: "Work",
				},
				related_routes: studentRoutes.work,
			};
		},

		document() {
			return {
				name: "notepad",
				title: "app.my_documents",
				route: null,
				related_routes: [],
			};
		},

		help() {
			return {
				name: "help",
				title: "app.get_help",
				route: {
					name: "Help",
				},
				related_routes: studentRoutes.help,
			};
		},

		users() {
			return {
				name: "users",
				title: "app.manage_users",
				route: {
					name: "AdminUsers",
				},
				related_routes: adminRoutes.users,
			};
		},

		settings() {
			return {
				name: "settings",
				title: "app.settings.title",
				route: {
					name: this.isMentor ? "MentorSettings" : "UserSettings",
				},
				related_routes: this.isMentor
					? mentorRoutes.settings
					: ["UserSettings"],
			};
		},

		accessibility() {
			return {
				name: "accessibility",
				title: "app.accessibility",
				route: null,
				related_routes: [],
			};
		},

		topIcons() {
			let icons = [];

			// If project route, then only push project icon
			if (this.isProjectsRoute) {
				icons.push(this.project);
				return icons;
			}

			// Messages, calendar, dashboard, tools are available for all entities
			icons.push(this.notification, this.calendar, this.dashboard, this.tools);

			// If user is any entity except mentor
			if (!this.isMentor) {
				icons.push(this.work);
				// Push projects only if student has more than one projects
				if (this.projects && this.projects.length > 1) {
					icons.push(this.project);
				}
			}

			// If user is mentor
			if (this.isMentor || this.isAdmin) {
				icons.push(this.group);
			}

			// Users is available for admin only
			if (this.isAdmin) {
				icons.push(this.users);
			}

			return icons;
		},

		bottomIcons() {
			let icons = [];

			// If not project route, then only push settings
			if (!this.isProjectsRoute) {
				icons.push(this.settings);
			}

			// Accessibility is available for all entities
			icons.push(this.accessibility);

			return icons;
		},
	},
};
</script>
