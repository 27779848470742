const path_prefix = "/research/tools/editor";

export const editor = [
	{
		path: `${path_prefix}/index`,
		name: "ToolsEditor",
		component: () =>
			import(
				/* webpackChunkName:"page.editor"*/ "@/pages/users/student/tools/editor/OnBoard.vue"
			),
		meta: {
			layout: "AppLayout",
			requiresAuth: true,
			header: {
				title: "app.tools.research_editor.title",
			},
			footer: {
				component: "Tools",
			},
		},
	},

	{
		path: `${path_prefix}/:pad_id`,
		name: "EditorExplorePage",
		component: () =>
			import(
				/* webpackChunkName:"page.editor-explore"*/ "@/pages/users/student/tools/editor/Index.vue"
			),
		meta: {
			layout: "AppLayout",
			requiresAuth: true,
			header: {
				title: "app.tools.research_editor.title",
			},
			footer: {
				component: "Tools",
			},
		},
	},

	{
		path: `${path_prefix}/collaborate/research_paper/:id`,
		name: "ToolsCollaborateResearchPaper",
		component: () =>
			import(
				/* webpackChunkName:"page.editor-explore"*/ "@/pages/users/student/tools/editor/ResearchPaper.vue"
			),
		meta: {
			layout: "AppLayout",
			requiresAuth: true,
			header: {
				title: "app.tools.research_editor.title",
			},
			footer: {
				component: "Tools",
			},
		},
	},
];
