export const help = [
	{
		path: "/research/help/index",
		name: "Help",
		component: () =>
			import(
				/* webpackChunkName:"page.help"*/ "@/pages/users/student/help/Index.vue"
			),
		meta: {
			layout: "AppLayout",
			requiresAuth: true,
			header: true,
		},
	},
];
