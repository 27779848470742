<template>
	<v-expansion-panels flat v-model="panel" multiple>
		<v-expansion-panel>
			<v-expansion-panel-header class="pa-4">
				<template v-slot:actions>
					<v-icon color="wr_blue_1" size="24" class="expansion-left-icon-wr"
						>$vuetify.icons.values.arrow_down</v-icon
					>
				</template>
				<div class="expansion-left-icon-header-wr mx-2">
					<span class="font-weight-bold subtitle-1 ">{{
						$t("app.documentation")
					}}</span>
					<template
						v-if="source.author_name || source.creator || source.candidate"
					>
						<br />
						<span>{{ displayDetails }}</span>
					</template>
				</div>
			</v-expansion-panel-header>

			<v-expansion-panel-content>
				<v-card flat :class="['mt-4 greyLighten4']">
					<v-row no-gutters>
						<!-- Source Type Selection List -->
						<v-col>
							<v-card-text class="font-weight-bold"
								>{{ $t("app.source_type") }}
							</v-card-text>
							<v-card-text class="py-0">
								<v-select
									v-model="source.source_type"
									:items="source_types"
									item-text="text"
									item-value="value"
									:placeholder="$t('app.select_any_source_type')"
									outlined
									dense
									color="greenAccent2"
									background-color="white"
								>
									<template v-slot:item="{ item }">
										{{ $t(item.text) }}
									</template>
									<template v-slot:selection="{ item }">
										{{ $t(item.text) }}
									</template>
								</v-select>
							</v-card-text>
						</v-col>
						<!-- If source type is selected then show accessed_by -->
						<v-col>
							<template v-if="source.source_type">
								<v-card-text class="font-weight-bold">{{
									$t("app.access_mode")
								}}</v-card-text>
								<v-card-text class="py-0">
									<v-radio-group v-model="source.accessed_by" row class="ma-0">
										<v-radio
											v-for="(accessed_by, index) in accessedByItems"
											:key="'s' + index"
											color="greenAccent2"
											:label="$t(accessed_by.text)"
											:value="accessed_by.value"
										></v-radio>
									</v-radio-group>
								</v-card-text>
							</template>
						</v-col>
					</v-row>

					<!--
							If source type is book and source accessed_by is
							selected then show the ISBN searching functionality.
					-->
					<v-card-text
						v-if="source.source_type == 'book' && source.accessed_by"
						class="pb-0"
					>
						<v-alert
							:icon="$vuetify.icons.values.bulb_on"
							text
							dense
							dismissible
							type="warning"
						>
							<b>{{ $t("app.msg.isbn_numbers_tagline") }}&ensp;</b>
							<a href="https://en.wikipedia.org/wiki/ISBN" target="__blank">{{
								$t("app.read_about_isbn")
							}}</a>
						</v-alert>

						<v-row no-gutters>
							<v-col>
								<v-text-field
									v-model="isbn"
									outlined
									class="px-1"
									dense
									type="number"
									color="greenAccent2"
									background-color="white"
									:label="$t('app.placeholder.search_with_isbn')"
									:counter="13"
									maxlength="13"
									:hint="$t('app.isbn_example')"
								></v-text-field>
							</v-col>
							<v-col sm="2">
								<AppButton
									label="app.search"
									:block="true"
									color="info"
									:disabled="!isbn"
									@click="searchByISBN()"
								></AppButton>
							</v-col>
						</v-row>
					</v-card-text>
					<v-divider></v-divider>

					<!-- If source accessed_by is selected then show form to fill -->
					<template v-if="source.accessed_by">
						<v-divider></v-divider>
						<v-card-text>
							<v-row no-gutters class="mt-3">
								<v-col
									sm="6"
									class="px-1"
									v-for="option in options"
									:key="option.name"
								>
									<v-form :ref="`form-${sourceId}`">
										<!-- For "text" type fields -->
										<v-text-field
											v-if="!option.type"
											v-model="source[option.model]"
											:label="$t(option.name)"
											outlined
											dense
											color="greenAccent2"
											background-color="white"
											:rules="getRules(option)"
										></v-text-field>

										<DatePicker
											v-if="option.type === 'date'"
											v-model="source[option.model]"
											:prop_date="source[option.model]"
											:placeholder="$t(option.name)"
											:outlined="true"
											:dense="true"
											:rules="getRules(option)"
										></DatePicker>
									</v-form>
								</v-col>
							</v-row>
							<AppButton
								label="app.save"
								icon="save"
								color="success"
								:prop_class="['mt-5']"
								@click="updateSource()"
							></AppButton>
						</v-card-text>
					</template>
				</v-card>
				<ISBNResultDialog
					v-if="isbn_dialog"
					:dialog="isbn_dialog"
					:book="isbn_result"
					@import-book="saveBookByISBN"
					@close="closeISBNDialog()"
				></ISBNResultDialog>
			</v-expansion-panel-content>
			<v-divider></v-divider>
		</v-expansion-panel>
	</v-expansion-panels>
</template>

<script>
import DatePicker from "@/components/plugins/DatePicker.vue";
import rules from "@/utils/validation";
import {
	SOURCE_ACCESSED_BY,
	SOURCE_ACCESSED_BY_OPTIONS,
	CITATION_SOURCES,
} from "@/utils/helpers/source.js";
import { getBook } from "@/services/isbn.js";
import ISBNResultDialog from "@/components/research/modules/gatherSources/source/ISBNResultDialog.vue";
import { mapGetters } from "vuex";

export default {
	name: "SourceDocumentation",

	props: {
		sourceId: {
			required: true,
			type: Number,
		},
		sourceObj: {
			required: false,
		},
		panelProp: {
			default: null,
		},
	},

	data() {
		return {
			rules,
			panel: this.panelProp,
			source_types: [
				{ value: "book", text: "app.book" },
				{ value: "journal", text: "app.journal_article" },
				{ value: "newspaper", text: "app.newspaper_article" },
				{ value: "visual", text: "app.visual" },
				{ value: "music", text: "app.music" },
				{ value: "photo", text: "app.photo" },
				{ value: "interview", text: "app.interview" },
				{ value: "observation", text: "app.observation" },
				{ value: "primary", text: "app.primary_source" },
			],
			isbn: null,
			isbn_dialog: false,
			isbn_result: null,
		};
	},

	components: {
		DatePicker,
		ISBNResultDialog,
	},

	computed: {
		...mapGetters({
			getSource: "source/getSource",
		}),

		displayDetails() {
			let person =
				this.source.author_name || this.source.creator || this.source.candidate;
			return (
				person +
				" " +
				this.source.publication_date +
				", " +
				this.source.review_date
			);
		},

		isWorkRoute() {
			return this.$route.name === "SourcesWork";
		},

		source() {
			// If source is a prop
			if (this.sourceObj) return this.sourceObj;
			// If not, then find
			return this.getSource(this.sourceId);
		},

		accessedByItems() {
			return CITATION_SOURCES[this.source.source_type]["accessed_by"];
		},

		options() {
			return CITATION_SOURCES[this.source.source_type][this.source.accessed_by];
		},
	},

	methods: {
		getRules(option) {
			let extra_rules = option?.extra_rules ? option.extra_rules : [];
			return option.required ? [rules.required].concat(extra_rules) : [];
		},

		async updateSource() {
			try {
				this.$loader.start();

				await this.validate();

				/**
				 * del attachment key as put api expect attachment
				 * to always be a file
				 */
				var source = Object.assign({}, this.source);
				delete source["attachment"];
				await this.$store.dispatch("source/update", source);

				// It means request is from research paper, don't show alert then
				if (!this.sourceObj) {
					this.$announce.success("app.ntfy.succ.document_updated");
				}
				// used in new-reference-dialog
				this.$emit("save-to-etherpad", source);

				this.panel = null;
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		validate() {
			let refs = this.$refs[`form-${this.sourceId}`];
			let valid = true;

			refs.forEach((ref) => {
				valid &= ref.validate();
			});

			if (!valid) {
				throw new Error("app.ntfy.err.fill_required_fields", {
					cause: "werCustom",
				});
			}
		},

		closeISBNDialog() {
			this.isbn_dialog = false;
			this.isbn = null;
			this.isbn_result = null;
		},

		async searchByISBN() {
			try {
				// get book from isbn api
				let res = await getBook(this.isbn);
				// if isbn found any
				if (res) {
					// open preview dialog
					this.isbn_dialog = true;
					// send info to dialog
					this.isbn_result = res;
				} else {
					this.closeISBNDialog();
					throw new Error("app.ntfy.err.book_not_found_by_isbn", {
						cause: "werCustom",
					});
				}
			} catch (error) {
				this.$announce.error(error);
			}
		},

		saveBookByISBN(payload) {
			try {
				this.$loader.start();
				/**
				 * If accessed_by is not set "online" then no need to
				 * use origin(source url) param.
				 */
				if (this.source.accessed_by != "online") {
					delete payload.origin;
				}
				/**
				 * 1. Merge the current book source and isbn searched
				 * book source.
				 */
				let result = { ...this.source, ...payload };
				// 2. Assign merged data to current book source
				Object.assign(this.source, result);
				// Close the dialog
				this.closeISBNDialog();
				// throw book successful import notification
				this.$announce.success("app.ntfy.succ.book_imported");
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},
	},
};
</script>
