import { query as API } from "@/services/api";
import { getField, updateField } from "vuex-map-fields";
import Vue from "vue";
import ShortUniqueId from "short-unique-id";
const uid = new ShortUniqueId({ length: 10 });

// INITIATE STATE
function initialState() {
	return {
		// choose question query
		choose_question: {
			grade: null,
			current_step: null,
			step1_question: null,
			step1_answer: null,
			step2_questions: {
				open_question_1: null,
				open_question_2: null,
				close_question_1: null,
				close_question_2: null,
			},
			step2_answer: null,
			step4_answer: null,
			multiple: [],
		},
		// mindmap query
		mindmap: {
			grade: null,
			current_step: null,
			step1_question: null,
			step1_answer: null,
			step1_map: null,
			step2_map: null,
			step2_answer: null,
			step3_map: null,
			step3_branches: null,
			step3_answer: null,
			step4_answer: null,
			attempts: [],
			beautify: true,
		},
		// toc
		toc: {
			grade: null,
			current_step: null,
			step1_question: null,
			step1_answer: null,
			step2_toc: null,
			step2_answer: null,
			step3_toc: null,
			step3_answer: null,
		},
		// keywords
		keywords: {
			grade: null,
			current_step: null,
			step1_question: null,
			step1_answer: null,
			step2_keywords: null,
			step2_answer: null,
			step3_keywords: null,
			step3_answer: null,
			step4_keywords: null,
			step4_answer: null,
			step4_selected_sources: [],
		},
	};
}

// STATE
const state = initialState();

// GETTERS
const getters = {
	getField,
};

// ACTIONS
const actions = {
	async list(context, payload) {
		return await API.list(payload);
	},

	async create(context, payload) {
		return await API.create(payload);
	},

	async get(context, payload) {
		return await API.get(payload);
	},
};

// MUTATIONS
const mutations = {
	updateField,

	UPDATE_MAP: (state, payload) => {
		state.mindmap[`step${payload.step}_map`].data = payload.data;
	},

	ADD_TOC: (state, payload) => {
		let step = payload.step + "_toc";
		// new chapter is reqested to be added
		if (!payload.id) {
			state.toc[step].push({
				id: uid(),
				parentId: 1,
				title: payload.title,
				description: payload.description,
			});
		}
		// subchapter is requested to be added
		if (payload.id == -1) {
			let parentIndex = state.toc[step].findIndex(
				(item) => item.id == payload.parentId,
			);

			if (parentIndex != -1) {
				let data = {
					id: uid(),
					parentId: payload.parentId,
					title: payload.title,
					description: payload.description,
				};
				let index = state.toc[step][parentIndex].children.findIndex(
					(item) => item.id === -1,
				);

				state.toc[step][parentIndex].children.splice(index, 0, data);
			}
		}
	},

	UPDATE_TOC: (state, payload) => {
		let step = payload.step + "_toc";

		delete payload.step;

		// If parent id is 1 that means its a level one parent element
		let parent_id = payload.parentId == 1 ? payload.id : payload.parentId;
		let parentIndex = state.toc[step].findIndex((item) => item.id == parent_id);
		if (parentIndex == -1) return;

		// If parent id is 1 that means its a level one parent element
		if (payload.parentId == 1) {
			Vue.set(state.toc[step], parentIndex, payload);
			console.log(state.toc[step][parentIndex]);
			return;
		}

		// Else its a child element
		let childIndex = state.toc[step][parentIndex].children.findIndex(
			(item) => item.id == payload.id,
		);
		if (childIndex != -1) {
			Vue.set(state.toc[step][parentIndex].children, childIndex, payload);
		}
	},

	DELETE_TOC: (state, payload) => {
		// If input has no children that means its a children element
		let find_id = payload.parentId != 1 ? payload.parentId : payload.id;
		let parentIndex = state.toc.step2_toc.findIndex(
			(item) => item.id == find_id,
		);
		if (parentIndex == -1) return;

		// If input has no children that means its a children element
		if (payload.parentId != 1) {
			let childIndex = state.toc.step2_toc[parentIndex].children.findIndex(
				(item) => item.id == payload.id,
			);
			if (childIndex != -1) {
				Vue.delete(
					state.toc.step2_toc[parentIndex].children,
					childIndex,
					payload,
				);
			}
		} else {
			Vue.delete(state.toc.step2_toc, parentIndex, payload);
		}
	},

	RESET_STATE: (state) => {
		const s = initialState();
		Object.keys(s).forEach((key) => {
			state[key] = s[key];
		});
	},
};

// Export module
export const query = {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
