<template>
	<v-navigation-drawer
		permanent
		width="390"
		class="white"
		:mini-variant="mini"
		mini-variant-width="100"
		app
		:style="styleObject"
		:right="$vuetify.rtl"
		:left="!$vuetify.rtl"
	>
		<TheSideNav></TheSideNav>

		<!-- Skillsx logo -->
		<v-list class="py-0">
			<v-list-item>
				<v-container @click.stop="redirectToHome()">
					<v-list-item-content class="text-center">
						<v-list-item-title>
							<h4
								class="cursor-wr title"
								v-html="$t('app.skillsx_with_style')"
							></h4>
						</v-list-item-title>
					</v-list-item-content>
				</v-container>
			</v-list-item>
		</v-list>

		<!-- A title -->
		<v-card class="stages-parent-container-wr" flat>
			<v-list class="py-0">
				<v-list-item>
					<v-container>
						<v-list-item-content class="text-center">
							<v-list-item-title
								:class="{
									'subheading title': true,
								}"
								><strong>{{ $t("app.my_research_progress") }}:</strong>
							</v-list-item-title>
						</v-list-item-content>
					</v-container>
				</v-list-item>
			</v-list>

			<!-- Research Steps (Do not show if drawer is opened in mini form) -->
			<v-container fluid v-if="!mini" class="pt-0 pe-2">
				<ResearchStages />
			</v-container>
		</v-card>
	</v-navigation-drawer>
</template>

<script>
import ResearchStages from "@/components/research/modules/Index";
import TheSideNav from "@/components/layout/TheSideNav";
import { mapState, mapGetters } from "vuex";

export default {
	name: "TheDrawer",
	data() {
		return {
			width: window.innerWidth,
		};
	},

	components: {
		ResearchStages,
		TheSideNav,
	},

	computed: {
		...mapState({
			mini: (state) => state.globals.mini,
		}),
		...mapGetters({
			isAuthorized: "auth/isAuthorized",
			userLang: "user/getLanguage",
		}),

		styleObject() {
			let obj = {};
			if (this.$vuetify.rtl) {
				obj.paddingRight = "67px";
			} else {
				obj.paddingLeft = "67px";
			}
			return obj;
		},
	},

	watch: {
		width(val) {
			if (val <= this.$vuetify.breakpoint.thresholds.md) {
				this.$store.commit("globals/TOGGLE_DRAWER_STATUS", {
					status: true,
				});
			}
		},
	},

	mounted() {
		// When resize window check drawer opening status
		window.addEventListener("resize", () => {
			try {
				this.width = window.innerWidth;
			} catch (error) {
				this.$announce.error(error);
			}
		});
	},

	methods: {
		redirectToHome() {
			this.$router.push({
				name: "Home",
				query: {
					lang: this.userLang,
				},
			});
		},
	},
};
</script>

<style scoped>
>>> .v-navigation-drawer__content {
	border-right: 1.5px solid grey;
	border-right-color: rgba(0, 0, 0, 0.12);
}
.stages-parent-container-wr {
	margin-top: 11px;
}

h4 {
	text-shadow: 1px 1px rgb(199, 130, 130) !important;
}
</style>
