import { knowledgeOrganizers } from "./root";
import { literature } from "./literature/index";
import { history } from "./history/index";
import { science } from "./science/index";
import { bible } from "./bible/index";
import { civicStudies } from "./civicStudies/index";

export const knowledgeOrganizersRoutes = [
	...knowledgeOrganizers,
	...literature,
	...history,
	...science,
	...bible,
	...civicStudies,
];
