<template>
	<v-row no-gutters v-if="current_step">
		<!-- Left button -->
		<v-col>
			<AppButton
				v-if="leftBtn.show"
				:label="leftBtn.title"
				:disabled="leftBtn.disabled"
				:icon="leftBtn.icon || null"
				:left_icon="true"
				@click="$eventBus.$emit(leftBtn.event_name)"
			></AppButton>
		</v-col>

		<!-- Step Progress -->
		<v-col align="center">
			<AppStepsProgress
				v-model="progress_step"
				:progress_step="progress_step"
				:steps="steps"
			></AppStepsProgress>
		</v-col>

		<!-- Right Button -->
		<v-col class="text-end">
			<AppButton
				v-if="rightBtn.show"
				:label="rightBtn.title"
				:disabled="leftBtn.disabled"
				:icon="rightBtn.icon || null"
				@click="$eventBus.$emit(rightBtn.event_name)"
			></AppButton>
		</v-col>
	</v-row>
</template>

<script>
import { mapState } from "vuex";
import { chooseQuestionBoardSteps } from "@/utils/steps";
import { footerStepsMixin } from "@/mixins/footerStepsMixin";

export default {
	name: "ChooseQuestionFooter",

	mixins: [footerStepsMixin],

	computed: {
		...mapState({
			current_step: (state) => state.process.current_step,
			questionnaire: (state) => state.questionnaire.list,
		}),

		steps() {
			return chooseQuestionBoardSteps;
		},

		leftBtn() {
			let btnObj = {
				title: "app.back",
				disabled: false,
				show: this.current_step.previous_step,
				event_name: this.current_step.back_event,
				icon: this.$vuetify.rtl ? "right_chevron" : "left_chevron",
			};

			return btnObj;
		},

		rightBtn() {
			let btnObj = {
				title: "app.next",
				disabled: false,
				show: true,
				event_name: this.current_step.next_event,
				icon: this.$vuetify.rtl ? "left_chevron" : "right_chevron",
			};

			switch (this.current_step.name) {
				case "instructions":
					btnObj.title = "app.continue";
					btnObj.icon = null;
					break;
				case "questionnaire":
					btnObj.title = "app.next";
					if (
						this.questionnaire &&
						this.questionnaire.status ==
							this.$defines.QUESTIONNAIRE_STATUS_WAIT_FOR_APPROVAL_STRING
					) {
						btnObj.title = "app.confirm_and_send_to_mentor";
						btnObj.event_name = "crq-finish-process";
					}

					break;
			}

			return btnObj;
		},
	},
};
</script>
