import { store } from "@/store";
import { DEFINES } from "@/utils/defines";
import { findFromArray, flatArrayOfObjects } from "@/utils/helpers";

export const getSelectedStandardMindmap = async () => {
	let standard_maps = await store.dispatch("mindmap/list", {
		category: DEFINES.SYSTEM_CATEGORY,
	});

	if (standard_maps && standard_maps.data.length) {
		return findFromArray(standard_maps.data, "selected", true);
	}
	return null;
};

export const mindMapHelper = {
	async getWizardMapFavKeywords(only_fav = true) {
		let fav_keywords = [];

		let wizard_map = await store.dispatch("whiteboard/list", {
			category: DEFINES.SYSTEM_CATEGORY,
			type: DEFINES.WHITEBOARD_TYPES.WIZARD_MINDMAP,
		});

		// Only if map found
		if (wizard_map && wizard_map.length) {
			wizard_map[0].data.forEach((item) => {
				/**
				 * Push keyword only when two condition matches
				 * 1. select only fav and item is added to fav
				 * 2. Don't select only fav, means select all.
				 */
				if ((only_fav && item.fav) || !only_fav) {
					fav_keywords.push({
						id: item.id,
						name: item.text,
					});
				}
			});
		}

		return fav_keywords;
	},

	async getStandardMapFavKeywords(only_fav = true) {
		let fav_keywords = [];

		let mindmap = await getSelectedStandardMindmap();

		// Only if map found
		if (mindmap) {
			let data = mindmap.data.nodeData;
			// Only if map has some data
			if (data) {
				// root node can be fav too so check it first.
				if ((only_fav && data.fav) || !only_fav) {
					fav_keywords.push({
						id: data.id,
						name: data.topic,
					});
				}
				// Only if map's data has some children
				if (data.children) {
					// Flat all nested children
					let result = flatArrayOfObjects(data.children, "children");
					if (result) {
						// Now, find fav nodes.
						result.forEach((item) => {
							if ((only_fav && item.fav) || !only_fav) {
								fav_keywords.push({
									id: item.id,
									name: item.topic,
								});
							}
						});
					}
				}
			}
		}

		return fav_keywords;
	},

	async getStandardMapReferences() {
		// Fetch all mind maps
		let maps = await store.dispatch("mindmap/list", {
			category: DEFINES.SYSTEM_CATEGORY,
		});
		// If no maps, return
		if (!maps || !maps.data || !maps.data.length) return null;

		let result = [];

		// Else, loop on each map
		for (const map of maps.data) {
			let data = map.data.nodeData;

			function isReferenceAvailable(arr) {
				let reference_note = arr.find((item) => item.id == "references");
				return reference_note ? reference_note.value : null;
			}

			// root node can have references too so check it first.
			let root_node_references = isReferenceAvailable(data.notes);
			if (root_node_references) {
				result = [...result, ...root_node_references];
			}

			// find child nodes' references
			if (data.children && data.children.length) {
				// Flat all nested children
				let children = flatArrayOfObjects(data.children, "children");

				// Now, find fav nodes.
				children.forEach((item) => {
					let child_references = isReferenceAvailable(item.notes);
					if (child_references) {
						result = [...result, ...child_references];
					}
				});
			}
		}

		return result;
	},
};
