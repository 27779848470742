import Vue from "vue";

export const environments = {
	// Api constants
	base_url: process.env.VUE_APP_BASE_URL,
	base_api_url: process.env.VUE_APP_BASE_API_URL,
	base_app_url: process.env.VUE_APP_BASE_APP_URL,
	environment: process.env.VUE_APP_ENV,

	// Latest build version
	build_version: process.env.VUE_APP_BUILD_VERSION,

	// Localization constants
	i18n_locale: process.env.VUE_APP_I18N_LOCALE,
	i18n_fallback_locale: process.env.VUE_APP_I18N_FALLBACK_LOCALE,

	// Firebase Configurations
	firebase_api_key: process.env.VUE_APP_FIREBASE_API_KEY,
	firebase_auth_domain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
	firebase_project_id: process.env.VUE_APP_FIREBASE_PROJECT_ID,
	firebase_storage_bucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
	firebase_messaging_sender_id: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
	firebase_app_id: process.env.VUE_APP_FIREBASE_APP_ID,
	firebase_measurement_id: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,

	// You tube Configurations
	youtube_api_key: process.env.VUE_APP_YOU_TUBE_API_KEY,

	// Etherpad configuration
	etherpad_base_api_url: process.env.VUE_APP_ETHERPAD_BASE_API_URL,
	etherpad_api_key: process.env.VUE_APP_ETHERPAD_API_KEY,

	// accessibility configuration
	accessibility_key: process.env.VUE_APP_ACCESSIBILITY_KEY,
	accessibility_integrity: process.env.VUE_APP_ACCESSIBILITY_INTEGRITY,
};

Vue.prototype.$environments = environments;
