<template>
	<v-dialog v-model="dialog" persistent max-width="500">
		<v-card flat>
			<v-card-text class="pa-10">
				<v-row align="center" justify="center">
					<v-col cols="12" align="center">
						<v-progress-circular
							v-if="show_timer"
							:rotate="360"
							:size="50"
							:width="5"
							:value="second * (100 / 20)"
							color="success"
						>
							{{ second }}
						</v-progress-circular>
						<Loading v-else></Loading>
					</v-col>
					<v-col
						cols="12"
						class="headline black--text text-center font-weight-bold"
					>
						{{ $t(data.title) }}
					</v-col>
					<v-col cols="12" class="subtitle-1 text-center pt-0">
						{{ $t(data.body) }}
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
import Loading from "@/components/plugins/Loading.vue";

export default {
	name: "LoaderDialog",

	components: {
		Loading,
	},

	data() {
		return {
			dialog: false,
			second: 20, // remove hardcode after discussion
			interval_id: null,
			show_timer: false,
			data: {
				title: "app.processing_request",
				body: "app.dont_refresh_page",
			},
		};
	},

	created() {
		this.readyToListenEvents();
	},

	methods: {
		readyToListenEvents() {
			// For timer to start
			this.startTimer();
			// For timer to stop
			this.stopTimer();
			// For loader to start
			this.startLoader();
			// For loader to stop
			this.stopLoader();
		},

		startTimer() {
			this.$eventBus.$on("start-timer", (payload) => {
				// show timer instead of loader
				this.show_timer = true;
				// Set up interval to run the second loop
				this.interval_id = setInterval(() => {
					if (this.second == 0) {
						this.second = 20;
					} else {
						this.second--;
					}
				}, 1000);
			});
		},

		stopTimer() {
			this.$eventBus.$on("stop-timer", (payload) => {
				clearInterval(this.interval_id);
				this.second = 20;
				this.show_timer = false;
			});
		},

		startLoader() {
			this.$eventBus.$on("start-loader", (payload = this.data) => {
				this.dialog = true;

				if (payload.title) {
					this.data.title = payload.title;
				}
				if (payload.body) {
					this.data.body = payload.body;
				}
			});
		},

		stopLoader() {
			this.$eventBus.$on("stop-loader", (payload) => {
				this.dialog = false;
			});
		},
	},
};
</script>
