import { planAndManage as API } from "@/services/api";
import moment from "moment";
import { getField, updateField } from "vuex-map-fields";
import {
	sortArrayOfObjects,
	mapObjectKeys,
	findElIndex,
} from "@/utils/helpers";
import Vue from "vue";
import groupBy from "lodash/groupBy";

// INITIATE STATE
function initialState() {
	return {
		start_date: {},
		user_plan: [],
		user_avail: null,
		current_tab: "timeline",
	};
}

// STATE
const state = initialState();

// GETTERS
const getters = {
	getField,
	getSortedUserPlan: (state) => {
		return sortArrayOfObjects(state.user_plan, "id");
	},

	getModulesByGroup: (state, getters) => (input_plan) => {
		let plan = sortArrayOfObjects(input_plan, "id");
		// Filter the items which has step && step.module
		let filtered = plan.filter(
			(item) => item.module && item.module.name && item.module.name !== "M1",
		);
		if (!filtered || !filtered.length) return null;

		/**
		 * Group the plan by modules names. It should look like-
		 * { M2: [], M3: [], M4: [], M5: []}
		 */
		return groupBy(filtered, function(item) {
			return item.module.name;
		});
	},

	getModulesGroup: (state, getters) => (input_plan = null) => {
		let plan = input_plan || getters.getSortedUserPlan;
		// Filter the items which has step && step.module
		let filtered = plan.filter(
			(item) => item.step && item.step.module && item.step.module.name,
		);
		if (!filtered || !filtered.length) return null;

		/**
		 * Group the plan by modules names. It should look like-
		 * { M2: [], M3: [], M4: [], M5: []}
		 */
		return groupBy(filtered, function(item) {
			return item.step.module.name;
		});
	},

	getCalendarPlan: (state, getters) => (module_name = null) => {
		let data = [];
		// If perticular module is requested
		if (module_name) {
			let modules_group = getters.getModulesGroup();
			if (!modules_group || !modules_group[module_name]) return [];
			data = modules_group[module_name];
		}
		// if all modules is requested
		else {
			data = getters.getSortedUserPlan;
		}
		// As api keys and full calender needs different keys of start and end time
		var keys = { plan_start_date: "start", plan_end_date: "end" };
		// Prepare necessary data
		return data.map(function(o) {
			// Map "plan_start_date" key to "start" and "plan_end_date" to "end"
			return mapObjectKeys(o, (value, key) => {
				return key in keys ? keys[key] : key;
			});
		});
	},

	getModulePlan: (state, getters, rootState, rootGetters) => () => {
		// Group the modules
		let modules_group = getters.getModulesGroup();
		if (!modules_group) return [];

		// Now, loop in each module and prepare data.
		let result = [];
		for (let module_name in modules_group) {
			let obj = modules_group[module_name][0];
			let module_id = parseInt(module_name[1]);
			// Prepare the required info about module
			result.push({
				module_id,
				module: module_name.replace("M", "Module "),
				start_date: obj.plan_start_date,
				color: rootGetters["research/getModuleColor"](module_id),
			});
		}

		return result;
	},

	getModuleStagePlan: (state, getters, rootState, rootGetters) => (
		module_name,
		input_plan = null,
	) => {
		if (!input_plan) {
			// Group the modules
			var modules_group = getters.getModulesGroup();
			if (!modules_group || !modules_group[module_name]) return [];
		}

		let module_plan = input_plan
			? input_plan[module_name]
			: modules_group[module_name];

		var obj = {};
		// Loop on requested module's items
		module_plan.map((o) => {
			// o.step.id is unique and common in two same plans
			let key = o.step.id;
			// If key is already exists in obj then only update one property
			if (obj[key]) {
				obj[key].plan_end_date = moment(o.plan_end_date).format("ll");
				return;
			}
			// Else create new key
			obj[key] = {
				id: key,
				stage: o.title,
				plan_start_date: moment(o.plan_start_date).format("ll"),
				plan_start_time: moment(o.plan_start_date).format("LT"),
				plan_end_date: moment(o.plan_end_date).format("ll"),
				actual_start_date: o.actual_start_date
					? moment(o.actual_start_date).format("ll")
					: null,
				actual_end_date: o.actual_end_date
					? moment(o.actual_end_date).format("ll")
					: null,
				position: o.step.position,
				module_id: parseInt(o.step.module.name[1]),
				color: rootGetters["research/getModuleColor"](o.step.module.name[1]),
			};
		});

		return sortArrayOfObjects(Object.values(obj), "position");
	},

	getStagePlan: (state, getters) => (stage_position) => {
		return getters.getSortedUserPlan.find((plan) => {
			if (plan.step != null) {
				return plan.step.position == stage_position;
			}
		});
	},

	getAvailableStepData: (state) => (id) => {
		return state.user_plan.find((item) => {
			return item.step && item.step.id == id;
		});
	},
};

// ACTIONS
const actions = {
	async getStartDate(context) {
		let response = await API.getStartDate();
		context.commit("SET_START_DATE", response);
		return response;
	},

	async setStartDate(context, payload) {
		let response = await API.setStartDate(payload);
		return response;
	},

	async getUserAvail(context) {
		let response = await API.getUserAvail();
		context.commit("SET_USER_AVAIL", response);
		return response;
	},

	async createUserAvail(context, payload) {
		let response = await API.createUserAvail(payload);
		return response;
	},

	async updateUserAvail(context, payload) {
		let response = await API.updateUserAvail(payload);
		return response;
	},

	async deleteUserAvail(context, payload) {
		let response = await API.deleteUserAvail(payload);
		return response;
	},

	async calculateResearchPlan(context) {
		let response = await API.calculateResearchPlan();
		context.commit("SET_USER_PLAN", response);
		return response;
	},

	async getUserPlan(context, payload) {
		let response = await API.getUserPlan(payload);
		context.commit("SET_USER_PLAN", response);
		return response;
	},

	async createUserPlan(context, payload) {
		let response = await API.createUserPlan(payload);
		context.commit("CREATE_USER_PLAN", response);
		return response;
	},

	async updateUserPlan(context, payload) {
		// The type should always have the first letter in capital.
		payload.type = payload.type.charAt(0).toUpperCase() + payload.type.slice(1);

		let response = await API.updateUserPlan(payload);
		context.commit("UPDATE_USER_PLAN", response);

		return response;
	},

	async delUserPlan(context, payload) {
		await API.delUserPlan(payload);
		context.commit("DELETE_USER_PLAN", payload);
	},

	async getOfStudent(context, payload) {
		return await API.getOfStudent(payload);
	},

	async getPlanSteps(context, payload) {
		return await API.getPlanSteps(payload);
	},
};

// MUTATIONS
const mutations = {
	updateField,
	SET_START_DATE: (state, payload) => {
		state.start_date = payload;
	},

	SET_USER_AVAIL: (state, payload) => {
		state.user_avail = payload;
	},

	SET_USER_PLAN: (state, payload) => {
		state.user_plan = payload;
	},

	CREATE_USER_PLAN: (state, payload) => {
		state.user_plan.push(payload);
	},

	UPDATE_USER_PLAN: (state, payload) => {
		let el_index = findElIndex(state.user_plan, "id", payload.id);

		if (el_index !== -1) {
			Vue.set(state.user_plan, el_index, payload);
		}
	},

	DELETE_USER_PLAN: (state, payload) => {
		let objIndex = findElIndex(state.user_plan, "id", payload.id);

		if (objIndex !== -1) {
			state.user_plan.splice(objIndex, 1);
		}
	},

	RESET_STATE: (state) => {
		const s = initialState();
		Object.keys(s).forEach((key) => {
			state[key] = s[key];
		});
	},
};

// Export module
export const planAndManage = {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
