<template>
	<v-row no-gutters v-if="questionnaire">
		<!-- Left button -->
		<v-col :sm="isStepProgressEnabled ? 4 : 6">
			<AppButton
				v-if="leftBtn.show"
				:label="leftBtn.title"
				:left_icon="true"
				:icon="leftBtn.icon || null"
				:disabled="leftBtn.disabled"
				@click="$eventBus.$emit(leftBtn.event_name)"
			></AppButton>
		</v-col>

		<v-col sm="4" align="center" v-if="isStepProgressEnabled">
			<AppStepsProgress
				v-model="current_step"
				:progress_step="current_step"
				:steps="steps"
			></AppStepsProgress>
		</v-col>

		<!-- Right Button -->
		<v-col :sm="isStepProgressEnabled ? 4 : 6" class="text-end">
			<AppButton
				v-if="rightBtn.show"
				:label="
					current_step && current_step.next_step_title
						? current_step.next_step_title
						: rightBtn.title
				"
				:icon="rightBtn.icon || null"
				:disabled="rightBtn.disabled"
				@click="$eventBus.$emit(rightBtn.event_name)"
			></AppButton>
		</v-col>
	</v-row>
</template>

<script>
import { mapState } from "vuex";
import AppStepsProgress from "@/components/ui/AppStepsProgress";

export default {
	name: "QuestionnaireFooter",

	data() {
		return {
			current_step: null,
		};
	},

	components: {
		AppStepsProgress,
	},

	mounted() {
		this.initiateStepNumber();
	},

	watch: {
		current_questionnaire(newVal) {
			switch (newVal) {
				case this.$defines.QUESTIONNAIRE.q30:
				case this.$defines.QUESTIONNAIRE.q40:
					this.current_step = 2;
					break;
				default:
					this.current_step = 1;
					break;
			}
		},
	},

	computed: {
		...mapState({
			questionnaire: (state) => state.questionnaire.list,
			current_questionnaire: (state) =>
				state.questionnaire.choose_topic_current_questionnaire,
		}),

		isStepProgressEnabled() {
			return this.$route.name == "ChooseTopicSummary";
		},

		steps() {
			return [{ value: 1 }, { value: 2 }];
		},

		isQuestionnaireApproved() {
			return (
				this.questionnaire.status ==
				this.$defines.QUESTIONNAIRE_STATUS_WAIT_FOR_APPROVAL_STRING
			);
		},

		leftBtn() {
			let obj = {};
			obj.title = "app.back";
			obj.event_name = "redirect-to-previous";
			obj.disabled = false;
			obj.show = true;
			obj.icon = this.$vuetify.rtl ? "right_chevron" : "left_chevron";

			switch (this.$route.name) {
				case "ChooseThemeSummary":
					obj.show = false;
					break;
				case "ChooseTopicSummary":
					obj.show = this.current_step > 1;
					obj.disabled = this.isQuestionnaireApproved;
					break;
			}
			return obj;
		},

		rightBtn() {
			let obj = {};
			obj.title = "app.continue";
			obj.event_name = "redirect-to-next";
			obj.disabled = false;
			obj.show = true;
			obj.icon = this.$vuetify.rtl ? "left_chevron" : "right_chevron";

			switch (this.$route.name) {
				case "ChooseTopicSummary":
					obj.title = this.isQuestionnaireApproved
						? "app.confirm_and_send_to_mentor"
						: "app.next";
					if (this.isQuestionnaireApproved) {
						obj.event_name = "finish-choose-topic";
					}

					break;
				case "ChooseThemeSummary":
					obj.title = this.isQuestionnaireApproved
						? "app.confirm_and_send_to_mentor"
						: "app.next";
					obj.event_name = this.isQuestionnaireApproved
						? "finish-choose-field"
						: "submit-questionnaire";

					break;
			}
			return obj;
		},
	},

	methods: {
		initiateStepNumber() {
			try {
				this.current_step =
					this.$store.state.questionnaire.choose_topic_current_questionnaire ==
					this.$defines.QUESTIONNAIRE.q20
						? 1
						: 2;
			} catch (error) {
				this.$announce.error(error);
			}
		},
	},
};
</script>
