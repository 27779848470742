const path_prefix = "/research/mentor/tools/standard_mind_map";

export const standardMindmap = [
	{
		path: `${path_prefix}/list`,
		name: "MentorStandardMindmapList",
		component: () =>
			import(
				/* webpackChunkName:"page.m-standard-list"*/ "@/pages/users/student/tools/standardMindmap/List.vue"
			),
		meta: {
			layout: "MentorLayout",
			requiresAuth: true,
			header: {
				title: "app.tools.standard_mind_map.header.title",
			},
			footer: {
				component: "MentorToolsFooter",
			},
		},
	},
	{
		path: `${path_prefix}/:id`,
		name: "MentorStandardMindmapExplore",
		component: () =>
			import(
				/* webpackChunkName:"page.m-standard-view"*/ "@/pages/users/student/tools/standardMindmap/Index.vue"
			),
		meta: {
			layout: "MentorLayout",
			requiresAuth: true,
			header: {
				title: "app.tools.standard_mind_map.header.title",
			},
			footer: {
				component: "MentorToolsFooter",
			},
		},
	},
];
