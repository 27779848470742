<template>
	<v-app>
		<TheVersionUpdateAlert v-if="version_updated"></TheVersionUpdateAlert>

		<!-- To listen all TheAnnouncements (notifications or messages) -->
		<TheAnnouncements />

		<!-- To display loader while performing any request -->
		<TheLoader />

		<template>
			<!-- If parent is not loaded yet -->
			<TheSplashScreen v-if="!parent_loaded"></TheSplashScreen>

			<!-- If parent is loaded and user is allowed to access the requested page -->
			<component :is="layout" v-if="is_allowed && parent_loaded"> </component>
		</template>

		<!-- Check if authorized user is trying to access any non-allowed route -->
		<TheAccessDenied v-if="!is_fetching && !is_allowed"></TheAccessDenied>

		<!-- If user is inactive for a while then this will open -->
		<TheIdle v-model="idle_dialog" @close="idle_dialog = false"></TheIdle>

		<AppIntroVideo v-if="!isMentor"></AppIntroVideo>
	</v-app>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { mapFields } from "vuex-map-fields";
import TheLoader from "@/components/plugins/TheLoader";
import * as layouts from "@/components/layout/types";
import TheAnnouncements from "@/components/plugins/TheAnnouncements";
import TheAccessDenied from "@/components/layout/TheAccessDenied";
import { notificationUpdatesMixin } from "@/mixins/notificationsMixin";
import firebase from "@/services/firebase";
import TheVersionUpdateAlert from "@/components/layout/TheVersionUpdateAlert.vue";
import moment from "moment";
import { jwtManagementMixin } from "@/mixins/jwtManagementMixin";
import TheSplashScreen from "@/components/layout/TheSplashScreen.vue";
import TheIdle from "@/components/layout/TheIdle.vue";
import { loadAccessibilityScript } from "@/services/accessibility";
import { updateAccessibilityParams } from "@/services/accessibility";
import AppIntroVideo from "@/components/ui/AppIntroVideo";

export default {
	name: "App",

	mixins: [notificationUpdatesMixin, jwtManagementMixin],

	data() {
		return {
			is_fetching: true,
			is_allowed: false,
			parent_loaded: false,
			idle_dialog: false,
		};
	},

	head: {
		// To use "this" in the component, it is necessary to return the object through a function
		title: function() {
			return {
				inner:
					this.$environments.environment == "test"
						? "ideanow"
						: this.$environments.environment == "production"
						? "SkillsX"
						: "wer-local",
				separator: " ",
			};
		},
	},

	components: {
		...layouts,
		TheLoader,
		TheAnnouncements,
		TheAccessDenied,
		TheVersionUpdateAlert,
		TheSplashScreen,
		TheIdle,
		AppIntroVideo,
	},

	computed: {
		...mapState({
			mini: (state) => state.globals.mini,
			version_updated: (state) => state.globals.version_updated,
			modules: (state) => state.research.modules,
		}),

		...mapGetters({
			layout: "globals/layout",
			isAuthorized: "auth/isAuthorized",
			userLang: "user/getLanguage",
			userProfile: "user/getProfile",
			isMentor: "user/isMentor",
		}),

		...mapFields("planAndManage", {
			current_tab: "current_tab",
		}),

		userProfile_id() {
			return this.userProfile.id;
		},
	},

	watch: {
		$route(to, from) {
			// Check route accessibility
			this.isRouteAccessible();

			// Close drawer if not closed already
			if (!this.mini) {
				this.$store.commit("globals/TOGGLE_DRAWER_STATUS");
			}

			// Reset confirm stage's tab's status to 'timeline'
			this.current_tab = "timeline";
		},

		// After authenticate, get the user.
		isAuthorized(newVal, oldVal) {
			if (newVal) {
				this.keepUserUpdated();
			}
		},

		user(newVal, oldVal) {
			if (newVal && this.isAuthorized) {
				// generate jwt for user
				this.createJWT();

				// listen it's notifications
				this.listenNewNotification();

				// Get general params of the modules
				this.getRqGeneral();
				this.getRscGeneral();
			}
		},

		// On changing of user's language, update locales accordingly.
		userLang(newVal, oldVal) {
			if (newVal !== oldVal) {
				// Update locale and RTL
				this.keepLocaleAndRtlUpdated();
				// Update moment locale
				// TODO:: this.updateMomentLocale();
				// Update accessibility extension according to language
				updateAccessibilityParams(newVal);
			}
		},

		// When user profile changes, get the menu and general params
		userProfile_id() {
			this.getResearchMenu();
			this.getRscGeneral();
		},
	},

	created() {
		this.listenVersionUpdates();
	},

	mounted() {
		this.init();
	},

	onActive() {
		if (this.isAuthorized) {
			this.idle_dialog = true;
		}
	},

	methods: {
		listenVersionUpdates() {
			try {
				firebase
					.database()
					.ref(`build_version`)
					.on("value", async (snapshot) => {
						console.log("firebase_version", snapshot.val());
						console.log("env_version", this.$environments.build_version);
						this.$store.commit("globals/SHOW_UPDATE_ALERT", {
							status: this.$environments.build_version != snapshot.val(),
						});
					});
			} catch (error) {
				this.$announce.error(error);
			}
		},

		isRouteAccessible() {
			try {
				this.$loader.start();
				this.is_allowed = !this.$route.meta.requiresAuth
					? // If any route does not need auth, allow it.
					  true
					: // Otherwise check from available steps, if route is allowed or not.
					  this.$store.getters["user/isRouteAllowed"](this.$route.name);
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.is_fetching = false;
				this.$loader.stop();
			}
		},

		async init() {
			try {
				this.$loader.start();
				// check if current route is accessible or not
				this.isRouteAccessible();
				// get user from DB and set in state
				await this.keepUserUpdated();
				// update locale based on user's language
				await this.keepLocaleAndRtlUpdated();
				// update moment library locale too
				// TODO:: await this.updateMomentLocale();
				// load accessibility script
				loadAccessibilityScript(this.userLang);
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.parent_loaded = true;
				this.$loader.stop();
			}
		},

		async keepUserUpdated() {
			try {
				if (this.isAuthorized) {
					await this.$store.dispatch("user/get");
				}
			} catch (error) {
				this.$announce.error(error);
			}
		},

		async keepLocaleAndRtlUpdated() {
			try {
				await this.$store.dispatch("globals/updateLocaleAndRtl");
			} catch (error) {
				this.$announce.error(error);
			}
		},

		// TODO:: async updateMomentLocale() {
		// 	try {
		// 		moment.locale(this.userLang);
		// 	} catch (error) {
		// 		this.$announce.error(error);
		// 	}
		// },

		async getResearchMenu() {
			try {
				this.$loader.start();

				await this.$store.dispatch("research/getMenu");
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		async getRscGeneral() {
			try {
				this.$loader.start();

				await this.$store.dispatch("process/getRscGeneral");
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		async getRqGeneral() {
			try {
				this.$loader.start();

				await this.$store.dispatch("process/getRqGeneral");
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},
	},
};
</script>

<style lang="scss">
@import "@/assets/styles/main.scss";
</style>
