<template>
	<TheHeaderInterface :title="title" :subtitle="subtitle"></TheHeaderInterface>
</template>

<script>
import TheHeaderInterface from "@/components/layout/TheHeaderInterface.vue";

export default {
	name: "ToolsMindmapHeader",

	components: {
		TheHeaderInterface,
	},

	computed: {
		title() {
			let route = this.$route;
			if (route.name === "WizardMindmapExplore") {
				return "app.tools.wizard_mind_map.new_map.title";
			}
			if (route.name === "StandardMindmapExplore") {
				return "app.tools.standard_mind_map.new_map.title";
			}
		},

		subtitle() {
			let route = this.$route;
			if (route.name === "WizardMindmapExplore") {
				return "app.tools.wizard_mind_map.new_map.subtitle";
			}
			if (route.name === "StandardMindmapExplore") {
				return "app.tools.standard_mind_map.new_map.subtitle";
			}
		},
	},
};
</script>
