import { mapGetters } from "vuex";

export const projectsManageMixin = {
	computed: {
		...mapGetters({
			isMentor: "user/isMentor",
		}),
	},

	methods: {
		async getUser() {
			await this.$store.dispatch("user/get");
		},

		async getMenu() {
			await this.$store.dispatch("research/getMenu");
		},

		async pushToDashboard() {
			await this.$router.push({
				name: this.isMentor ? "MentorDashboard" : "UserDashboard",
			});
		},

		async setUpDataForDashboard() {
			// Get user details and set in state
			await this.getUser();

			// Get research menu
			await this.getMenu();

			// Push to the respected route
			await this.pushToDashboard();
		},
	},
};
