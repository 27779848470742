<template>
	<v-main>
		<!-- Navigation drawer -->
		<TheDrawer />

		<!-- Header -->
		<TheHeader v-if="$route.meta.header"></TheHeader>

		<!-- Button, to toggle drawer -->
		<TheDrawerButton></TheDrawerButton>

		<!-- Router view -->

		<v-container
			fluid
			:style="{ height: view_port_height }"
			:class="container_classes"
		>
			<router-view
				id="the-view-port"
				:key="$route.fullPath"
				class="pa-4"
			></router-view>
		</v-container>

		<!-- Footer -->
		<TheFooter v-if="$route.meta.footer"></TheFooter>
	</v-main>
</template>

<script>
import TheDrawer from "@/components/layout/TheDrawer";
import TheHeader from "@/components/layout/TheHeader";
import TheFooter from "@/components/layout/TheFooter";
import TheDrawerButton from "@/components/layout/TheDrawerButton";
import { layoutMixin } from "@/mixins/layoutMixin.js";

export default {
	name: "AppLayout",

	mixins: [layoutMixin],

	components: {
		TheDrawer,
		TheHeader,
		TheFooter,
		TheDrawerButton,
	},
};
</script>
