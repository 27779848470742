import { callApi } from "./manager";

export const user = {
	get: () => {
		return callApi(`/user/detail`, "get");
	},

	updateProfile: (payload) => {
		return callApi(`/user/detail/${payload.user_id}/`, "put", payload);
	},

	sendToMentor: (payload) => {
		return callApi(`/user/message/`, "post", payload);
	},

	updateCompletedStep: (payload) => {
		return callApi(`/user/complete_step/`, "post", payload);
	},

	resetPassword: (payload) => {
		return callApi(`/user/password/0/`, "put", payload);
	},

	createJWT: (payload) => {
		return callApi(`/infra/etherpad_encrypt/`, "post", payload);
	},

	listProjects: () => {
		return callApi(`/user/detail?all=true`, "get");
	},

	createProfile: (payload) => {
		return callApi(`/user/detail/`, "post", payload);
	},

	getStudents: () => {
		return callApi("/user/detail?students=true", "get");
	},
};
