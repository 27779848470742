import Vue from "vue";
import VueI18n from "vue-i18n";
import { store } from "@/store";

Vue.use(VueI18n);

function getRelevantLocale() {
	// Get user from store
	let user_profile = store.state.user.user;
	/**
	 * Why not make require.context() function to dynamic? i.e write
	 * it once and pass variables ?
	 * Because the arguments passed to the require.context() function
	 * must be literals (fixed values not variables).
	 * Reference-https://webpack.js.org/guides/dependency-management/#requirecontext
	 */
	// If user state is empty or user language is set to English
	if (!Object.entries(user_profile).length || user_profile.user_lang === "en") {
		return require.context("@/locales", true, /[A-Za-z0-9-_,\s]+\.json$/i);
	}
	// If gender is not set or gender is set to "male"
	if (
		!user_profile.hasOwnProperty("gender") ||
		user_profile.gender === "male"
	) {
		return require.context("@/locales/male", true, /[A-Za-z0-9-_,\s]+\.json$/i);
	}
	// If gender is set to "female"
	if (user_profile.gender === "female") {
		return require.context(
			"@/locales/female",
			true,
			/[A-Za-z0-9-_,\s]+\.json$/i,
		);
	}
}

export const loadLocaleMessages = () => {
	const locales = getRelevantLocale();
	const messages = {};
	locales.keys().forEach((key) => {
		const matched = key.match(/([A-Za-z0-9-_]+)\./i);
		if (matched && matched.length > 1) {
			const locale = matched[1];
			messages[locale] = locales(key);
		}
	});
	return messages;
};

export const i18n = new VueI18n({
	messages: loadLocaleMessages(),
	locale: Vue.prototype.$environments.i18n_locale || "en",
	fallbackLocale: Vue.prototype.$environments.i18n_fallback_locale || "en",
	silentFallbackWarn: true,
});

Vue.prototype.i18n = i18n;
