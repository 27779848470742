<template>
	<TheHeaderInterface
		v-if="current_step"
		:title="title"
		:subtitle="current_step.header_subtitle"
	></TheHeaderInterface>
</template>

<script>
import { mapState } from "vuex";
import TheHeaderInterface from "@/components/layout/TheHeaderInterface.vue";

export default {
	name: "PlanSearchStrategyHeader",

	components: {
		TheHeaderInterface,
	},

	watch: {
		"current_step.value": function(newVal, oldVal) {
			if (newVal !== oldVal) {
				this.$eventBus.$emit("calculate-view-port-height");
			}
		},
	},

	computed: {
		...mapState({
			current_step: (state) => state.process.current_step,
		}),

		title() {
			return "app.plan_search_strategy.title";
		},
	},
};
</script>
