<template>
	<v-stepper-step
		:complete="current_stage === stage || current_stage > stage"
		complete-icon=""
		:step="stage"
		:color="color"
		@click="isStageAvailable ? $emit('mutate-step-and-redirect') : null"
		class="py-0 pe-0 text-truncate"
	>
		<v-row
			no-gutters
			:class="['stepper-heading-row-wr', isStageAvailable ? 'cursor-wr' : null]"
		>
			<v-col
				:sm="steps_routes.length ? 11 : 12"
				:class="[activeStepper(), 'greyDarken4--text']"
				:style="styleObj"
				>{{ $t(`app.${title}.title`) }}</v-col
			>
			<v-col sm="1" v-if="isStageAvailable && steps_routes.length">
				<v-hover v-slot:default="{ hover }">
					<v-icon
						class=""
						x-small
						:color="hover ? 'black' : ''"
						@click.stop="
							isCurrentStage
								? $emit('close-step')
								: $emit('mutate-step-and-redirect')
						"
					>
						{{ $vuetify.icons.values[isCurrentStage ? "minus" : "plus"] }}
					</v-icon>
				</v-hover>
			</v-col>
		</v-row>
	</v-stepper-step>
</template>

<script>
import { mapState } from "vuex";
import { isArrayHavingItem } from "@/utils/helpers";

export default {
	name: "Stages",
	props: {
		title: {
			type: String,
			required: true,
		},
		stage: {
			type: Number,
			required: true,
		},
		steps_routes: {
			type: Array,
			required: true,
		},
	},

	computed: {
		...mapState({
			current_stage: (state) => state.research.current_stage,
		}),

		isCurrentStage() {
			return this.current_stage == this.stage;
		},

		isStageAvailable() {
			return this.$store.getters["user/isStageAvailable"](this.stage);
		},

		color() {
			if (this.isCurrentStage) {
				return "amberDarken1";
			}
			if (this.current_stage > this.stage) {
				return "greyDarken4";
			}
			return null;
		},

		styleObj() {
			return { opacity: this.isStageAvailable ? null : 0.6 };
		},
	},

	methods: {
		activeStepper() {
			return {
				"font-weight-bold": this.isCurrentStage,
				"text-decoration-underline":
					this.isCurrentStage && !this.anySubStepActive(),
			};
		},

		anySubStepActive() {
			// If any substep is active then return false so underline class cannot be applied to step.
			return isArrayHavingItem(this.steps_routes, this.$route.name);
		},
	},
};
</script>

<style scoped>
/* Stepper's step and every step's row */
>>> .v-stepper__label,
.stepper-heading-row-wr {
	width: 100% !important;
}
/* Step's prepend icon (small dots) */
>>> .v-stepper__step__step {
	height: 11px;
	min-width: 11px;
	width: 10px;
	border: 1px solid black;
	color: transparent !important;
	background: white !important;
}
</style>
