<template>
	<span>
		<v-icon
			v-if="use_icon"
			class="cursor-wr"
			:color="color"
			v-clipboard:copy="message"
			v-clipboard:success="onCopy"
			v-clipboard:error="onError"
			>$vuetify.icons.values.copy</v-icon
		>
		<v-card
			v-else
			flat
			dark
			tile
			class="cursor-wr"
			align="center"
			height="55"
			v-clipboard:copy="message"
			v-clipboard:success="onCopy"
			v-clipboard:error="onError"
		>
			<v-card-text class="py-3 white--text subtitle-1">
				{{ $t("app.copy_code") }}
			</v-card-text>
		</v-card>
	</span>
</template>

<script>
export default {
	name: "CopyToClipboard",

	props: {
		message: {
			required: true,
		},
		use_icon: {
			default: true,
		},
		color: {
			default: "",
		},
	},

	methods: {
		onCopy(e) {
			this.$announce.success("app.ntfy.succ.copied_successfully");
		},

		onError(e) {
			this.$announce.error("app.ntfy.err.copying_failed");
		},
	},
};
</script>

<style></style>
