const path_prefix = "/research/tools/observation";

export const observation = [
	{
		path: `${path_prefix}/list`,
		name: "ObservationsList",
		component: () =>
			import(
				/* webpackChunkName:"page.observations"*/ "@/pages/users/student/tools/observation/List.vue"
			),
		meta: {
			layout: "AppLayout",
			requiresAuth: true,
			header: {
				title: "app.observations.title",
			},
			footer: {
				component: "Tools",
			},
		},
	},
	{
		path: `${path_prefix}/list/:id`,
		name: "Observation",
		component: () =>
			import(
				/* webpackChunkName:"page.observation"*/ "@/pages/users/student/tools/observation/Index"
			),
		meta: {
			layout: "AppLayout",
			requiresAuth: true,
			header: {
				component: "Observation",
			},
			footer: {
				component: "Tools",
			},
		},
	},
];
