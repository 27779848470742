import { tag as API } from "@/services/api";

// INITIATE STATE
function initialState() {
	return {
		list: [],
	};
}

// STATE
const state = initialState();

// GETTERS
const getters = {};

// ACTIONS
const actions = {
	async list(context, payload) {
		let response = await API.list(payload);
		context.commit("SET_LIST", response);
		return response;
	},

	async create(context, payload) {
		let response = await API.create(payload);
		return response;
	},

	async delete(context, payload) {
		let response = await API.delete(payload);
		return response;
	},
};

// MUTATIONS
const mutations = {
	SET_LIST: (state, payload) => {
		state.list = payload;
	},

	RESET_STATE: (state) => {
		const s = initialState();
		Object.keys(s).forEach((key) => {
			state[key] = s[key];
		});
	},
};

// Export module
export const tag = {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
