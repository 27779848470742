<template>
	<v-dialog :value="dialog" scrollable width="600px" persistent>
		<AppDialog
			:heading="{
				label: current_step,
				color: 'amberDarken1',
			}"
			:action="{
				label: 'app.okay',
				event: 'finish',
			}"
			@close="$emit('close')"
			@finish="finishModuleAndRedirect()"
		>
			<template v-slot:dialog-content>
				<v-img
					:src="require('@/assets/images/clapping.png')"
					width="80"
				></v-img>

				<div class="title font-weight-bold mt-8">
					{{ $t("app.good_work") }}
				</div>

				<div
					class="mt-2 subtitle-1"
					v-html="
						$t('app.finish_step_description', {
							current_step: $t(current_step),
						})
					"
				></div>

				<div
					class="body-1 mt-6"
					v-html="$t('app.good_luck')"
				></div>
			</template>
		</AppDialog>
	</v-dialog>
</template>

<script>
import { accessManagementMixin } from "@/mixins/accessManagementMixin";
import AppDialog from "@/components/ui/AppDialog.vue";

export default {
	name: "StageFinishDialog",

	mixins: [accessManagementMixin],

	props: {
		dialog: {
			required: true,
			type: Boolean,
		},

		current_step: {
			required: true,
			type: String,
		},
	},

	components: {
		AppDialog,
	},

	created() {
		this.getUserPlan();
	},

	methods: {
		async getUserPlan() {
			try {
				this.$loader.start();

				await this.$store.dispatch("planAndManage/getUserPlan");
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},
	},
};
</script>

<style scoped>
.image-wr {
	vertical-align: middle !important;
}
</style>
