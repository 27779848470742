export const reflectJourneyResearchPlan = [
	{
		path: "/research/reflect_journey_confirm_timeline",
		name: "ReflectJourneyResearchPlan",
		component: () =>
			import(
				/* webpackChunkName:"page.rj-ct"*/ "@/pages/research/modules/_common/Planning.vue"
			),
		meta: {
			layout: "AppLayout",
			requiresAuth: true,
			module: "M5",
			header: {
				component: "ResearchPlanning",
			},
			footer: {
				component: "ResearchPlanning",
			},
		},
	},
];
