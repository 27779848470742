const path_prefix = "/research/tools/knowledge_organizers/history";

export const history = [
	{
		path: `${path_prefix}/index`,
		name: "History",
		component: () =>
			import(
				/* webpackChunkName:"page.ko.history"*/ "@/pages/users/student/tools/knowledgeOrganizers/history/Index.vue"
			),
		meta: {
			layout: "AppLayout",
			requiresAuth: true,
			header: {
				title: "app.ko.history.title",
				subtitle: "",
			},
			footer: {
				component: "ToolsKnowledgeOrganizers",
			},
		},
	},
	{
		path: `${path_prefix}/primary_sources`,
		name: "AnalyzingPrimarySources",
		component: () =>
			import(
				/* webpackChunkName:"page.ko.history.primary-source"*/ "@/pages/users/student/tools/knowledgeOrganizers/history/analyzingPrimarySources/Index.vue"
			),
		meta: {
			layout: "AppLayout",
			requiresAuth: true,
			header: {
				title: "app.ko.history.analyzing_primary_sources.header.title",
				subtitle: "",
			},
			footer: {
				component: "ToolsKnowledgeOrganizers",
			},
		},
	},
];
