import { whiteboard as API } from "@/services/api";
import { findElIndex } from "@/utils/helpers";

// INITIATE STATE
function initialState() {
	return {
		list: [],
	};
}

// STATE
const state = initialState();

// GETTERS
const getters = {
	isOwner: (state, getters, rootState, rootGetters) => (map_user_id) => {
		return rootGetters["user/getProfile"].id === map_user_id;
	},
};

// ACTIONS
const actions = {
	async list(context, payload) {
		let response = await API.list(payload);
		context.commit("SET_LIST", response);
	},

	async create(context, payload) {
		let response = await API.create(payload);
		context.commit("PUSH_MAP", response);
	},

	async get(context, payload) {
		return await API.get(payload);
	},

	async update(context, payload) {
		return await API.update(payload);
	},

	async delete(context, payload) {
		await API.delete(payload);
		context.commit("REMOVE_MAP", payload);
	},
};

// MUTATIONS
const mutations = {
	SET_LIST: (state, payload) => {
		state.list = payload;
	},

	PUSH_MAP: (state, payload) => {
		state.list.push(payload);
	},

	REMOVE_MAP: (state, payload) => {
		let objIndex = findElIndex(state.list, "uuid", payload.uuid);

		if (objIndex !== -1) {
			state.list.splice(objIndex, 1);
		}
	},

	RESET_STATE: (state) => {
		const s = initialState();
		Object.keys(s).forEach((key) => {
			state[key] = s[key];
		});
	},
};

// Export module
export const wizardMindmap = {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
