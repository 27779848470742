import { getField, updateField } from "vuex-map-fields";
import { meetingSummary as API } from "@/services/api";
import { findFromArray, findElIndex } from "@/utils/helpers";

// INITIATE STATE
function initialState() {
	return {
		list: [],
	};
}

// STATE
const state = initialState();

// GETTERS
const getters = {
	getField,
};

// ACTIONS
const actions = {
	// On meetings
	async list(context) {
		let response = await API.list();
		context.commit("SET_LIST", response);
	},

	async get(context, payload) {
		return await API.get(payload);
	},

	async create(context, payload) {
		return await API.create(payload);
	},

	async update(context, payload) {
		return await API.update(payload);
	},

	async delete(context, payload) {
		await API.delete(payload);
		context.commit("REMOVE_MEETING_SUMMARY", payload);
	},

	async listOfStudent(context, payload) {
		return await API.listOfStudent(payload);
	},

	// On Agendas
	async listAgendas(context, payload) {
		return await API.listAgendas(payload);
	},

	async createAgenda(context, payload) {
		return await API.createAgenda(payload);
	},

	async updateAgenda(context, payload) {
		return await API.updateAgenda(payload);
	},

	async deleteAgenda(context, payload) {
		await API.deleteAgenda(payload);
	},

	// On Todo Items
	async listTodoItems(context, payload) {
		return await API.listTodoItems(payload);
	},

	async createTodoItem(context, payload) {
		return await API.createTodoItem(payload);
	},

	async updateTodoItem(context, payload) {
		return await API.updateTodoItem(payload);
	},

	async deleteTodoItem(context, payload) {
		await API.deleteTodoItem(payload);
	},
};

// MUTATIONS
const mutations = {
	updateField,

	SET_LIST: (state, payload) => {
		state.list = payload;
	},

	REMOVE_MEETING_SUMMARY: (state, payload) => {
		let objIndex = findElIndex(state.list, "id", payload.meeting_id);

		if (objIndex !== -1) {
			state.list.splice(objIndex, 1);
		}
	},

	RESET_STATE: (state) => {
		const s = initialState();
		Object.keys(s).forEach((key) => {
			state[key] = s[key];
		});
	},
};

// Export module
export const meetingSummary = {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
