export const notifications = [
	{
		path: "/research/mentor/notifications",
		name: "MentorNotifications",
		component: () =>
			import(
				/* webpackChunkName:"page.m-notifications"*/ "@/pages/users/mentor/notification/Index.vue"
			),
		meta: {
			layout: "MentorLayout",
			requiresAuth: true,
			header: true,
		},
	},
];
