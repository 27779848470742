const path_prefix = "/research/mentor/tools/editor";

export const editor = [
	{
		path: `${path_prefix}/index`,
		name: "MentorToolsEditor",
		component: () =>
			import(
				/* webpackChunkName:"page.m-editor"*/ "@/pages/users/mentor/tools/editor/OnBoard.vue"
			),
		meta: {
			layout: "MentorLayout",
			requiresAuth: true,
			header: {
				title: "Research Editor",
			},
			footer: {
				component: "MentorToolsFooter",
			},
		},
	},

	{
		path: `${path_prefix}/:pad_id`,
		name: "MentorEditorExplorePage",
		component: () =>
			import(
				/* webpackChunkName:"page.m-editor-explore"*/ "@/pages/users/mentor/tools/editor/Index.vue"
			),
		meta: {
			layout: "MentorLayout",
			requiresAuth: true,
			header: {
				title: "Research Editor",
			},
			footer: {
				component: "MentorToolsFooter",
			},
		},
	},
];
