<template>
	<v-dialog :value="dialog" persistent scrollable>
		<AppDialog
			:heading="{
				label: label,
			}"
			:fluid="true"
			@close="$emit('close')"
		>
			<template v-slot:dialog-content>
				<Pad
					v-if="document_id && jwt && pad_created"
					:pad_id="document_id"
				></Pad>
			</template>
		</AppDialog>
	</v-dialog>
</template>

<script>
import { generateRandomString } from "@/utils/helpers";
import { mapState, mapGetters } from "vuex";
import Pad from "@/components/shared/tools/etherpad/Pad.vue";
import AppDialog from "@/components/ui/AppDialog.vue";

export default {
	name: "DocumentDialog",

	props: {
		dialog: {
			required: true,
		},
	},

	data() {
		return {
			pad_created: false,
		};
	},

	components: {
		Pad,
		AppDialog,
	},

	computed: {
		...mapState({
			document_id: (state) => state.research.document_id,
			jwt: (state) => state.user.jwt,
			current_module: (state) => state.research.current_module,
			current_stage: (state) => state.research.current_stage,
		}),

		...mapGetters({
			getModuleName: "research/getModuleName",
			currentStageName: "research/getCurrentStageName",
		}),

		label() {
			if (this.current_module && this.current_stage) {
				return `${this.$t(this.getModuleName(this.current_module))} > ${this.$t(
					this.currentStageName,
				)}`;
			}

			if (this.$route.meta.header?.title) {
				return this.$route.meta.header.title;
			}

			return "app.my_documents";
		},
	},

	mounted() {
		this.setDocument();
	},

	methods: {
		async setDocument() {
			try {
				this.$loader.start();

				// if document id does not exists in the state then create
				if (!this.document_id) {
					let uuid = generateRandomString();

					this.$store.commit("research/SET_DOCUMENT_ID", {
						document_id: uuid,
					});

					// Create pad of respected id
					await this.$store.dispatch("etherpad/createPad", {
						pad_id: uuid,
						text: "",
					});
				}

				// Set the flag
				this.pad_created = true;
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},
	},
};
</script>
