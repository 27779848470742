<template>
	<v-main>
		<!-- Header -->
		<WebsiteHeader></WebsiteHeader>

		<!-- View -->
		<div :style="{ height: view_port_height }" class="px-1">
			<router-view id="the-view-port" :key="$route.fullPath"></router-view>
		</div>

		<!-- Footer -->
		<WebsiteFooter></WebsiteFooter>
	</v-main>
</template>

<script>
import WebsiteHeader from "@/components/website/Header.vue";
import WebsiteFooter from "@/components/website/Footer.vue";
import { getElHeight } from "@/utils/helpers";

export default {
	name: "WebsiteLayout",

	data() {
		return {
			view_port_height: null,
		};
	},

	components: {
		WebsiteHeader,
		WebsiteFooter,
	},

	created() {
		window.addEventListener("resize", this.calculateHeight);
	},

	mounted() {
		this.calculateHeight();
	},

	methods: {
		calculateHeight() {
			try {
				this.$nextTick(() => {
					let header_height = getElHeight("website-header-wr");
					let footer_height = getElHeight("website-footer-wr");
					this.view_port_height = `calc(100vh - ${header_height}px - ${footer_height}px - 20px)`;
				});
			} catch (error) {
				this.$announce.error(error);
			}
		},
	},
};
</script>

<style scoped>
::-webkit-scrollbar {
	width: 6px !important;
}
</style>
