import { questionnaire as API } from "@/services/api";

// INITIATE STATE
function initialState() {
	return {};
}

// STATE
const state = initialState();

// GETTERS
const getters = {};

// ACTIONS
const actions = {
	async getOfStudent(context, payload) {
		return await API.getOfStudent(payload);
	},
};

// MUTATIONS
const mutations = {
	RESET_STATE: (state) => {
		const s = initialState();
		Object.keys(s).forEach((key) => {
			state[key] = s[key];
		});
	},
};

// Export module
export const mentorQuestionnaire = {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
