<template>
	<v-main>
		<!-- Router view -->
		<v-card flat width="100%" height="100%">
			<router-view :key="$route.fullPath"></router-view>
		</v-card>
	</v-main>
</template>

<script>
export default {
	name: "NoLayout",
};
</script>
