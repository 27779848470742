import { interview as API } from "@/services/api";
import { findElIndex, findFromArray } from "@/utils/helpers";
import Vue from "vue";
import { getField, updateField } from "vuex-map-fields";

// INITIATE STATE
function initialState() {
	return {
		current_tab: "plan-interview",
		list: null,
	};
}

// STATE
const state = initialState();

// GETTERS
const getters = {
	getField,
	getInterview: (state) => (interview_id) => {
		return findFromArray(state.list, "id", interview_id);
	},
};

// ACTIONS
const actions = {
	async list(context) {
		let response = await API.list();
		context.commit("SET_LIST", response);
		return response;
	},

	async create(context, payload) {
		let response = await API.create(payload);
		context.commit("PUSH_INTERVIEW", {
			data: response,
		});
		return response;
	},

	async update(context, payload) {
		let response = await API.update(payload);
		context.commit("UPDATE_INTERVIEW", {
			interview_id: payload.interview_id,
			data: response,
		});
		return response;
	},

	async delete(context, payload) {
		let response = await API.delete(payload);
		context.commit("DELETE_INTERVIEW", {
			interview_id: payload.interview_id,
		});
		return response;
	},

	async listOfStudent(context, payload) {
		return await API.listOfStudent(payload);
	},
};

// MUTATIONS
const mutations = {
	updateField,
	SET_LIST: (state, payload) => {
		state.list = payload;
	},

	PUSH_INTERVIEW: (state, payload) => {
		state.list.push(payload.data);
	},

	UPDATE_INTERVIEW: (state, payload) => {
		//Find index of specific object using findIndex method.
		let objIndex = findElIndex(state.list, "id", payload.interview_id);

		// If index found then update the data at index.
		if (objIndex !== -1) {
			Vue.set(state.list, objIndex, payload.data);
		}
	},

	DELETE_INTERVIEW: (state, payload) => {
		//Find index of specific object using findIndex method.
		let objIndex = findElIndex(state.list, "id", payload.interview_id);

		// If index found then update the data at index.
		if (objIndex !== -1) {
			state.list.splice(objIndex, 1);
		}
	},

	RESET_STATE: (state) => {
		const s = initialState();
		Object.keys(s).forEach((key) => {
			state[key] = s[key];
		});
	},
};

// Export module
export const interview = {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
