export const query = [
	{
		path: "/research/tools/query",
		name: "Query",
		component: () =>
			import(
				/* webpackChunkName:"page.query"*/ "@/pages/users/student/tools/query/Index.vue"
			),
		meta: {
			layout: "AppLayout",
			requiresAuth: true,
			header: {
				title: "app.tools.ai_practice.title",
				subtitle: "app.tools.ai_practice.subtitle",
			},
			footer: {
				component: "Tools",
			},
		},
	},
	{
		path: "/research/tools/query/choose_question",
		name: "ChooseQuestionQuery",
		component: () =>
			import(
				/* webpackChunkName:"page.query_cq"*/ "@/pages/users/student/tools/query/ChooseQuestion.vue"
			),
		meta: {
			layout: "AppLayout",
			requiresAuth: true,
			header: {
				component: "ToolsQuery",
			},
			footer: {
				component: "Query",
			},
		},
	},
	{
		path: "/research/tools/query/mindmap",
		name: "MindmapQuery",
		component: () =>
			import(
				/* webpackChunkName:"page.query_mm"*/ "@/pages/users/student/tools/query/Mindmap.vue"
			),
		meta: {
			layout: "AppLayout",
			requiresAuth: true,
			header: {
				component: "ToolsQuery",
			},
			footer: {
				component: "Query",
			},
		},
	},
	{
		path: "/research/tools/query/toc",
		name: "TOCQuery",
		component: () =>
			import(
				/* webpackChunkName:"page.query_toc"*/ "@/pages/users/student/tools/query/TOC.vue"
			),
		meta: {
			layout: "AppLayout",
			requiresAuth: true,
			header: {
				component: "ToolsQuery",
			},
			footer: {
				component: "Query",
			},
		},
	},
	{
		path: "/research/tools/query/keywords",
		name: "KeywordsQuery",
		component: () =>
			import(
				/* webpackChunkName:"page.query_keywords"*/ "@/pages/users/student/tools/query/Keywords.vue"
			),
		meta: {
			layout: "AppLayout",
			requiresAuth: true,
			header: {
				component: "ToolsQuery",
			},
			footer: {
				component: "Query",
			},
		},
	},
];
