import { getElHeight } from "@/utils/helpers";

export const layoutMixin = {
	data() {
		return {
			view_port_height: null,
			container_classes: "wr_grey_1 pe-0",
		};
	},

	watch: {
		$route(to, from) {
			this.decideViewPortHeight();
		},
	},

	created() {
		this.readyToListenEvents();
	},

	mounted() {
		this.decideViewPortHeight();
	},

	methods: {
		readyToListenEvents() {
			window.addEventListener("resize", () => {
				this.decideViewPortHeight();
			});

			this.$eventBus.$on("calculate-view-port-height", async (payload) => {
				this.decideViewPortHeight(payload);
			});
		},

		decideViewPortHeight(payload = null) {
			try {
				this.$nextTick(() => {
					setTimeout(
						() => {
							let is_header = this.$route.meta.hasOwnProperty("header");
							let is_footer = this.$route.meta.hasOwnProperty("footer");
							let footer_height = 62;
							let header_height = getElHeight("the-header-wr");

							switch (true) {
								case is_header && is_footer:
									this.view_port_height = `calc(100vh - ${header_height}px - ${footer_height}px)`;
									break;
								case is_header:
									this.view_port_height = `calc(100vh - ${header_height}px)`;
									break;
								case is_footer:
									this.view_port_height = `calc(100vh - ${footer_height}px)`;
									break;
								default:
									this.view_port_height = `calc(100vh)`;
									break;
							}
						},
						payload && payload.header_updated ? 200 : 0,
					);
				});
			} catch (error) {
				this.$announce.error(error);
			}
		},
	},
};
