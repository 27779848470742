<template>
	<v-card-text>
		<div v-if="pad_id">
			<div class="pa-2">
				<!-- Speech to Text (To append text in pad) -->
				<div class="d-inline-block pa-1">
					<SpeechToText text="" @speechend="appendText"></SpeechToText>
				</div>

				<!-- Text to speech (To hear what written on pad) -->
				<div class="d-inline-block pa-1" v-if="locale != 'he'">
					<v-tooltip top>
						<template v-slot:activator="{ on, attrs }">
							<v-icon
								v-bind="attrs"
								v-on="on"
								class="cursor-wr"
								@click="openTTSDialog()"
								:class="{ 'vertical-transform-wr': $vuetify.rtl }"
								>$vuetify.icons.values.volume_high</v-icon
							>
						</template>
						<span>{{ $t("app.listen") }}</span>
					</v-tooltip>
					<EtherTextToSpeech
						v-if="tts_dialog"
						:dialog="tts_dialog"
						:text="text"
						@close="(text = null), (tts_dialog = false)"
					></EtherTextToSpeech>
				</div>

				<!-- Translator (To translate some text for pad) -->
				<div class="d-inline-block pa-1">
					<Translator text=""></Translator>
				</div>

				<!-- Add reference -->
				<div v-if="isPadOwner && !isWorkRoute" class="d-inline-block pa-2">
					<AppButton
						label="app.add_reference"
						color="success"
						icon="plus"
						:small="true"
						@click="createNewSource()"
					></AppButton>

					<PopulateReferenceDialog
						v-if="reference_dialog"
						:dialog="reference_dialog"
						:source="source"
						@close="reference_dialog = false"
					></PopulateReferenceDialog>
				</div>
			</div>

			<!-- Pad (In iframe) -->
			<iframe
				v-if="jwt && author.name && author.color"
				:src="`${padURL}?${padQueryParams}`"
				width="100%"
				height="600"
				frameborder="0"
				allowFullScreen
			></iframe>
		</div>

		<v-alert v-else type="error" dense outlined
			>It seems that editor work has not been started by the yet.</v-alert
		>
	</v-card-text>
</template>

<script>
import { wordCount, isLocaleRTL } from "@/utils/helpers";
import SpeechToText from "@/components/plugins/SpeechToText";
import EtherTextToSpeech from "@/components/plugins/EtherTextToSpeech.vue";
import { mapGetters, mapState } from "vuex";
import { etherpadColors } from "@/utils/colors";
import PopulateReferenceDialog from "@/components/research/modules/write/stages/researchPaper/PopulateReferenceDialog.vue";
import moment from "moment";
import Translator from "@/components/plugins/Translator.vue";

export default {
	name: "EtherPadIndex",

	props: {
		pad_id: {
			required: true,
		},
	},

	data() {
		return {
			moment,
			text: null,
			tts_dialog: false,
			show_save: false,
			author: {
				id: null,
				color: null,
				name: null,
			},
			reference_dialog: false,
			source: null,
		};
	},

	components: {
		SpeechToText,
		EtherTextToSpeech,
		PopulateReferenceDialog,
		Translator,
	},

	computed: {
		...mapGetters({
			userProfile: "user/getProfile",
			user: "user/getPersonalDetails",
			locale: "globals/getLocale",
		}),

		...mapState({
			jwt: (state) => state.user.jwt,
		}),

		isWorkRoute() {
			return this.$route.name == "ResearchPaperWork";
		},

		isPadOwner() {
			let pad_owner_id = this.pad_id.substring(0, this.pad_id.lastIndexOf("-"));
			return pad_owner_id == this.userProfile.id;
		},

		padURL() {
			return `${this.$environments.etherpad_base_api_url}/p/${this.pad_id}`;
		},

		padQueryParams() {
			let params = {
				// Token should always be at the first index
				token: this.jwt,
				userName: this.author.name,
				// Do not send '#' in param, send encoded value instead
				userColor: this.author.color.replace("#", "%23"),
				rtl: isLocaleRTL(this.locale),
				lang: this.locale,
			};

			return Object.keys(params)
				.map((key) => {
					return key + "=" + params[key];
				})
				.join("&");
		},
	},

	mounted() {
		this.init();
	},

	methods: {
		async openTTSDialog() {
			try {
				this.$loader.start();

				// Prepare html of pad
				await this.getHTML();

				this.tts_dialog = true;
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		async getHTML() {
			try {
				this.$loader.start();

				let response = await this.$store.dispatch("etherpad/getHTML", {
					pad_id: this.pad_id,
				});

				this.text = response.html;
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		async appendText(payload) {
			try {
				this.$loader.start();

				await this.$store.dispatch("etherpad/appendText", {
					pad_id: this.pad_id,
					text: payload.text,
				});
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		async init() {
			try {
				// Check first if pad does exists or not.
				await this.doesPadExists();

				// Create author for this pad
				await this.createAuthorIfNotExistsFor();
			} catch (error) {
				this.$announce.error(error);
			}
		},

		async doesPadExists() {
			let exists = await this.$store.dispatch("etherpad/doesPadExists", {
				pad_id: this.pad_id,
			});
			if (!exists) {
				throw new Error("Pad does not exist", {
					cause: "werCustom",
				});
			}
		},

		async createAuthorIfNotExistsFor() {
			// Create author if not exist
			var author = await this.$store.dispatch(
				"etherpad/createAuthorIfNotExistsFor",
				{
					pad_id: this.pad_id,
					authorMapper: this.userProfile.id,
					name: this.user.username,
				},
			);

			// Get author
			await this.getAndSetAuthorParams(author);
		},

		async getAndSetAuthorParams(payload) {
			let result = await this.$store.dispatch("etherpad/getAuthor", {
				authorId: payload.authorID,
			});

			this.author.id = payload.authorID;
			this.author.color = etherpadColors[result.colorId];
			this.author.name = result.name;
		},

		async createNewSource() {
			try {
				this.$loader.start();

				let response = await this.$store.dispatch("source/create", {
					review_date: moment().format("YYYY-MM-DD"),
					publication_date: moment().format("YYYY-MM-DD"),
				});

				if (response) {
					this.source = response;

					this.reference_dialog = true;
				}
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},
	},
};
</script>
