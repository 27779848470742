<template>
	<v-dialog :value="dialog" scrollable width="850px" persistent>
		<AppDialog
			:heading="{
				label: 'app.book_searched_by_isbn',
			}"
			:action="{
				label: 'app.import',
				event: 'import',
			}"
			@close="$emit('close')"
			@import="importBook()"
		>
			<template v-slot:dialog-content>
				<v-row no-gutters>
					<v-col sm="3" align="center">
						<v-img
							:src="
								volumeInfo.imageLinks && volumeInfo.imageLinks.thumbnail
									? volumeInfo.imageLinks.thumbnail
									: require(`@/assets/images/error.png`)
							"
							contain
							aspect-ratio="1.1"
						></v-img>
					</v-col>
					<v-col>
						<v-card-text>
							<div class="headline">
								{{ volumeInfo.title }}
							</div>
							<div class="subtitle-1">
								<read-more
									:more-str="$t('app.read_more')"
									:text="volumeInfo.description && '  '"
									:less-str="$t('app.read_less')"
									:max-chars="100"
								></read-more>
							</div>
						</v-card-text>
					</v-col>
				</v-row>

				<!-- Other information -->
				<v-row no-gutters class="mt-3">
					<v-col v-for="(item, index) in bookData" :key="index" sm="4">
						<v-card outlined tile class="">
							<v-card-text class="subtitle-1 black--text">
								<b>{{ $t(item.key) }}</b>
								<div v-html="item.value"></div>
							</v-card-text>
						</v-card>
					</v-col>
				</v-row>

				<!-- Warning of what would happen on import -->
				<v-alert
					type="warning"
					text
					class="mt-10 font-weight-bold"
					border="top"
				>
					{{ $t("app.msg.book_info_override") }}
				</v-alert>
			</template>
		</AppDialog>
	</v-dialog>
</template>

<script>
import AppDialog from "@/components/ui/AppDialog.vue";
import moment from "moment";

export default {
	name: "ISBNResultDialog",

	props: {
		dialog: {
			required: true,
			type: Boolean,
		},
		book: {
			required: true,
		},
	},

	data() {
		return {
			moment,
		};
	},

	components: {
		AppDialog,
	},

	computed: {
		publisher() {
			return this.book.publisher ? this.book.publisher : this.$t("app.unknown");
		},

		volumeInfo() {
			return this.book.volumeInfo;
		},

		bookData() {
			if (!this.volumeInfo) return [];

			return [
				{
					key: "app.authors",
					value: this.volumeInfo.authors
						? this.volumeInfo.authors.toString()
						: [],
				},
				{
					key: "app.publisher",
					value: this.volumeInfo.publisher
						? this.volumeInfo.publisher
						: this.$t("app.unknown"),
				},
				{
					key: "app.published_date",
					value: this.volumeInfo.publishedDate,
				},
				{
					key: "app.language",
					value: this.volumeInfo.language,
				},
				{
					key: "app.preview_link",
					value: `<a :href="${this.volumeInfo.previewLink}" target="__blank">
									${this.$t("app.click_here")}
								</a>`,
				},
				{
					key: "app.page_count",
					value: this.volumeInfo.pageCount,
				},
			];
		},
	},

	methods: {
		importBook() {
			// Prepare an object of the same keys of source object (see in parent component)
			let obj = {};
			obj.title = this.volumeInfo.title;
			obj.description = this.volumeInfo.description;
			obj.author_name = this.volumeInfo.authors
				? this.volumeInfo.authors.toString()
				: "";
			obj.publisher = this.publisher;
			// get date can have any of three (y,m,d) or all three.
			obj.publication_date = moment(
				this.volumeInfo.publishedDate,
				"YYYY-MM-DD",
			).format("YYYY-MM-DD");
			// origin param for URL fields
			obj.origin = this.volumeInfo.previewLink;

			// emit event to save imported book.
			this.$emit("import-book", obj);
		},
	},
};
</script>
