export const questionCreator = [
	{
		path: `/research/tools/question_creator`,
		name: "QuestionCreator",
		component: () =>
			import(
				/* webpackChunkName:"page.question_creator"*/ "@/pages/users/student/tools/questionCreator/Index.vue"
			),
		meta: {
			layout: "AppLayout",
			requiresAuth: true,
			header: {
				title: "app.question_creator",
			},
			footer: {
				component: "Tools",
			},
		},
	},
];
