<template>
	<TheHeaderInterface
		v-if="current_step"
		:title="current_step.header_title"
		:subtitle="subtitle"
	></TheHeaderInterface>
</template>

<script>
import { mapState } from "vuex";
import TheHeaderInterface from "@/components/layout/TheHeaderInterface.vue";

export default {
	name: "ChooseQuestionHeader",

	components: {
		TheHeaderInterface,
	},

	watch: {
		"current_step.value": function(newVal, oldVal) {
			if (newVal !== oldVal) {
				this.$eventBus.$emit("calculate-view-port-height");
			}
		},
	},

	computed: {
		...mapState({
			current_step: (state) => state.process.current_step,
			questionnaire: (state) => state.questionnaire.list,
		}),

		subtitle() {
			// At last step, if questionnaire is approved then show different subtitle.
			if (
				this.current_step.value === 7 &&
				this.questionnaire &&
				this.questionnaire.questionnaire.name ===
					this.$defines.QUESTIONNAIRE.q50 &&
				this.questionnaire.status ===
					this.$defines.QUESTIONNAIRE_STATUS_WAIT_FOR_APPROVAL_STRING
			) {
				return "app.choose_question.step_7.summary_approval.subtitle";
			}
			return this.current_step.header_subtitle;
		},
	},
};
</script>
