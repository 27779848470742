import { whiteboard as API } from "@/services/api";

// INITIATE STATE
function initialState() {
	return {
		current_board_id: null,
	};
}

// STATE
const state = initialState();

// GETTERS
const getters = {};

// ACTIONS
const actions = {
	async list(context, payload) {
		return await API.list(payload);
	},

	async get(context, payload) {
		return await API.get(payload);
	},

	async create(context, payload) {
		return await API.create(payload);
	},

	async update(context, payload) {
		return await API.update(payload);
	},

	async delete(context, payload) {
		return await API.delete(payload);
	},

	async getEssentialParams(context) {
		return await API.getEssentialParams();
	},
};

// MUTATIONS
const mutations = {
	SET_CURRENT_BOARD_ID: (state, payload) => {
		state.current_board_id = payload.board_id;
	},

	RESET_STATE: (state) => {
		const s = initialState();
		Object.keys(s).forEach((key) => {
			state[key] = s[key];
		});
	},
};

// Export module
export const whiteboard = {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
