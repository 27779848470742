import { auth as API } from "@/services/api";
import { DEFINES } from "@/utils/defines";
import { router } from "@/router";

// INITIATE STATE
function initialState() {
	return {
		token: null,
	};
}

// STATE
const state = initialState();

// GETTERS
const getters = {
	isAuthorized: (state) => {
		return state.token ? true : false;
	},
};

// ACTIONS
const actions = {
	async login(context, payload) {
		let response = await API.login(payload);
		context.commit("SET_TOKEN", response);
	},

	async logout(context) {
		let user_lang = context.rootGetters["user/getLanguage"];
		// Reset all module's state which will log out the user.
		await context.dispatch("resetAllState", [], DEFINES.USE_ROOT);
		// Set layout again because state is clear
		context.dispatch(
			"globals/setLayout",
			{
				layout: "WebsiteLayout",
			},
			DEFINES.USE_ROOT,
		);
		// Redirect to home route with language query param
		router.push({
			name: "Home",
			query: {
				lang: user_lang,
			},
		});
	},
};

// MUTATIONS
const mutations = {
	SET_TOKEN: (state, payload) => {
		state.token = payload.token;
	},
	RESET_STATE: (state) => {
		const s = initialState();
		Object.keys(s).forEach((key) => {
			state[key] = s[key];
		});
	},
};

// Export module
export const auth = {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
