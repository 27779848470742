import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

// Import Modules
import { modules } from "./modules";

Vue.use(Vuex);

export const allStates = [
	"globals",
	"category",
	"research",
	"auth",
	"user",
	"questionnaire",
	"agora",
	"mindmap",
	"whiteboard",
	"keyword",
	"source",
	"tag",
	"process",
	"planAndManage",
	"temporary",
	"statement",
	"interview",
	"researchPaper",
	"notification",
	"standardMindmap",
	"wizardMindmap",
	"student",
	"sourceNote",
	"meetingSummary",
	"aws",
	"etherpad",
	"translator",
	"group",
	"toolsResearchPaper",
	"mentorBoard",
	"mentorQuestionnaire",
	"mentorSource",
	"mentorMindmap",
	"query"
];

export const store = new Vuex.Store({
	state: {},
	mutations: {},
	actions: {
		resetAllState(context, exclude = []) {
			// Filter the states which should not be excluded
			let toReset = allStates.filter((state) => {
				return !exclude.includes(state);
			});
			// Now, clear the deservable states
			toReset.forEach((item) => {
				context.commit(`${item}/RESET_STATE`);
			});
		},
	},
	modules,
	plugins: [
		createPersistedState({
			key: "weResearch",
		}),
	],
});
