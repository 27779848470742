import { keyword as API } from "@/services/api";
import {
	findFromArray,
	findElIndex,
	sortArrayOfObjects,
} from "@/utils/helpers";
import { mindMapHelper } from "@/utils/helpers/mindmap";
import { filterArray } from "@/utils/helpers";
import { getField, updateField } from "vuex-map-fields";

// INITIATE STATE
function initialState() {
	return {
		list: [],
		fav_list: [],
		mindmap_keywords: [],
		ai_suggested_keywords: [],
	};
}

// STATE
const state = initialState();

// GETTERS
const getters = {
	getField,
	addedListCount: (state, getters) => {
		return Object.keys(state.list).length;
	},

	isExistsInList: (state) => (keyword_name) => {
		var keyword = findFromArray(state.list, "name", keyword_name);
		return keyword != undefined;
	},

	favCountOfAddedList: (state, getters) => {
		let result = filterArray(state.fav_list, "selected", true, true);
		return result.length;
	},
};

// ACTIONS
const actions = {
	async mindMapKeywords(context, payload) {
		// Get wizard map
		let wizard_map_keywords = await mindMapHelper.getWizardMapFavKeywords(
			false,
		);

		// Get standard map
		let standard_map_keywords = await mindMapHelper.getStandardMapFavKeywords(
			false,
		);

		// Merge both keywords
		let result = [...wizard_map_keywords, ...standard_map_keywords];

		const unique = [
			...new Map(result.map((item) => [item.name, item])).values(),
		];

		context.commit("SET_MINDMAP_KEYWORDS", unique);
	},

	async list(context, payload) {
		let response = await API.list(payload);
		context.commit("SET_LIST", response);
		return response;
	},

	async create(context, payload) {
		let response = await API.create(payload);
		context.commit("PUSH_KEYWORD", response);
		return response;
	},

	async toggleFav(context, payload) {
		let response = await API.toggleFav(payload);
		context.commit("UPDATE_FAV_KEYWORD", response);
		return response;
	},

	async toggleCompleted(context, payload) {
		let response = await API.toggleCompleted(payload);
		return response;
	},

	async favlist(context, payload) {
		let response = await API.favlist(payload);
		context.commit("SET_FAV_LIST", response);
		return response;
	},

	async del(context, payload) {
		let response = await API.del(payload);
		context.commit("REMOVE_KEYWORD", payload);
		return response;
	},

	async getOfStudent(context, payload) {
		return await API.getOfStudent(payload);
	},
};

// MUTATIONS
const mutations = {
	updateField,
	SET_GENERAL_INFO: (state, payload) => {
		state.rsc_general = payload;
	},

	SET_LIST: (state, payload) => {
		state.list = sortArrayOfObjects(payload, "id");
	},

	SET_MINDMAP_KEYWORDS: (state, payload) => {
		state.mindmap_keywords = payload;
	},

	PUSH_KEYWORD: (state, payload) => {
		state.list.push(payload);
	},

	REMOVE_KEYWORD: (state, payload) => {
		//Find index of specific object using findIndex method.
		let objIndex = findElIndex(state.list, "id", payload.id);

		// If index found then update the data at index.
		if (objIndex !== -1) {
			state.list.splice(objIndex, 1);
		}
	},

	UPDATE_FAV_KEYWORD: (state, payload) => {
		// If keyword is marked a fav then push otherwise splice.
		if (payload.selected) {
			state.fav_list.push(payload);
		} else {
			//Find index of specific object using findIndex method.
			let objIndex = findElIndex(state.fav_list, "id", payload.id);

			// If index found then update the data at index.
			if (objIndex !== -1) {
				state.fav_list.splice(objIndex, 1);
			}
		}
	},

	SET_FAV_LIST: (state, payload) => {
		state.fav_list = sortArrayOfObjects(payload, "id");
	},

	RESET_STATE: (state) => {
		const s = initialState();
		Object.keys(s).forEach((key) => {
			state[key] = s[key];
		});
	},
};

// Export module
export const keyword = {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
