import { store } from "@/store";
import { i18n } from "@/plugins/i18n";
import rules from "@/utils/validation";

export const getSourceList = () => {
	return store.state.source.list;
};

export const validateCreateSource = () => {
	let sources = getSourceList();

	const min_create_sources = store.state.process.rsc_general.min_source_list;

	// Check length
	if (sources.length < min_create_sources) {
		throw new Error(
			i18n.t("app.ntfy.err.min_source_required", {
				count: min_create_sources,
			}),
			{
				cause: "werCustom",
			},
		);
	}

	// Check each source has title, description and media is required
	let result = sources.find((item) => {
		return !item.title || !item.description || (!item.attachment && !item.url);
	});
	if (result) {
		throw new Error("app.ntfy.err.source_details_incomplete", {
			cause: "werCustom",
		});
	}
};

export const isSourceUnDocumented = (source) => {
	if (!source.source_type || !source.accessed_by) return true;

	let empty = CITATION_SOURCES[source.source_type][source.accessed_by].filter(
		(attr) => {
			return attr.required && [null, " "].includes(source[attr.model]);
		},
	);
	if (empty && empty.length) return true;
};

export const validateDocumentSource = () => {
	let sources = getSourceList();
	// count undocumented
	let undocumented = sources.filter((item) => {
		return isSourceUnDocumented(item);
	});
	// count documented
	let documented = sources.length - undocumented.length;
	const min_document_sources =
		store.state.process.rsc_general.min_doc_source_list;
	// if documented is less than required, throw error
	if (documented < min_document_sources) {
		throw new Error(
			i18n.t("app.ntfy.err.min_documented_sources", {
				count: min_document_sources,
			}),
			{
				cause: "werCustom",
			},
		);
	}
};

export const validateEvaluateSource = () => {
	let sources = getSourceList();
	const min_evaluate_sources = store.state.process.rsc_general.min_eval_list;
	// count evaluated (by totalling the final score)
	let evaluated = sources.filter((item) => {
		return (
			item.sr_reliability + item.sr_info_quality + item.sr_beneficiality > 0
		);
	});
	// if evaulated is less than required, throw error
	if (evaluated.length < min_evaluate_sources) {
		throw new Error(
			i18n.t("app.ntfy.err.min_evaluated_sources", {
				count: min_evaluate_sources,
			}),
			{
				cause: "werCustom",
			},
		);
	}
};

export const SOURCE_ACCESSED_BY = {
	PRINTED: { text: "app.printed", value: "printed" },
	ONLINE: { text: "app.online", value: "online" },
	E_PAPER: { text: "app.epaper", value: "epaper" },
	OFFLINE: { text: "app.offline", value: "offline" },
};

export const SOURCE_ACCESSED_BY_OPTIONS = {
	AUTHOR: { name: "app.author", model: "author_name", required: true },
	PUBLISHER: {
		name: "app.publisher",
		model: "publisher",
		required: true,
	},
	PUBLICATION_PLACE: {
		name: "app.publication_place",
		model: "publication_place",
		required: true,
	},
	PUBLICATION_DATE: {
		name: "app.publication_date",
		type: "date",
		model: "publication_date",
		required: true,
	},
	REVIEW_DATE: {
		name: "app.review_date",
		type: "date",
		model: "review_date",
		required: true,
	},
	URL: {
		name: "app.url",
		model: "origin",
		required: true,
		extra_rules: [rules.isValidURL],
	},
	JOURNAL_NAME: {
		name: "app.journal_name",
		model: "paper_journal_name",
		required: true,
	},
	FROM_PAGE: {
		name: "app.from_page",
		model: "from_page",
		required: true,
	},
	TO_PAGE: { name: "app.to_page", model: "to_page", required: true },
	VOLUME: { name: "app.volume", model: "volume", required: true },
	ISSUE: { name: "app.issue", model: "issue", required: true },
	NEWSPAPER_NAME: {
		name: "app.newspaper_name",
		model: "paper_journal_name",
		required: true,
	},
	DESIGNER: { name: "app.designer", model: "creator", required: true },
	COMPOSER: { name: "app.composer", model: "creator", required: true },
	RECORDING_PLACE: {
		name: "app.recording_place",
		model: "publication_place",
		required: true,
	},
	RECORDING_DATE: {
		name: "app.recording_date",
		type: "date",
		model: "publication_date",
		required: true,
	},
	PHOTOGRAPHER: {
		name: "app.photographer",
		model: "creator",
		required: true,
	},
	PHOTOGRAPHY_PLACE: {
		name: "app.photography_place",
		model: "publication_place",
		required: true,
	},
	PHOTOGRAPHY_DATE: {
		name: "app.photography_date",
		type: "date",
		model: "publication_date",
		required: true,
	},
	COLLECTION_NAME: {
		name: "app.collection_name",
		model: "collection",
		required: true,
	},
	OBSERVED_DATE: {
		name: "app.observed_date",
		type: "date",
		model: "candidate",
		required: true,
	},
	OBSERVATION_PLACE: {
		name: "app.observation_place",
		model: "candidate",
		required: true,
	},
	OBSERVER: { name: "app.observer", model: "candidate", required: true },
	OBSERVEE: { name: "app.observee", model: "candidate", required: true },
	INTERVIEW_BY: {
		name: "app.interview_by",
		model: "candidate",
		required: true,
	},
	INTERVIEW_PLACE: {
		name: "app.interview_place",
		model: "candidate",
		required: true,
	},
	INTERVIEW_PLATFORM: {
		name: "app.platform",
		model: "platform",
		required: true,
	},
	INTERVIEW_DATE: {
		name: "app.interview_date",
		model: "candidate",
		required: true,
	},
};

export const CITATION_SOURCES = {
	book: {
		accessed_by: [
			SOURCE_ACCESSED_BY.PRINTED,
			SOURCE_ACCESSED_BY.ONLINE,
			SOURCE_ACCESSED_BY.E_PAPER,
		],
		printed: [
			SOURCE_ACCESSED_BY_OPTIONS.AUTHOR,
			SOURCE_ACCESSED_BY_OPTIONS.PUBLISHER,
			SOURCE_ACCESSED_BY_OPTIONS.PUBLICATION_PLACE,
			SOURCE_ACCESSED_BY_OPTIONS.PUBLICATION_DATE,
			SOURCE_ACCESSED_BY_OPTIONS.REVIEW_DATE,
		],
		online: [
			SOURCE_ACCESSED_BY_OPTIONS.AUTHOR,
			SOURCE_ACCESSED_BY_OPTIONS.PUBLISHER,
			SOURCE_ACCESSED_BY_OPTIONS.URL,
			SOURCE_ACCESSED_BY_OPTIONS.PUBLICATION_PLACE,
			SOURCE_ACCESSED_BY_OPTIONS.PUBLICATION_DATE,
			SOURCE_ACCESSED_BY_OPTIONS.REVIEW_DATE,
		],
		epaper: [
			SOURCE_ACCESSED_BY_OPTIONS.AUTHOR,
			SOURCE_ACCESSED_BY_OPTIONS.PUBLISHER,
			SOURCE_ACCESSED_BY_OPTIONS.PUBLICATION_PLACE,
			SOURCE_ACCESSED_BY_OPTIONS.PUBLICATION_DATE,
			SOURCE_ACCESSED_BY_OPTIONS.REVIEW_DATE,
		],
	},
	journal: {
		accessed_by: [SOURCE_ACCESSED_BY.PRINTED, SOURCE_ACCESSED_BY.ONLINE],
		printed: [
			SOURCE_ACCESSED_BY_OPTIONS.AUTHOR,
			SOURCE_ACCESSED_BY_OPTIONS.JOURNAL_NAME,
			SOURCE_ACCESSED_BY_OPTIONS.FROM_PAGE,
			SOURCE_ACCESSED_BY_OPTIONS.TO_PAGE,
			SOURCE_ACCESSED_BY_OPTIONS.VOLUME,
			SOURCE_ACCESSED_BY_OPTIONS.ISSUE,
			SOURCE_ACCESSED_BY_OPTIONS.PUBLICATION_PLACE,
			SOURCE_ACCESSED_BY_OPTIONS.PUBLICATION_DATE,
			SOURCE_ACCESSED_BY_OPTIONS.REVIEW_DATE,
		],
		online: [
			SOURCE_ACCESSED_BY_OPTIONS.AUTHOR,
			SOURCE_ACCESSED_BY_OPTIONS.JOURNAL_NAME,
			SOURCE_ACCESSED_BY_OPTIONS.URL,
			SOURCE_ACCESSED_BY_OPTIONS.FROM_PAGE,
			SOURCE_ACCESSED_BY_OPTIONS.TO_PAGE,
			SOURCE_ACCESSED_BY_OPTIONS.VOLUME,
			SOURCE_ACCESSED_BY_OPTIONS.ISSUE,
			SOURCE_ACCESSED_BY_OPTIONS.PUBLICATION_PLACE,
			SOURCE_ACCESSED_BY_OPTIONS.PUBLICATION_DATE,
			SOURCE_ACCESSED_BY_OPTIONS.REVIEW_DATE,
		],
	},
	newspaper: {
		accessed_by: [SOURCE_ACCESSED_BY.PRINTED, SOURCE_ACCESSED_BY.ONLINE],
		printed: [
			SOURCE_ACCESSED_BY_OPTIONS.AUTHOR,
			SOURCE_ACCESSED_BY_OPTIONS.NEWSPAPER_NAME,
			SOURCE_ACCESSED_BY_OPTIONS.FROM_PAGE,
			SOURCE_ACCESSED_BY_OPTIONS.TO_PAGE,
			SOURCE_ACCESSED_BY_OPTIONS.PUBLICATION_PLACE,
			SOURCE_ACCESSED_BY_OPTIONS.PUBLICATION_DATE,
			SOURCE_ACCESSED_BY_OPTIONS.REVIEW_DATE,
		],
		online: [
			SOURCE_ACCESSED_BY_OPTIONS.AUTHOR,
			SOURCE_ACCESSED_BY_OPTIONS.NEWSPAPER_NAME,
			SOURCE_ACCESSED_BY_OPTIONS.URL,
			SOURCE_ACCESSED_BY_OPTIONS.PUBLICATION_PLACE,
			SOURCE_ACCESSED_BY_OPTIONS.PUBLICATION_DATE,
			SOURCE_ACCESSED_BY_OPTIONS.REVIEW_DATE,
		],
	},
	visual: {
		accessed_by: [SOURCE_ACCESSED_BY.ONLINE],
		online: [
			SOURCE_ACCESSED_BY_OPTIONS.DESIGNER,
			SOURCE_ACCESSED_BY_OPTIONS.URL,
			SOURCE_ACCESSED_BY_OPTIONS.PUBLICATION_PLACE,
			SOURCE_ACCESSED_BY_OPTIONS.PUBLICATION_DATE,
			SOURCE_ACCESSED_BY_OPTIONS.REVIEW_DATE,
		],
	},
	music: {
		accessed_by: [SOURCE_ACCESSED_BY.ONLINE, SOURCE_ACCESSED_BY.OFFLINE],
		online: [
			SOURCE_ACCESSED_BY_OPTIONS.COMPOSER,
			SOURCE_ACCESSED_BY_OPTIONS.URL,
			SOURCE_ACCESSED_BY_OPTIONS.RECORDING_PLACE,
			SOURCE_ACCESSED_BY_OPTIONS.RECORDING_DATE,
			SOURCE_ACCESSED_BY_OPTIONS.REVIEW_DATE,
		],
		offline: [
			SOURCE_ACCESSED_BY_OPTIONS.COMPOSER,
			SOURCE_ACCESSED_BY_OPTIONS.RECORDING_PLACE,
			SOURCE_ACCESSED_BY_OPTIONS.RECORDING_DATE,
			SOURCE_ACCESSED_BY_OPTIONS.REVIEW_DATE,
		],
	},
	photo: {
		accessed_by: [SOURCE_ACCESSED_BY.ONLINE, SOURCE_ACCESSED_BY.OFFLINE],
		online: [
			SOURCE_ACCESSED_BY_OPTIONS.PHOTOGRAPHER,
			SOURCE_ACCESSED_BY_OPTIONS.URL,
			SOURCE_ACCESSED_BY_OPTIONS.PHOTOGRAPHY_PLACE,
			SOURCE_ACCESSED_BY_OPTIONS.PHOTOGRAPHY_DATE,
			SOURCE_ACCESSED_BY_OPTIONS.REVIEW_DATE,
		],
		offline: [
			SOURCE_ACCESSED_BY_OPTIONS.PHOTOGRAPHER,
			SOURCE_ACCESSED_BY_OPTIONS.PHOTOGRAPHY_PLACE,
			SOURCE_ACCESSED_BY_OPTIONS.PHOTOGRAPHY_DATE,
			SOURCE_ACCESSED_BY_OPTIONS.REVIEW_DATE,
		],
	},
	interview: {
		accessed_by: [SOURCE_ACCESSED_BY.ONLINE, SOURCE_ACCESSED_BY.OFFLINE],
		offline: [
			SOURCE_ACCESSED_BY_OPTIONS.INTERVIEW_BY,
			SOURCE_ACCESSED_BY_OPTIONS.INTERVIEW_PLACE,
			SOURCE_ACCESSED_BY_OPTIONS.INTERVIEW_DATE,
			SOURCE_ACCESSED_BY_OPTIONS.REVIEW_DATE,
		],
		online: [
			SOURCE_ACCESSED_BY_OPTIONS.INTERVIEW_BY,
			SOURCE_ACCESSED_BY_OPTIONS.INTERVIEW_PLATFORM,
			SOURCE_ACCESSED_BY_OPTIONS.INTERVIEW_PLACE,
			SOURCE_ACCESSED_BY_OPTIONS.INTERVIEW_DATE,
			SOURCE_ACCESSED_BY_OPTIONS.REVIEW_DATE,
		],
	},
	observation: {
		accessed_by: [SOURCE_ACCESSED_BY.ONLINE, SOURCE_ACCESSED_BY.OFFLINE],
		offline: [
			SOURCE_ACCESSED_BY_OPTIONS.OBSERVEE,
			SOURCE_ACCESSED_BY_OPTIONS.OBSERVER,
			SOURCE_ACCESSED_BY_OPTIONS.OBSERVATION_PLACE,
			SOURCE_ACCESSED_BY_OPTIONS.OBSERVED_DATE,
			SOURCE_ACCESSED_BY_OPTIONS.REVIEW_DATE,
		],
		online: [
			SOURCE_ACCESSED_BY_OPTIONS.OBSERVEE,
			SOURCE_ACCESSED_BY_OPTIONS.OBSERVER,
			SOURCE_ACCESSED_BY_OPTIONS.URL,
			SOURCE_ACCESSED_BY_OPTIONS.OBSERVATION_PLACE,
			SOURCE_ACCESSED_BY_OPTIONS.OBSERVED_DATE,
			SOURCE_ACCESSED_BY_OPTIONS.REVIEW_DATE,
		],
	},
	primary: {
		accessed_by: [SOURCE_ACCESSED_BY.ONLINE, SOURCE_ACCESSED_BY.OFFLINE],
		offline: [
			SOURCE_ACCESSED_BY_OPTIONS.AUTHOR,
			SOURCE_ACCESSED_BY_OPTIONS.COLLECTION_NAME,
			SOURCE_ACCESSED_BY_OPTIONS.PUBLICATION_PLACE,
			SOURCE_ACCESSED_BY_OPTIONS.PUBLICATION_DATE,
			SOURCE_ACCESSED_BY_OPTIONS.REVIEW_DATE,
		],
		online: [
			SOURCE_ACCESSED_BY_OPTIONS.AUTHOR,
			SOURCE_ACCESSED_BY_OPTIONS.URL,
			SOURCE_ACCESSED_BY_OPTIONS.COLLECTION_NAME,
			SOURCE_ACCESSED_BY_OPTIONS.PUBLICATION_PLACE,
			SOURCE_ACCESSED_BY_OPTIONS.PUBLICATION_DATE,
			SOURCE_ACCESSED_BY_OPTIONS.REVIEW_DATE,
		],
	},
};

export const EVALUATION_QUESTIONS = [
	{
		name: "app.main_idea",
		questions: [
			{
				title: "app.source_evaluation.main_idea.question_1.title",
				key: "sr_main_idea_of_source",
				type: "text",
			},
		],
	},
	{
		name: "app.source_review_question",
		questions: [
			{
				title: "app.source_evaluation.source_review_question.question_1.title",
				subtitle:
					"app.source_evaluation.source_review_question.question_1.subtitle",
				key: "sr_contex_period_of_source",
				type: "text",
			},
			{
				title: "app.source_evaluation.source_review_question.question_2.title",
				key: "sr_creation_motivation",
				type: "text",
			},
			{
				title: "app.source_evaluation.source_review_question.question_3.title",
				key: "sr_info_pros_cons",
				type: "text",
			},
		],
	},
	{
		name: "app.source_evaluation.personal_reflection_question",
		questions: [
			{
				title: "app.source_evaluation.personal_question.title",
				subtitle: "app.source_evaluation.personal_question.subtitle",
				key: "sr_points_taken",
				type: "text",
			},
		],
	},
	{
		name: "app.final_score",
		subtitle: "app.source_evaluation.final_score.subtitle",
		questions: [
			{
				title: "app.source_evaluation.final_score.question_1.title",
				key: "sr_reliability",
				type: "number",
			},
			{
				title: "app.source_evaluation.final_score.question_2.title",
				key: "sr_info_quality",
				type: "number",
			},
			{
				title: "app.source_evaluation.final_score.question_3.title",
				key: "sr_beneficiality",
				type: "number",
			},
		],
	},
];
