import { tools } from "./home";
import { meeting } from "./meeting";
import { meetingSummary } from "./meetingSummary";
import { standardMindmap } from "./standard_mind_map";
import { wizardMindmap } from "./wizard_mind_map";
import { editor } from "./editor";

export const toolsRoutes = [
	...tools,
	...meeting,
	...meetingSummary,
	...standardMindmap,
	...wizardMindmap,
	...editor,
];
