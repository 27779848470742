var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"dialog-content-wr rounded-lg",attrs:{"height":_vm.height}},[_c('v-container',[_c('v-card-text',{staticClass:"pa-10"},[_c('v-row',[_c('v-col',{class:[
						'font-weight-bold text-h6',
						_vm.heading && _vm.heading.color
							? ((_vm.heading.color) + "--text")
							: 'black--text' ],attrs:{"sm":"10"}},[_vm._v(" "+_vm._s(_vm.$t(_vm.heading.label))+" ")]),_c('v-col',{staticClass:"text-end"},[_c('v-icon',{attrs:{"color":"black"},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v("$vuetify.icons.values.close")])],1)],1),_c('div',{staticClass:"mt-10"},[_vm._t("dialog-content")],2),(_vm.action)?_c('div',{staticClass:"mt-12"},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
					var hover = ref.hover;
return [(
							!Object(_vm.action).hasOwnProperty('condition') || _vm.action.condition
						)?_c('AppButton',{attrs:{"label":_vm.action.label,"loading":_vm.value,"disabled":_vm.value,"color":hover ? 'amberDarken1' : 'greenAccent2'},on:{"click":function($event){(_vm.loader = _vm.value), _vm.$emit(_vm.action.event)}}}):_vm._e()]}}],null,false,461238101)}),(_vm.show_cancel)?_c('AppButton',{class:['mx-5'],attrs:{"label":"app.cancel","color":"greyLighten2"},on:{"click":function($event){return _vm.$emit('close')}}}):_vm._e()],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }