const path_prefix = "/research/tools/knowledge_organizers/civic_studies";

export const civicStudies = [
	{
		path: `${path_prefix}/civic_studies/index`,
		name: "CivicStudies",
		component: () =>
			import(
				/* webpackChunkName:"page.ko.civic-studies"*/ "@/pages/users/student/tools/knowledgeOrganizers/civicStudies/Index.vue"
			),
		meta: {
			layout: "AppLayout",
			requiresAuth: true,
			header: {
				title: "app.ko.civic_studies.title",
				subtitle: "",
			},
			footer: {
				component: "ToolsKnowledgeOrganizers",
			},
		},
	},
	{
		path: `${path_prefix}/analyzing_primary_source`,
		name: "CivicAnalyzingPrimarySource",
		component: () =>
			import(
				/* webpackChunkName:"page.ko.civic.primary_source"*/ "@/pages/users/student/tools/knowledgeOrganizers/civicStudies/analyzingPrimarySources/Index.vue"
			),
		meta: {
			layout: "AppLayout",
			requiresAuth: true,
			header: {
				title: "app.ko.civic_studies.analyzing_primary_sources.header.title",
				subtitle: "",
			},
			footer: {
				component: "ToolsKnowledgeOrganizers",
			},
		},
	},
];
