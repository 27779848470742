export const practicePresentation = [
	{
		path: "/research/practice_presentation",
		name: "PracticePresentation",
		component: () =>
			import(
				/* webpackChunkName:"page.pr-pr"*/ "@/pages/research/modules/present/stages/Practice.vue"
			),
		meta: {
			layout: "AppLayout",
			requiresAuth: true,
			header: {
				title: "app.practice_presentation.title",
			},
			footer: {
				component: "Buttons",
				data: {
					rightBtn: {
						title: "app.next",
						event_name: "finish-practice-presentation",
					},
				},
			},
		},
	},
];
