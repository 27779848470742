export const group = [
	{
		path: "/research/admin/groups",
		name: "AdminGroups",
		component: () =>
			import(
				/* webpackChunkName:"page.a-groups"*/ "@/pages/users/admin/group/Index.vue"
			),
		meta: {
			layout: "AppLayout",
			requiresAuth: true,
			header: {
				title: "app.groups.title",
			},
		},
	},
];
