// Basic routes like home page, common dashboard etc.
import { basicRoutes } from "./basic";

// Agora Routes
import { agoraRoutes } from "./agora";

// Research modules routes
import { researchModulesRoutes } from "./research/modules";

// User's routes
import { userRoutes } from "./users";

// Dynamic Routes
import { dynamicRoutes } from "./dynamic";

export const routes = [
	...basicRoutes,
	...agoraRoutes,
	...researchModulesRoutes,
	...userRoutes,
	...dynamicRoutes,
];
