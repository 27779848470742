const path_prefix = "/research/tools/knowledge_organizers";

export const knowledgeOrganizers = [
	{
		path: `${path_prefix}/index`,
		name: "KnowledgeOrganizers",
		component: () =>
			import(
				/* webpackChunkName:"page.knowledge-organizer"*/ "@/pages/users/student/tools/knowledgeOrganizers/Index.vue"
			),
		meta: {
			layout: "AppLayout",
			requiresAuth: true,
			header: {
				title: "app.ko.title",
				subtitle: "app.ko.subtitle",
			},
			footer: {
				component: "Tools",
			},
		},
	},
	{
		path: `${path_prefix}/positive_and_negative`,
		name: "PositiveNegative",
		component: () =>
			import(
				/* webpackChunkName:"page.pos-neg"*/
				"@/pages/users/student/tools/knowledgeOrganizers/positiveNegative/List"
			),
		meta: {
			layout: "AppLayout",
			requiresAuth: true,
			header: {
				title: "app.ko.positive_negative.header.title",
				subtitle: "app.ko.positive_negative.subtitle",
			},
			footer: {
				component: "KnowledgeOrganizers",
			},
		},
	},
	{
		path: `${path_prefix}/positive_and_negative/statement/:id`,
		name: "PositiveNegativeStatement",
		component: () =>
			import(
				/* webpackChunkName:"page.pos-neg-explore"*/
				"@/pages/users/student/tools/knowledgeOrganizers/positiveNegative/Index"
			),
		meta: {
			layout: "AppLayout",
			requiresAuth: true,
			header: {
				title: "app.ko.positive_negative.header.title",
				subtitle: "app.ko.positive_negative.subtitle",
			},
			footer: {
				component: "KnowledgeOrganizers",
			},
		},
	},
	{
		path: `${path_prefix}/analyzing_events`,
		name: "AnalyzingEvents",
		component: () =>
			import(
				/* webpackChunkName:"page.analyze-events"*/ "@/pages/users/student/tools/knowledgeOrganizers/AnalyzingEvents"
			),
		meta: {
			layout: "AppLayout",
			requiresAuth: true,
			header: {
				title: "app.ko.analyzing_historical_event.header.title",
			},
			footer: {
				component: "KnowledgeOrganizers",
			},
		},
	},
	{
		path: `${path_prefix}/decisions_and_considerations`,
		name: "Decisions",
		component: () =>
			import(
				/* webpackChunkName:"page.decisions"*/ "@/pages/users/student/tools/knowledgeOrganizers/Decisions"
			),
		meta: {
			layout: "AppLayout",
			requiresAuth: true,
			header: {
				title: "app.ko.decision_consideration.header.title",
			},
			footer: {
				component: "KnowledgeOrganizers",
			},
		},
	},
	{
		path: `${path_prefix}/analyzing_famous_person`,
		name: "AnalyzingPerson",
		component: () =>
			import(
				/* webpackChunkName:"page.famous-person"*/ "@/pages/users/student/tools/knowledgeOrganizers/AnalyzingPerson"
			),
		meta: {
			layout: "AppLayout",
			requiresAuth: true,
			header: {
				title: "app.ko.analyzing_famous_person.header.title",
			},
			footer: {
				component: "KnowledgeOrganizers",
			},
		},
	},
	{
		path: `${path_prefix}/point_of_views_and_beliefs`,
		name: "Beliefs",
		component: () =>
			import(
				/* webpackChunkName:"page.beliefs"*/ "@/pages/users/student/tools/knowledgeOrganizers/Beliefs"
			),
		meta: {
			layout: "AppLayout",
			requiresAuth: true,
			header: {
				title: "app.ko.point_of_view_and_beliefs.header.title",
			},
			footer: {
				component: "KnowledgeOrganizers",
			},
		},
	},
	{
		path: `${path_prefix}/analyzing_phenomenon`,
		name: "AnalyzingPhenomenon",
		component: () =>
			import(
				/* webpackChunkName:"page.phenomenon"*/ "@/pages/users/student/tools/knowledgeOrganizers/AnalyzingPhenomenon"
			),
		meta: {
			layout: "AppLayout",
			requiresAuth: true,
			header: {
				title: "app.ko.analyzing_scientific_phenomenon.header.title",
			},
			footer: {
				component: "KnowledgeOrganizers",
			},
		},
	},
];
