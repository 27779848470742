import { callApi } from "./manager";

export const questionnaire = {
	list: (payload) => {
		// FIXME:: Make it generalised
		if (payload.query_params) {
			return callApi(
				`/user/questionnaire/${payload.questionnaire_name}?user=${payload.query_params.user_id}`,
				"get",
			);
		}
		return callApi(`/user/questionnaire/${payload.questionnaire_name}`, "get");
	},

	submitAnswer: (payload) => {
		return callApi(
			`/user/question/${payload.questionnaire_id}/`,
			"put",
			payload,
		);
	},

	submit: (payload) => {
		return callApi(
			`/user/questionnaire/${payload.questionnaire_id}/`,
			"put",
			payload,
		);
	},

	getSubQuestionnaires: (payload) => {
		return callApi(
			`/user/questionnaire/?qs=${payload.questionnaire_name}&min_count=1`,
			"get",
		);
	},

	addQuestionnaire: (payload) => {
		return callApi(`/user/questionnaire/`, "post", payload);
	},

	removeQuestionnaire: (payload) => {
		return callApi(
			`/user/questionnaire/${payload.questionnaire_id}/`,
			"delete",
		);
	},

	getOfStudent: (payload) => {
		return callApi(
			`/user/questionnaire/${payload.questionnaire_name}?user=${payload.user_id}`,
			"get",
		);
	},
};
