<template>
	<v-row no-gutters>
		<v-col>
			<AppButton
				v-if="leftBtn.show"
				:label="leftBtn.title"
				:left_icon="true"
				:icon="leftBtn.icon || null"
				:disabled="leftBtn.disabled"
				@click="redirectBack()"
			></AppButton>
		</v-col>
	</v-row>
</template>

<script>
export default {
	name: "KnowledgeOrganizersFooter",

	computed: {
		leftBtn() {
			let btnObj = {
				title: "app.back",
				disabled: false,
				show: true,
				icon: this.$vuetify.rtl ? "right_chevron" : "left_chevron",
			};
			return btnObj;
		},
	},

	methods: {
		redirectBack() {
			try {
				this.$loader.start();

				let route;
				switch (this.$route.name) {
					case "Genres":
						route = "Literature";
						break;
					case "CivicAnalyzingPrimarySource":
						route = "CivicStudies";
						break;
					case "AnalyzingPrimarySources":
						route = "History";
						break;
					default:
						route = "KnowledgeOrganizers";
						break;
				}

				this.$router.push({
					name: route,
				});
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},
	},
};
</script>
