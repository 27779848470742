const path_prefix = "/research";

export const chooseTopic = [
	{
		path: `${path_prefix}/choose_topic`,
		name: "ChooseTopic",
		component: () =>
			import(
				/* webpackChunkName:"page.dq-cto"*/ "@/pages/research/modules/defineQuestion/stages/chooseTopic/Index"
			),
		meta: {
			layout: "AppLayout",
			requiresAuth: true,
			header: {
				title: "app.choose_topic.title",
				subtitle: "app.choose_topic.subtitle",
			},
		},
	},
	{
		path: `${path_prefix}/choose_topic_wizard_mindmap`,
		name: "ChooseTopicWizardMindmap",
		component: () =>
			import(
				/* webpackChunkName:"page.dq-cto-wmm"*/ "@/pages/research/modules/defineQuestion/stages/chooseTopic/alternatives/WizardMindmap.vue"
			),
		meta: {
			layout: "AppLayout",
			requiresAuth: true,
			header: {
				component: "Mindmap",
			},
			footer: {
				component: "Mindmap",
			},
		},
	},
	{
		path: `${path_prefix}/choose_topic_standard_mindmap`,
		name: "ChooseTopicStandardMindmap",
		component: () =>
			import(
				/* webpackChunkName:"page.dq-cto-smm"*/ "@/pages/research/modules/defineQuestion/stages/chooseTopic/alternatives/StandardMindmap.vue"
			),
		meta: {
			layout: "AppLayout",
			requiresAuth: true,
			header: {
				component: "Mindmap",
			},
			footer: {
				component: "Mindmap",
			},
		},
	},
	{
		path: `${path_prefix}/choose_topic_table_chart`,
		name: "ChooseTopicTableChart",
		component: () =>
			import(
				/* webpackChunkName:"page.dq-cto-tc"*/ "@/pages/research/modules/defineQuestion/stages/chooseTopic/alternatives/TableChart.vue"
			),
		meta: {
			layout: "AppLayout",
			requiresAuth: true,
			header: {
				component: "Mindmap",
			},
			footer: {
				component: "Mindmap",
			},
		},
	},
	{
		path: `${path_prefix}/choose_topic_prioritization_tool`,
		name: "ChooseTopicPrioritizationTool",
		component: () =>
			import(
				/* webpackChunkName:"page.dq-cto-pt"*/ "@/pages/research/modules/defineQuestion/stages/chooseTopic/alternatives/PrioritizationTool"
			),
		meta: {
			layout: "AppLayout",
			requiresAuth: true,
			header: {
				component: "PrioritizationTool",
			},
			footer: {
				component: "PrioritizationTool",
			},
		},
	},
	{
		path: `${path_prefix}/choose_topic_summary`,
		name: "ChooseTopicSummary",
		component: () =>
			import(
				/* webpackChunkName:"page.dq-cto-s"*/ "@/pages/research/modules/defineQuestion/stages/chooseTopic/alternatives/Summary"
			),
		meta: {
			layout: "AppLayout",
			requiresAuth: true,
			header: {
				title: "app.choose_topic.summary.route.title",
				subtitle: "app.choose_topic.summary.subtitle",
			},
			footer: {
				component: "Questionnaire",
			},
		},
	},
];
