export const settings = [
	{
		path: "/research/mentor/settings",
		name: "MentorSettings",
		component: () =>
			import(
				/* webpackChunkName:"page.m-settings"*/ "@/pages/users/mentor/settings/Index.vue"
			),
		meta: {
			layout: "MentorLayout",
			requiresAuth: true,
		},
	},
];
