import { user as API } from "@/services/api";
import { routesAuthority, researchRoutes } from "@/router/authority";
import { sortArrayOfObjects, isArrayHavingItem } from "@/utils/helpers";
import { DEFINES } from "@/utils/defines";
import { findFromArray } from "@/utils/helpers";
import Vue from "vue";
import { filters } from "@/utils/filters";
import { i18n } from "@/plugins/i18n";

// INITIATE STATE
function initialState() {
	return {
		user: {},
		jwt: null,
		projects: null,
	};
}

// STATE
const state = initialState();

// GETTERS
const getters = {
	getProfile: (state) => {
		return state.user;
	},

	getLanguage: (state) => {
		return state.user.user_lang;
	},

	getFirstName: (state) => (input_user = state.user.user) => {
		let first_name = input_user.first_name;
		return first_name ? filters.capitalize(first_name) : null;
	},

	getLastName: (state) => (input_user = state.user.user) => {
		let last_name = input_user.last_name;
		return last_name ? filters.capitalize(last_name) : null;
	},

	fullName: (state, getters) => (input_user = state.user.user) => {
		let first_name = getters.getFirstName(input_user);
		let last_name = getters.getLastName(input_user);

		// If both names are present
		if (first_name && last_name) {
			return first_name + " " + last_name;
		}
		// If both names are not present
		if (!first_name && !last_name) {
			return filters.capitalize(input_user.username);
		}
		// If only first name is present
		if (first_name && !last_name) {
			return first_name;
		}
	},

	getPersonalDetails: (state) => {
		return state.user.user;
	},

	isAdmin: (state) => {
		return state.user.user_type === DEFINES.USER_ROLES.SYS_ADMIN;
	},

	isMentor: (state) => {
		return state.user.user_type === DEFINES.USER_ROLES.MENTOR;
	},

	hasMentor: (state) => {
		return state.user.parent_user;
	},

	isStudent: (state) => {
		return state.user.user_type === DEFINES.USER_ROLES.STUDENT;
	},

	isStageAvailable: (state, getters) => (input_step_position) => {
		if (getters.isAdmin) return true;

		let available_steps_positions = state.user.available_steps.map(
			(item) => item.plan_step.position,
		);
		return isArrayHavingItem(available_steps_positions, input_step_position);
	},

	getLatestAvailableStage: (state, getters, rootState, rootGetters) => (
		input_steps = null,
	) => {
		let available_steps = input_steps
			? input_steps
			: getters.getProfile.available_steps;
		if (!available_steps || !available_steps.length) return null;

		let res = findFromArray(
			rootGetters["research/flatModules"],
			"value",
			available_steps[available_steps.length - 1].plan_step.position,
		);

		return res;
	},

	isRouteAllowed: (state, getters, rootState, rootGetters) => (input_route) => {
		let allowedRoutes = null;

		// Prepare allowed routes for each entity
		if (getters.isAdmin) {
			allowedRoutes = routesAuthority.adminReachableRoutes;
		}
		if (getters.isMentor) {
			allowedRoutes = routesAuthority.mentorReachableRoutes;
		}
		if (getters.isStudent) {
			allowedRoutes = routesAuthority.studentReachableRoutes;
		}

		// If no allowed routes than return false
		if (!allowedRoutes) return false;

		// If route get matched then return true
		if (isArrayHavingItem(allowedRoutes, input_route)) return true;

		// If not matched but user is student then do further checking
		if (getters.isStudent) {
			// If child routes of choose theme then consider parent route
			if (
				isArrayHavingItem(
					[
						researchRoutes.chooseTheme,
						researchRoutes.chooseThemeDatabase,
						researchRoutes.chooseThemeSummary,
					],
					input_route,
				)
			) {
				input_route = researchRoutes.chooseTheme;
			}
			// If child routes of choose topic then consider parent route
			if (
				isArrayHavingItem(
					[
						researchRoutes.chooseTopic,
						researchRoutes.chooseTopicStandardMindmap,
						researchRoutes.chooseTopicWizardMindmap,
						researchRoutes.chooseTopicTableChart,
						researchRoutes.chooseTopicPrioritizationTool,
						researchRoutes.chooseTopicSummary,
					],
					input_route,
				)
			) {
				input_route = researchRoutes.chooseTopic;
			}

			// Find the position of that step which owns the input route.
			let result = findFromArray(
				rootGetters["research/flatModules"],
				"route",
				input_route,
			);
			if (!result) return false;

			// Now check, if input route's position is available or not.
			return getters.isStageAvailable(result.value);
		}

		return false;
	},
};

// ACTIONS
const actions = {
	async get(context) {
		let response = await API.get();
		context.commit("SET_USER", response);
	},

	async updateProfile(context, payload) {
		let response = await API.updateProfile(payload);
		context.commit("SET_USER", response);
	},

	async sendToMentor(context, payload) {
		let response = await API.sendToMentor(payload);
		return response;
	},

	async updateCompletedStep(context, payload) {
		await API.updateCompletedStep(payload);
	},

	async resetPassword(context, payload) {
		await API.resetPassword(payload);
	},

	async createJWT(context, payload) {
		let response = await API.createJWT(payload);
		context.commit("SET_TOKEN", response);
	},

	async listProjects(context) {
		let response = await API.listProjects();
		context.commit("SET_PROJECTS", response);
		return response;
	},

	async createProfile(context, payload) {
		return await API.createProfile(payload);
	},

	async getStudents(context) {
		return await API.getStudents();
	},
};

// MUTATIONS
const mutations = {
	SET_USER: (state, payload) => {
		payload.available_steps = sortArrayOfObjects(
			payload.available_steps,
			"position",
		);
		state.user = payload;
	},

	UPDATE_LOCALE: (state, payload) => {
		Vue.set(state.user, "user_lang", payload.lang ?? i18n.locale);
	},

	SET_TOKEN: (state, payload) => {
		state.jwt = payload.key;
	},

	SET_PROJECTS: (state, payload) => {
		state.projects = sortArrayOfObjects(payload, "default_profile", true);
	},

	RESET_STATE: (state) => {
		const s = initialState();
		Object.keys(s).forEach((key) => {
			// Do not reset 'user' key to keep i18n working
			if (key != "user") {
				state[key] = s[key];
			}
		});
	},
};

// Export module
export const user = {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
