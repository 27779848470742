import { callApi } from "./manager";

export const source = {
	list: () => {
		return callApi(`/rsc/user_source`, "get");
	},

	get: (payload) => {
		return callApi(`/rsc/user_source/${payload.source_id}`, "get");
	},

	create: (payload) => {
		return callApi(`/rsc/user_source/`, "post", payload);
	},

	update: (payload) => {
		return callApi(`/rsc/user_source/${payload.id}/`, "put", payload);
	},

	del: (payload) => {
		return callApi(`/rsc/user_source/${payload.id}/`, "delete");
	},

	getOfStudent: (payload) => {
		return callApi(`/rsc/user_source?user_id=${payload.student_id}`, "get");
	},
};
