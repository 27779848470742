import { callApi } from "./manager";

export const planAndManage = {
	getStartDate: () => {
		return callApi(`/plan/user_project`, "get");
	},

	setStartDate: (payload) => {
		return callApi(`/plan/user_project/${payload.id}/`, "put", payload);
	},

	getUserAvail: () => {
		return callApi(`/plan/user_avail`, "get");
	},

	createUserAvail: (payload) => {
		return callApi(`/plan/user_avail/`, "post", payload);
	},

	updateUserAvail: (payload) => {
		return callApi(`/plan/user_avail/${payload.id}/`, "put", payload);
	},

	deleteUserAvail: (payload) => {
		return callApi(`/plan/user_avail/${payload.id}/`, "delete");
	},

	calculateResearchPlan: () => {
		return callApi(`/plan/calculate_plan`, "get");
	},

	getUserPlan: (payload) => {
		return callApi(
			payload
				? `/plan/user_plan?user_id=${payload.user_id}`
				: `/plan/user_plan`,
			"get",
		);
	},

	createUserPlan: (payload) => {
		return callApi(`/plan/user_plan/`, "post", payload);
	},

	updateUserPlan: (payload) => {
		return callApi(`/plan/user_plan/${payload.id}/`, "put", payload);
	},

	delUserPlan: (payload) => {
		return callApi(`/plan/user_plan/${payload.id}/`, "delete");
	},

	getOfStudent: (payload) => {
		return callApi(`/plan/user_plan?user_id=${payload.user_id}`, "get");
	},

	getPlanSteps: (payload) => {
		return callApi(`/plan/plan_steps?plan=${payload.plan_id}`, "get");
	},
};
