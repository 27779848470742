export const planSearchStrategy = [
	{
		path: "/researc/plan_search_strategy",
		name: "PlanSearchStrategy",
		component: () =>
			import(
				/* webpackChunkName:"page.gs-pss"*/ "@/pages/research/modules/gatherSources/stages/PlanSearchStrategy.vue"
			),
		meta: {
			layout: "AppLayout",
			requiresAuth: true,
			header: {
				component: "PlanSearchStrategy",
			},
			footer: {
				component: "PlanSearchStrategy",
				data: null,
			},
		},
	},
];
