<template>
	<TheHeaderInterface
		v-if="moduleNumber"
		:title="title"
		:subtitle="subtitle"
	></TheHeaderInterface>
</template>

<script>
import { mapState } from "vuex";
import TheHeaderInterface from "@/components/layout/TheHeaderInterface.vue";

export default {
	name: "SummaryQuestionnaireHeader",

	components: {
		TheHeaderInterface,
	},

	watch: {
		"questionnaire.status": function(newVal, oldVal) {
			if (newVal !== oldVal) {
				this.$eventBus.$emit("calculate-view-port-height");
			}
		},
	},

	computed: {
		...mapState({
			current_stage: (state) => state.research.current_stage,
			questionnaire: (state) => state.questionnaire.list,
		}),

		moduleNumber() {
			return Number(this.current_stage.toString()[0]);
		},

		title() {
			switch (this.moduleNumber) {
				case 2:
					return "app.define_question.summary.header.title";
				case 3:
					return "app.gather_sources.summary.header.title";
			}
		},

		subtitle() {
			switch (this.moduleNumber) {
				case 2:
					return "app.define_question.summary.subtitle";
				case 3:
					if (
						this.questionnaire &&
						this.questionnaire.status ===
							this.$defines.QUESTIONNAIRE_STATUS_DRAFT_STRING
					) {
						return "app.gather_sources.summary.subtitle";
					}
					return "app.gather_sources.summary.approved.subtitle";
			}
		},
	},
};
</script>
