import { presentResearchPlan } from "./stages/confirm_timeline";
import { preparePresentation } from "./stages/prepare";
import { practicePresentation } from "./stages/practice";
import { schedulePresentation } from "./stages/schedule";
import { presentResearchSummary } from "./stages/summary";

export const presentResearchRoutes = [
	...presentResearchPlan,
	...preparePresentation,
	...practicePresentation,
	...schedulePresentation,
	...presentResearchSummary,
];
