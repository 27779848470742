export const CUSTOM_ICONS = {
	language: "mdi-web",
	plus: "mdi-plus",
	minus: "mdi-minus",
	eye: "mdi-eye",
	eye_off: "mdi-eye-off",
	heart: "mdi-heart",
	info_circle_filled: "mdi-help-circle",
	phone: "mdi-phone",
	left_chevron: "mdi-chevron-left",
	right_chevron: "mdi-chevron-right",
	down_chevron: "mdi-chevron-down",
	check: "mdi-check",
	edit: "mdi-pencil",
	cancel: "mdi-close-circle",
	check_circle: "mdi-checkbox-marked-circle",
	group: "mdi-account-supervisor",
	start_recording: "mdi-checkbox-blank-circle",
	stop_recording: "mdi-card",
	share_screen: "mdi-monitor-multiple",
	stop_screen_sharing: "mdi-cellphone-link-off",
	hang_phone: "mdi-phone-hangup",
	delete: "mdi-delete",
	save: "mdi-content-save",
	heart_outline: "mdi-heart-outline",
	bell: "mdi-bell",
	tag_outline: "mdi-tag-outline",
	import: "mdi-download",
	arrow_up: "mdi-menu-up",
	pause: "mdi-pause",
	play: "mdi-play",
	check_outline: "mdi-checkbox-marked-outline",
	upload: "mdi-upload",
	link: "mdi-link-variant",
	tag: "mdi-tag",
	attachment: "mdi-attachment",
	info: "mdi-information",
	cancel_basic: "mdi-close",
	text_box: "mdi-format-text",
	rectangle_outline: "mdi-checkbox-blank-outline",
	top_right_arrow: "mdi-arrow-top-right",
	rectangle_filled: "mdi-checkbox-blank",
	size: "mdi-format-size",
	bold: "mdi-format-bold",
	italic: "mdi-format-italic",
	refresh: "mdi-refresh",
	user: "mdi-account",
	copy: "mdi-content-copy",
	circle_outline: "mdi-checkbox-blank-circle-outline",
	clock: "mdi-clock",
	calendar: "mdi-calendar",
	arrow_down: "mdi-arrow-down",
	full_screen: "mdi-fullscreen",
	exit_full_screen: "mdi-fullscreen-exit",
	guest: "mdi-human",
	switch_on: "mdi-toggle-switch",
	switch_off: "mdi-toggle-switch-off",
	email: "mdi-email-outline",
	printer: "mdi-printer",
	check_marked: "mdi-checkbox-marked",
	lock: "mdi-lock",
	logout: "mdi-logout",
	briefcase: "mdi-briefcase",
	timer: "mdi-timer",
	settings: "mdi-image-filter-vintage",
	database: "mdi-database",
	menu_down: "mdi-menu-down",
	menu_right: "mdi-menu-right",
	menu_left: "mdi-menu-left",
	mic: "mdi-microphone",
	broom: "mdi-broom",
	download: " mdi-download",
	up_chevron: "mdi-chevron-up",
	document: "mdi-file-document",
	clipboard_text: "mdi-clipboard-text",
	clipboard_check: "mdi-clipboard-check",
	notes_open: "mdi-format-quote-open",
	right_arrow: "mdi-arrow-right",
	left_arrow: "mdi-arrow-left",
	power: "mdi-power",
	send: "mdi-send",
	title: "mdi-format-title",
	message: "mdi-message",
	straight_arrow_up: "mdi-arrow-up",
	menu_horizontal: "mdi-dots-horizontal",
	mic_off: "mdi-microphone-off",
	share: "mdi-share-variant",
	account_check: "mdi-account-check",
	account_off: "mdi-account-off",
	volume_high: "mdi-volume-high",
	trophy: "mdi-trophy",
	sad: "mdi-emoticon-sad",
	flag: "mdi-flag",
	right_chevron_double: "mdi-chevron-double-right",
	left_chevron_double: "mdi-chevron-double-left",
	translate: "mdi-translate",
	pdf: "mdi-file-pdf",
	word: "mdi-file-word",
	bulb_on: "mdi-lightbulb-on",
	account_multiple_plus: "mdi-account-multiple-plus",
	accessibility: "mdi-wheelchair-accessibility",
	color_fill: "mdi-format-color-fill",
	color_text: "mdi-format-color-text",
	border_outside: "mdi-border-outside",
	border_none: "mdi-border-none",
	image: "mdi-image",
	video: "mdi-video",
	alert: "mdi-alert",
	lock_outline: "mdi-lock-outline",
	earth: "mdi-earth",
	accessibility: "mdi-wheelchair-accessibility",
	school: "mdi-school",
	google_circle_extended: "mdi-google-circles-extended",
	emoji: "mdi-sticker-emoji",
	airplane_landing: "mdi-airplane-landing",
	marker_check: "mdi-marker-check",
	arrow_all: "mdi-arrow-all",
	content_paste: "mdi-content-paste",
	human_greeting: "mdi-human-greeting",
	sync: "mdi-sync",
	link_variant: "mdi-link-variant",
	apps: "mdi-apps",
	cursor_outline: "mdi-cursor-default-outline",
	start_arrow: "mdi-ray-start-arrow",
	end_arrow: "mdi-ray-end-arrow",
	update: "mdi-update",
};
