const path_prefix = "/research/tools/knowledge_organizers/science";

export const science = [
	{
		path: `${path_prefix}/index`,
		name: "Science",
		component: () =>
			import(
				/* webpackChunkName:"page.ko.science"*/ "@/pages/users/student/tools/knowledgeOrganizers/science/Index.vue"
			),
		meta: {
			layout: "AppLayout",
			requiresAuth: true,
			header: {
				title: "app.ko.science.title",
				subtitle: "",
			},
			footer: {
				component: "ToolsKnowledgeOrganizers",
			},
		},
	},
];
