<template>
	<v-footer
		fixed
		bottom
		color="wr_indigo_2"
		id="website-footer-wr"
		class="justify-center white--text"
	>
		<v-row>
			<v-col>{{ $t("app.home.copyright") }}</v-col>
			<v-col align="center">
				{{ $t("app.home.curious_to_know") }}
				info@SkillsX.ai
			</v-col>
			<v-col
				v-if="$route.name != 'Accessibility'"
				align="end"
				@click="redirectTo()"
				class="cursor-wr"
			>
				{{ $t("app.home.accessibility") }}
			</v-col>
		</v-row>
	</v-footer>
</template>

<script>
export default {
	name: "WebsiteFooter",

	methods: {
		redirectTo() {
			this.$router.push({
				name: "Accessibility",
			});
		},
	},
};
</script>

<style></style>
