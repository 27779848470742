<template>
	<v-row v-if="current_step" no-gutters>
		<!-- Left button -->
		<v-col>
			<AppButton
				v-if="leftBtn.show"
				:label="leftBtn.title"
				:disabled="leftBtn.disabled"
				:icon="leftBtn.icon || null"
				:left_icon="true"
				@click="updateCurrentStep(0)"
			></AppButton>
		</v-col>

		<!-- Step Progress -->
		<v-col align="center">
			<AppStepsProgress
				v-model="progress_step"
				:progress_step="progress_step"
				:steps="steps"
			></AppStepsProgress>
		</v-col>

		<!-- Right Button -->
		<v-col class="text-end">
			<AppButton
				v-if="rightBtn.show"
				:label="rightBtn.title"
				:disabled="rightBtn.disabled"
				:icon="rightBtn.icon || null"
				@click="$eventBus.$emit(current_step.next_event)"
			></AppButton>
		</v-col>
	</v-row>
</template>

<script>
import { mapFields } from "vuex-map-fields";
import { interactionNetworkingSteps } from "@/utils/steps";
import { footerStepsMixin } from "@/mixins/footerStepsMixin";

export default {
	name: "InteractionAndNetworkingFooter",

	mixins: [footerStepsMixin],

	mounted() {
		this.updateCurrentStep(0);
	},

	computed: {
		...mapFields("temporary", {
			current_step: "intr_netw_current_step",
		}),

		steps() {
			return interactionNetworkingSteps;
		},

		leftBtn() {
			let btnObj = {
				title: "app.back",
				disabled: false,
				show: this.current_step.value === 2,
				icon: this.$vuetify.rtl ? "right_chevron" : "left_chevron",
			};
			return btnObj;
		},

		rightBtn() {
			let btnObj = {
				title: "app.next",
				disabled: false,
				show: true,
				icon: this.$vuetify.rtl ? "left_chevron" : "right_chevron",
			};
			return btnObj;
		},
	},

	created() {
		this.$eventBus.$on("in-move-to-summary", (payload) => {
			this.updateCurrentStep(1);
		});
	},

	methods: {
		updateCurrentStep(index) {
			this.current_step = interactionNetworkingSteps[index];
		},
	},

	beforeDestroy() {
		/**
		 * Vue does not destroy listeners until we don't load the page and that's why
		 * whenever any event emit the previously present listeners can also
		 * listen the emitted event and there could be a problem of duplicate
		 * triplicate listeners. So destroy those manually.
		 */
		this.$eventBus.$off("in-move-to-summary");
	},
};
</script>
