<template>
	<div v-if="steps && steps.length">
		<div
			v-for="(item, index) in steps"
			:key="index"
			@click="redirectTo(item)"
			:class="{
				'black--text text-truncate the-navigation-substep-steps-wr': true,
				'text-decoration-underline font-weight-bold': isActive(item.route),
			}"
		>
			<!--
				show steps only if it is not of prioritization tool
				or if so, then check if user has used it once or not.
		 -->
			<div
				v-if="
					$t(item.title).toLowerCase() !== 'prioritization tool' ||
						(process_status && process_status.prioritization_tool_used)
				"
			>
				{{ $t(`app.${item.title}.title`) }}
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from "vuex";
import { mapTypeSwitchingMixin } from "@/mixins/mapTypeSwitchingMixin";
import { isArrayHavingItem } from "@/utils/helpers";

export default {
	name: "Steps",

	mixins: [mapTypeSwitchingMixin],

	props: {
		steps: {
			required: true,
		},
	},

	computed: {
		...mapState({
			mini: (state) => state.globals.mini,
			process_status: (state) => state.process.process_status,
		}),
	},

	methods: {
		async redirectTo(item) {
			try {
				this.$loader.start();

				if (!item.route) return;

				// why special case? because we have to reset each mind map when change route
				let is_mm_routes = isArrayHavingItem(
					[
						"ChooseTopicWizardMindmap",
						"ChooseTopicStandardMindmap",
						"ChooseTopicTableChart",
					],
					item.route,
				);
				if (is_mm_routes) {
					// Reset current and completed steps (If required)
					await this.resetSteps(item.route);
				}

				this.$router.push({ name: item.route });
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		isActive(route) {
			//If current route is same with the loop item's route then annouce link as active.
			return this.$route.name == route;
		},
	},
};
</script>

<style scoped>
.the-navigation-substep-steps-wr {
	font-size: 0.8125rem !important; /** 13px */
}
</style>
