import Vue from "vue";

export const announce = {
	success: (message) => {
		Vue.prototype.$eventBus.$emit("announce", {
			type: "success",
			message,
		});
	},

	error: (error) => {
		// It not custom error, then console it
		if (error.cause != "werCustom") {
			console.error(error, error);
		}
		Vue.prototype.$eventBus.$emit("announce", {
			type: "error",
			message: Vue.prototype.i18n.t(error.message),
		});
	},

	warning: (message) => {
		Vue.prototype.$eventBus.$emit("announce", {
			type: "warning",
			message,
		});
	},

	info: (message) => {
		Vue.prototype.$eventBus.$emit("announce", {
			type: "info",
			message,
		});
	},
};

Vue.prototype.$announce = announce;
