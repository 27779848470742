<template>
	<v-row no-gutters>
		<!-- Left button -->
		<v-col>
			<AppButton
				v-if="leftBtn.show"
				:label="leftBtn.title"
				:left_icon="true"
				:icon="leftBtn.icon || null"
				:disabled="leftBtn.disabled"
				@click="redirectBack()"
			></AppButton>
		</v-col>

		<!-- Right Button -->
		<v-col class="text-end">
			<AppButton
				v-if="rightBtn.show"
				:label="rightBtn.title"
				:icon="rightBtn.icon || null"
				:disabled="rightBtn.disabled"
				@click="$eventBus.$emit('ms-submit')"
			></AppButton>
		</v-col>
	</v-row>
</template>

<script>
export default {
	name: "MeetingSummaryFooter",

	data() {
		return {
			submitted: false,
		};
	},

	watch: {
		$route(to, from) {
			if (from.name == "MeetingSummaryExplore" && to.name == "MeetingsList") {
				this.submitted = false;
			}
		},
	},

	computed: {
		isListRoute() {
			return this.$route.name === "MeetingsList";
		},

		isExploreRoute() {
			return this.$route.name === "MeetingSummaryExplore";
		},

		leftBtn() {
			let btnObj = {
				title: "app.back",
				disabled: false,
				show: true,
				icon: this.$vuetify.rtl ? "right_chevron" : "left_chevron",
			};
			return btnObj;
		},

		rightBtn() {
			let btnObj = {
				title: "app.submit_to_meeting_list",
				disabled: false,
				show: this.isExploreRoute && !this.submitted,
				icon: this.$vuetify.rtl ? "left_chevron" : "right_chevron",
			};
			return btnObj;
		},
	},

	created() {
		this.$eventBus.$on("ms-status-updated", (payload) => {
			this.submitted = payload.status;
		});
	},

	methods: {
		redirectBack() {
			try {
				this.$loader.start();

				this.$router.push({
					name: this.isListRoute
						? "Tools"
						: this.isExploreRoute
						? "MeetingsList"
						: null,
				});
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},
	},
};
</script>
