import Vue from "vue";
import { researchPaper as API } from "@/services/api";
import { getField, updateField } from "vuex-map-fields";
import {
	isJson,
	filterArray,
	findFromArray,
	findElIndex,
} from "@/utils/helpers";

// INITIATE STATE
function initialState() {
	return {
		template: [],
		help_content: [],
		current_tab: "tab-toc",
	};
}

// STATE
const state = initialState();

// GETTERS
const getters = {
	getField,
	getSublevel: (state, getters) => (chapter_id) => {
		var chapter = getters["getChapter"](chapter_id);
		var sublevel = state.template.filter((item) => {
			return item.level == chapter.level && item.sub_level;
		});
		return sublevel;
	},

	getContent: (state) => (chapter_id) => {
		var chapter = findFromArray(state.template, "id", chapter_id);

		var output = {};
		if (isJson(chapter.content)) {
			output = JSON.parse(chapter.content);
		}
		if (output == null) {
			return {};
		}
		return output;
	},

	getHelpContent: (state) => (tip_type) => {
		return filterArray(state.help_content, "tip_type", tip_type, true);
	},

	getChapter: (state) => (chapter_id) => {
		var chapter = findFromArray(state.template, "id", chapter_id);
		if (!chapter) {
			return {};
		}
		return chapter;
	},

	getSublevelCount: (state) => (level) => {
		var chapter = state.template.filter((item) => {
			return item.level == level && item.sub_level;
		});
		return chapter.length + 1;
	},

	getPrivateChapterCount: (state) => {
		return state.template.filter(
			(item) => item.origin == "private" && !item.sub_level,
		).length;
	},
};

// ACTIONS
const actions = {
	async template(context, payload) {
		var response = await API.template(payload);
		context.commit("SET_TEMPLATE", response);
		return response;
	},

	async addChapter(context, payload) {
		return await API.addChapter(payload);
	},

	async editChapter(context, payload) {
		await API.editChapter(payload);
	},

	async delChapter(context, payload) {
		await API.delChapter(payload);
		context.commit("DELETE_CHAPTER", payload);
	},

	async getHelpContent(context, payload) {
		return await API.getHelpContent(payload);
	},

	async setTemplateUUID(context, payload) {
		let response = await API.setTemplateUUID(payload);
		context.commit("UPDATE_CHAPTER", response);
	},

	async uploadImageToPoster(context, payload) {
		return await API.uploadImageToPoster(payload);
	},

	async removePosterImage(context, payload) {
		return await API.removePosterImage(payload);
	},
};

// MUTATIONS
const mutations = {
	updateField,
	SET_TEMPLATE: (state, payload) => {
		state.template = payload;
	},

	DELETE_CHAPTER: (state, payload) => {
		//Find index of specific object using findIndex method.
		let objIndex = findElIndex(state.template, "id", payload.id);
		// If index found then update the data at index.
		if (objIndex !== -1) {
			state.template.splice(objIndex, 1);
		}
	},

	UPDATE_CHAPTER: (state, payload) => {
		//Find index of specific object using findIndex method.
		let objIndex = findElIndex(state.template, "id", payload.id);

		// If index found then update the data at index.
		if (objIndex !== -1) {
			Vue.set(state.template, objIndex, payload);
		}
	},

	RESET_STATE: (state) => {
		const s = initialState();
		Object.keys(s).forEach((key) => {
			state[key] = s[key];
		});
	},
};

// Export module
export const researchPaper = {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
