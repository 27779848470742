<template>
	<div>
		<v-expansion-panels
			flat
			tile
			accordion
			@change="
				$eventBus.$emit('calculate-view-port-height', {
					header_updated: true,
				})
			"
		>
			<v-expansion-panel>
				<v-expansion-panel-header :ripple="false" class="pa-0">
					<template v-slot:actions>
						<v-btn
							icon
							small
							class="white"
							:style="{ border: '2px solid grey !important' }"
						>
							<v-icon color="secondary"
								>$vuetify.icons.values.down_chevron</v-icon
							>
						</v-btn>
					</template>
					<div
						v-if="title"
						:class="['text-h6', 'font-weight-bold', titleColor + '--text']"
						:style="headerStyleObj"
					>
						{{ $t(title) }}

						<span class="ms-3">
							<!-- Listen instruction -->
							<TextToSpeech :text="$t(subtitle)"></TextToSpeech>
						</span>

						<span v-if="video_id" class="ms-3">
							<!-- Listen instruction -->
							<StageVideoDialog :video_id="video_id"></StageVideoDialog>
						</span>
					</div>
				</v-expansion-panel-header>
				<v-expansion-panel-content>
					<div
						id="the-subheading-container-wr"
						class="body-2 greyDarken4--text"
					>
						<div
							v-if="subtitle"
							class="the-header-instructions-wr pb-2"
							:style="styleObj"
							v-html="$t(subtitle)"
						></div>
					</div>
				</v-expansion-panel-content>
			</v-expansion-panel>
		</v-expansion-panels>
	</div>
</template>

<script>
import TextToSpeech from "@/components/plugins/TextToSpeech.vue";
import { mapGetters } from "vuex";
import StageVideoDialog from "@/components/research/modules/_common/stages/VideoDialog.vue";

export default {
	name: "TheHeaderInterface",

	props: {
		title: {
			required: true,
		},
		subtitle: {
			required: true,
		},
		video_id: {
			required: false,
		},
	},

	components: {
		TextToSpeech,
		StageVideoDialog,
	},

	computed: {
		...mapGetters({
			isMentor: "user/isMentor",
		}),

		titleColor() {
			return this.$store.getters["research/getModuleColor"]();
		},

		headerStyleObj() {
			if (this.isMentor) {
				return {
					[this.$vuetify.rtl ? "padding-right" : "padding-left"]: "85px",
				};
			}
			return {};
		},

		styleObj() {
			if (this.isMentor) {
				return {
					[this.$vuetify.rtl ? "padding-right" : "padding-left"]: "85px",
				};
			}
			return {};
		},
	},
};
</script>

<style scoped>
.the-header-instructions-wr {
	font-size: 0.9375rem !important; /** 15px */
}

>>> #the-subheading-container-wr {
	line-height: inherit !important;
}

>>> .v-expansion-panel-content__wrap {
	padding: 0px !important;
}

>>> .v-expansion-panel--active > .v-expansion-panel-header {
	min-height: 48px !important;
}
</style>
