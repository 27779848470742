const path_prefix = "/research/tools/knowledge_organizers/bible";

export const bible = [
	{
		path: `${path_prefix}/bible/index`,
		name: "Bible",
		component: () =>
			import(
				/* webpackChunkName:"page.ko.bible"*/ "@/pages/users/student/tools/knowledgeOrganizers/bible/Index.vue"
			),
		meta: {
			layout: "AppLayout",
			requiresAuth: true,
			header: {
				title: "app.ko.bible.title",
				subtitle: "",
			},
			footer: {
				component: "ToolsKnowledgeOrganizers",
			},
		},
	},
];
