import { group as API } from "@/services/api";
import { findElIndex, findFromArray } from "@/utils/helpers";

// INITIATE STATE
function initialState() {
	return {
		current_group_id: null,
		breadcrumbs: [
			{
				id: 0,
				text: "app.all_groups",
			},
		],
	};
}

// STATE
const state = initialState();

// GETTERS
const getters = {};

// ACTIONS
const actions = {
	async list(context) {
		return await API.list();
	},

	async create(context, payload) {
		return await API.create(payload);
	},

	async update(context, payload) {
		return await API.update(payload);
	},

	async delete(context, payload) {
		return await API.delete(payload);
	},

	async listSubGroups(context, payload) {
		return await API.listSubGroups(payload);
	},

	async addUser(context, payload) {
		return await API.addUser(payload);
	},

	async listUsers(context, payload) {
		return await API.listUsers(payload);
	},
};

// MUTATIONS
const mutations = {
	PUSH_TO_BREADCRUMBS: (state, payload) => {
		// Serch the item
		let exists = findFromArray(state.breadcrumbs, "id", payload.id);
		// If not exists already then push
		if (!exists) {
			state.breadcrumbs.push({
				id: payload.id,
				text: payload.name,
			});
			// Also set requested breadcrumb as current
			state.current_group_id = payload.id;
		}
	},

	REMOVE_FROM_BREADCRUMBS: (state, payload) => {
		// Serch the index
		let index = findElIndex(state.breadcrumbs, "id", payload.id);
		// If find then remove all items after the requested breadcrumb
		if (index != -1) {
			state.breadcrumbs.splice(index + 1, state.breadcrumbs.length - 1);
			// Also set requested breadcrumb as current
			state.current_group_id = payload.id;
		}
	},

	RESET_BREADCRUMBS: (state, payload) => {
		const s = initialState();
		state.current_group_id = s.current_group_id;
		state.breadcrumbs = s.breadcrumbs;
	},

	RESET_STATE: (state) => {
		const s = initialState();
		Object.keys(s).forEach((key) => {
			state[key] = s[key];
		});
	},
};

// Export module
export const group = {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
