<template>
	<v-card flat height="100%">
		<v-container fill-height>
			<v-row justify="center">
				<v-col align="center">
					<Loading type="spin" color="purple" scale="0.7"></Loading>
					<v-card-text
						class="display-3"
						v-html="$t('app.skillsx_with_style')"
					></v-card-text>
				</v-col>
			</v-row>
		</v-container>
	</v-card>
</template>

<script>
import Loading from "@/components/plugins/Loading.vue";

export default {
	name: "SplashScreen",

	components: {
		Loading,
	},
};
</script>

<style></style>
