export const researchPaper = [
	{
		path: "/research/research_paper",
		name: "ResearchPaper",
		component: () =>
			import(
				/* webpackChunkName:"page.w-rp"*/ "@/pages/research/modules/write/stages/ResearchPaper"
			),
		meta: {
			layout: "AppLayout",
			requiresAuth: true,
			header: {
				title: "app.research_paper.title",
			},
			footer: {
				component: "Buttons",
				data: {
					rightBtn: {
						title: "app.finish",
						event_name: "rp-finish",
					},
				},
			},
		},
	},
];
