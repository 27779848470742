import { callApi } from "./manager";

export const group = {
	list: () => {
		return callApi(`/user/group/`, "get");
	},

	create: (payload) => {
		return callApi(`/user/group/`, "post", payload);
	},

	update: (payload) => {
		return callApi(`/user/group/${payload.group_id}/`, "put", payload);
	},

	delete: (payload) => {
		return callApi(`/user/group/${payload.group_id}`, "delete");
	},

	listSubGroups: (payload) => {
		return callApi(`/user/group?parent=${payload.group_id}`, "get");
	},

	addUser: (payload) => {
		return callApi(`/user/detail/`, "post", payload);
	},

	listUsers: (payload) => {
		return callApi(`/user/detail?group=${payload.group_id}`, "get");
	},
};
