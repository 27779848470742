export const meeting = [
	{
		path: "/research/tools/meeting",
		name: "Meet",
		component: () =>
			import(
				/* webpackChunkName:"page.meeting"*/ "@/pages/users/student/tools/meeting/Index.vue"
			),
		meta: {
			layout: "AppLayout",
			requiresAuth: true,
			header: {
				title: "app.meetings.meet_with_mentor.title",
				subtitle: "app.meetings.meet_with_mentor.subtitle",
			},
			footer: {
				component: "Tools",
			},
		},
	},
];
