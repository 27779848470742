export const group = [
	{
		path: "/research/mentor/groups",
		name: "MentorGroups",
		component: () =>
			import(
				/* webpackChunkName:"page.m-groups"*/ "@/pages/users/mentor/group/Index.vue"
			),
		meta: {
			layout: "MentorLayout",
			requiresAuth: true,
			header: {
				title: "app.groups.title",
			},
		},
	},
];
