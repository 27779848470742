import axios from "axios";

export const getBook = async (isbn) => {
	return await axios
		.get(`https://www.googleapis.com/books/v1/volumes?q=isbn:${isbn}`)
		.then((response) => {
			// return only first item (in most cases only single will return)
			return response && response.data.totalItems
				? response.data.items[0]
				: null;
		})
		.catch((error) => {
			throw error;
		});
};
