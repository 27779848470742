<template>
	<TheHeaderInterface
		v-if="currentStep"
		:title="currentStep.header_title"
		:subtitle="currentStep.header_subtitle"
	></TheHeaderInterface>
</template>

<script>
import { mapState } from "vuex";
import TheHeaderInterface from "@/components/layout/TheHeaderInterface.vue";
import { isArrayHavingItem } from "@/utils/helpers";

export default {
	name: "ToolsQuery",

	components: {
		TheHeaderInterface,
	},

	watch: {
		"current_step.value": function(newVal, oldVal) {
			if (newVal !== oldVal) {
				this.$eventBus.$emit("calculate-view-port-height");
			}
		},
	},

	computed: {
		...mapState({
			question_current_step: (state) =>
				state.query.choose_question.current_step,
			mindmap_current_step: (state) => state.query.mindmap.current_step,
			toc_current_step: (state) => state.query.toc.current_step,
			keywords_current_step: (state) => state.query.keywords.current_step,
		}),

		isChooseQuestionQueryRoute() {
			return isArrayHavingItem(
				["ChooseQuestionQuery", "rt6lio_AIChooseQuestion"],
				this.$route.name,
			);
		},

		isMindmapQueryRoute() {
			return isArrayHavingItem(
				["MindmapQuery", "rt6lio_AIMindmap"],
				this.$route.name,
			);
		},

		isTOCQueryRoute() {
			return isArrayHavingItem(["TOCQuery"], this.$route.name);
		},

		isKeywordsQueryRoute() {
			return isArrayHavingItem(["KeywordsQuery"], this.$route.name);
		},

		currentStep() {
			if (this.isChooseQuestionQueryRoute) {
				return this.question_current_step;
			}
			if (this.isMindmapQueryRoute) {
				return this.mindmap_current_step;
			}
			if (this.isTOCQueryRoute) {
				return this.toc_current_step;
			}
			if (this.isKeywordsQueryRoute) {
				return this.keywords_current_step;
			}
		},
	},
};
</script>
