const path_prefix = "/research/tools/interview";

export const interview = [
	{
		path: `${path_prefix}/list`,
		name: "InterviewsList",
		component: () =>
			import(
				/* webpackChunkName:"page.interviews"*/ "@/pages/users/student/tools/interview/List.vue"
			),
		meta: {
			layout: "AppLayout",
			requiresAuth: true,
			header: {
				title: "app.interviews.title",
				subtitle: "app.interviews.subtitle",
			},
			footer: {
				component: "Tools",
			},
		},
	},
	{
		path: `${path_prefix}/list/interview/:id`,
		name: "Interview",
		component: () =>
			import(
				/* webpackChunkName:"page.interview"*/ "@/pages/users/student/tools/interview/Index.vue"
			),
		meta: {
			layout: "AppLayout",
			requiresAuth: true,
			header: {
				component: "Interview",
			},
			footer: {
				component: "Tools",
			},
		},
	},
];
