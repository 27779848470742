export const presentResearchPlan = [
	{
		path: "/research/present_confirm_timeline",
		name: "PresentResearchPlan",
		component: () =>
			import(
				/* webpackChunkName:"page.pr-ct"*/ "@/pages/research/modules/_common/Planning.vue"
			),
		meta: {
			layout: "AppLayout",
			requiresAuth: true,
			module: "M6",
			header: {
				component: "ResearchPlanning",
			},
			footer: {
				component: "ResearchPlanning",
			},
		},
	},
];
