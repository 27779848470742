<template>
	<v-dialog v-model="dialog" scrollable width="1000px" persistent>
		<template v-slot:activator="{ on, attrs }">
			<v-tooltip top v-bind="attrs" v-on="on">
				<template v-slot:activator="{ on, attrs }">
					<v-icon
						v-bind="attrs"
						v-on="on"
						class="cursor-wr"
						@click="dialog = true"
						:class="{ 'vertical-transform-wr': $vuetify.rtl }"
						>$vuetify.icons.values.translate</v-icon
					>
				</template>
				<span>{{ $t("app.translate") }}</span>
			</v-tooltip>
		</template>

		<AppDialog
			v-if="dialog"
			:heading="{
				label: 'app.tools.translator.title',
			}"
			:height="650"
			@close="resetAndCloseDialog()"
		>
			<template v-slot:dialog-content>
				<v-card flat>
					<!-- ERROR MESSAGES -->
					<v-card-text class="pt-0">
						<div v-if="!isSourceLangSelected" class="error--text subtitle-1">
							* {{ $t("app.ntfy.err.source_lang_missing") }}
						</div>
						<div
							v-if="isSourceLangSelected && !isSourceTextPresent"
							class="error--text subtitle-1"
						>
							* {{ $t("app.ntfy.err.source_text_missing") }}
						</div>
						<div
							v-if="
								isSourceLangSelected &&
									isSourceTextPresent &&
									!isTargetLangSelected
							"
							class="error--text subtitle-1"
						>
							* {{ $t("app.ntfy.err.target_lang_missing") }}
						</div>
					</v-card-text>
					<v-card-text>
						<v-row no-gutters>
							<v-col>
								<!-- source language -->
								<v-form ref="source_form">
									<v-autocomplete
										v-model="source_lang"
										:items="$defines.TRANSLATOR_LANGUAGES"
										item-text="name"
										hide-no-data
										outlined
										hide-details
										hide-selected
										:label="$t('app.source_lang')"
										:placeholder="$t('app.placeholder.type_to_search')"
										return-object
										prepend-inner-icon="$vuetify.icons.values.language"
										clearable
										color="greenAccent2"
										:clear-icon="$vuetify.icons.values.close"
										@click:clear="clearTarget()"
									></v-autocomplete>
								</v-form>
							</v-col>
							<v-col>
								<!-- target language -->
								<v-form ref="target_form">
									<v-autocomplete
										v-model="target_lang"
										:items="$defines.TRANSLATOR_LANGUAGES"
										item-text="name"
										outlined
										hide-details
										:disabled="!isSourceLangSelected || !isSourceTextPresent"
										:label="$t('app.target_lang')"
										:placeholder="$t('app.placeholder.type_to_search')"
										@input="translate()"
										return-object
										prepend-inner-icon="$vuetify.icons.values.language"
										clearable
										color="greenAccent2"
										:clear-icon="$vuetify.icons.values.close"
										@click:clear="clearTarget()"
									></v-autocomplete>
								</v-form>
							</v-col>
						</v-row>

						<v-row no-gutters>
							<!-- Text to translate -->
							<v-col class="relative-pos-wr">
								<v-card outlined tile height="300">
									<!-- source text -->
									<v-textarea
										v-model="source_text"
										flat
										solo
										:placeholder="$t('app.placeholder.enter_text')"
										height="230"
										class="title"
										:counter="1000"
										:disabled="!isSourceLangSelected"
										maxlength="1000"
										:style="{
											direction: source_lang && source_lang.rtl ? 'rtl' : 'ltr',
										}"
										v-debounce:1s="translate"
										clearable
										color="greenAccent2"
										background-color="white"
										:clear-icon="$vuetify.icons.values.close"
										@click:clear="clearTarget()"
									>
									</v-textarea>

									<!-- source actions -->
									<v-footer absolute bottom class="white" height="45">
										<!-- Speech to text -->
										<SpeechToText
											v-if="isSourceLangSelected"
											:text="source_text"
											@speechend="source_text = $event.text"
										></SpeechToText>
									</v-footer>
								</v-card>
							</v-col>

							<v-col class="relative-pos-wr">
								<!-- Translated text -->
								<v-card
									tile
									outlined
									height="300"
									class="greyLighten4 pa-2"
									:style="{
										direction:
											isTargetLangSelected && target_lang.rtl ? 'rtl' : 'ltr',
									}"
								>
									<!-- When request is in progress -->
									<v-container fill-height fluid v-if="translating">
										<v-row align="center" justify="center">
											<v-col align="center" class="text-h5">
												{{ $t("app.translating") }}...
											</v-col>
										</v-row>
									</v-container>

									<!-- When requested is completed -->
									<template
										v-if="
											isTargetLangSelected && !translating && translated_text
										"
									>
										<v-card-text class="black--text title translated-text-wr">
											{{ translated_text }}
										</v-card-text>

										<!-- target actions -->
										<v-footer absolute bottom height="45">
											<!-- copy -->
											<CopyToClipboard
												:message="translated_text"
											></CopyToClipboard>

											<!-- text to speech -->
											<span class="mx-3">
												<TextToSpeech
													:text="translated_text"
													:prop_lang="target_lang.code"
												></TextToSpeech>
											</span>
										</v-footer>
									</template>
								</v-card>
							</v-col>
						</v-row>
					</v-card-text>
				</v-card>
			</template>
		</AppDialog>
	</v-dialog>
</template>

<script>
import AppDialog from "@/components/ui/AppDialog.vue";
import TextToSpeech from "@/components/plugins/TextToSpeech.vue";
import CopyToClipboard from "@/components/plugins/CopyToClipboard";
import SpeechToText from "@/components/plugins/SpeechToText.vue";

export default {
	name: "Translator",

	props: {
		text: {
			required: true,
		},
	},

	data() {
		return {
			source_lang: null,
			source_text: null,
			target_lang: null,
			translated_text: null,
			translating: false,
			dialog: false,
		};
	},

	components: {
		AppDialog,
		TextToSpeech,
		CopyToClipboard,
		SpeechToText,
	},

	watch: {
		dialog(newVal, oldVal) {
			if (newVal) {
				this.source_text = this.text;
			}
		},
	},

	computed: {
		isSourceLangSelected() {
			return this.source_lang;
		},
		isTargetLangSelected() {
			return this.target_lang;
		},
		isSourceTextPresent() {
			return this.source_text;
		},
	},

	methods: {
		onPaste($event) {
			console.log($event);
		},

		clearTarget() {
			this.target_lang = null;
			this.translated_text = null;
		},

		async translate() {
			try {
				if (!this.source_text || !this.target_lang) return;

				// Set translating flag true
				this.translating = true;

				// Translate
				let res = await this.$store.dispatch("translator/translate", {
					text: this.source_text,
					target: this.target_lang.code,
				});

				// Set new translated
				this.translated_text = res["translated text"];
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.translating = false;
			}
		},

		resetAndCloseDialog() {
			try {
				this.$loader.start();
				// reset source form
				this.$refs.source_form.reset();
				// reset target form
				this.$refs.target_form.reset();
				// reset other properties
				this.translated_text = null;
				// Check if text-to-speech dialog is open then close it's voice.
				this.$eventBus.$emit("stop-tts");
				// Close dialog
				this.dialog = false;
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},
	},
};
</script>

<style scoped>
.translated-text-wr {
	height: 255px !important;
	overflow-y: auto !important;
}
</style>
