export const sourceSelection = [
	{
		path: "/research/source_selection",
		name: "SourceSelection",
		component: () =>
			import(
				/* webpackChunkName:"page.gs-ss"*/ "@/pages/research/modules/gatherSources/stages/SourceSelection.vue"
			),
		meta: {
			layout: "AppLayout",
			requiresAuth: true,
			header: {
				title: "app.source_selection.title",
				subtitle: "app.source_selection.subtitle",
			},
			footer: {
				component: "Buttons",
				data: {
					rightBtn: {
						title: "app.completed_source_list",
						event_name: "finish-source-selection",
					},
				},
			},
		},
	},
];
