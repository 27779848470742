export const dashboard = [
	{
		path: "/research/dashboard",
		name: "UserDashboard",
		component: () =>
			import(
				/* webpackChunkName:"page.dashboard"*/ "@/pages/users/student/dashboard/Index.vue"
			),
		meta: {
			layout: "AppLayout",
			requiresAuth: true,
			header: false,
		},
	},
];
