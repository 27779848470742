import { environments } from "../environment/environment";
import { DEFINES } from "@/utils/defines";

export const loadAccessibilityScript = (lang) => {
	window.interdeal = {
		sitekey: environments.accessibility_key,
		Menulang: lang
			? lang.toUpperCase()
			: navigator.language.split("-")[0].toUpperCase(),
		domains: {
			js: "https://js.nagich.co.il/",
			acc: "https://access.nagich.co.il/",
		},
		isPartial: true,
	};
	(function(doc, head, body) {
		var coreCall = doc.createElement("script");
		coreCall.src = "https://js.nagich.co.il/core/4.3.5/accessibility.js";
		coreCall.defer = true;
		coreCall.integrity = environments.accessibility_integrity;
		coreCall.crossOrigin = "anonymous";
		coreCall.setAttribute("data-cfasync", true);
		body ? body.appendChild(coreCall) : head.appendChild(coreCall);
	})(document, document.head, document.body);
};

export const updateAccessibilityParams = (lang) => {
	if (!lang) return;

	// set locale
	window.interdeal.SetLocale(lang);
	// set menu language (should in uppercase)
	window.interdeal.Menulang = lang.toUpperCase();
	// set position
	window.interdeal.setPosition(
		DEFINES.LOCALES.find((item) => item.code == lang).direction,
	);
};
