<template>
	<TheHeaderInterface
		v-if="userPlan && userPlan.length"
		title="app.dashboard.title"
		subtitle="app.dashboard.subtitle"
	></TheHeaderInterface>
</template>

<script>
import { mapState } from "vuex";
import TheHeaderInterface from "@/components/layout/TheHeaderInterface.vue";

export default {
	name: "UserDashboardHeader",

	components: {
		TheHeaderInterface,
	},

	watch: {
		userPlan(newVal, oldVal) {
			if (newVal !== oldVal) {
				this.$eventBus.$emit("calculate-view-port-height");
			}
		},
	},

	computed: {
		...mapState({
			userPlan: (state) => state.planAndManage.user_plan,
		}),
	},
};
</script>
