import axios from "axios";
import { getFullUrlForEtherpadApiReq } from "@/utils/helpers";

async function callApi(url, httpMethod, payload = {}) {
	let req_url = getFullUrlForEtherpadApiReq(url);

	switch (httpMethod) {
		// Post request
		case "post":
			return axios
				.post(req_url, payload)
				.then((response) => {
					return validateApiResponse(response);
				})
				.catch((error) => {
					throw error;
				});
		// Get Request
		case "get":
			return axios
				.get(req_url)
				.then((response) => {
					return validateApiResponse(response);
				})
				.catch((error) => {
					throw error;
				});
		// Delete request
		case "delete":
			return axios
				.delete(req_url, headers)
				.then((response) => {
					return validateApiResponse(response);
				})
				.catch((error) => {
					throw error;
				});
		// Put request
		case "put":
			return axios
				.put(req_url, payload, headers)
				.then((response) => {
					return validateApiResponse(response);
				})
				.catch((error) => {
					throw error;
				});
	}
}

const validateApiResponse = (response) => {
	let error = {};
	if (response.data.code == 0) {
		return response.data.data;
	} else {
		error.message = response.data.message;
		throw error;
	}
};

export { callApi };
