import { callApi } from "./manager";
import qs from "qs";

export const researchPaper = {
	template: (payload) => {
		return callApi(`/integration/paper?${qs.stringify(payload)}`, "get");
	},

	addChapter: (payload) => {
		return callApi(`/integration/paper/`, "post", payload);
	},

	editChapter: (payload) => {
		return callApi(`/integration/paper/${payload.id}/`, "put", payload);
	},

	getHelpContent: (payload) => {
		return callApi(
			`/integration/papertip/${payload.id}?tip_type=${payload.tip_type}`,
			"get",
		);
	},

	delChapter: (payload) => {
		return callApi(`/integration/paper/${payload.id}/`, "delete");
	},

	setTemplateUUID: (payload) => {
		return callApi(`/integration/paper/${payload.id}/`, "put", payload);
	},

	uploadImageToPoster: (payload) => {
		return callApi(`/rsc/user_free_files/`, "post", payload);
	},

	removePosterImage: (payload) => {
		return callApi(`/rsc/user_free_files/${payload.image_id}`, "delete");
	},

	getOfStudent: (payload) => {
		return callApi(
			`/integration/paper?clear=${payload.clear}&user_id=${payload.student_id}`,
			"get",
		);
	},
};
