export const dashboard = [
	{
		path: "/research/mentor/dashboard",
		name: "MentorDashboard",
		component: () =>
			import(
				/* webpackChunkName:"page.m-dashboard"*/ "@/pages/users/mentor/dashboard/Index.vue"
			),
		meta: {
			layout: "MentorLayout",
			requiresAuth: true,
		},
	},
];
