<template>
	<v-alert
		type="success"
		tile
		dark
		:icon="$vuetify.icons.values.info"
		class="update-alert-wr"
	>
		<v-row>
			<v-col>
				<div class="title font-weight-bold">
					{{ $t("app.updates_available") }}
				</div>
				<div class="subtitle-1">
					{{ $t("app.update_to_new_version") }}
				</div>
			</v-col>
			<v-col align="center">
				<AppButton
					label="app.update_now"
					color="black"
					icon="update"
					:large="true"
					:prop_class="['mt-3']"
					@click="updateVersion()"
				></AppButton>
			</v-col>
		</v-row>
	</v-alert>
</template>

<script>
export default {
	name: "VersionUpdateAlert",

	methods: {
		updateVersion() {
			try {
				this.$loader.start();

				location.reload(true);
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},
	},
};
</script>

<style scoped>
.update-alert-wr {
	position: fixed;
	top: 0;
	z-index: 100;
	width: 100%;
}
</style>
