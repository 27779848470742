<template>
	<v-row v-if="current_step" no-gutters>
		<!-- Left button -->
		<v-col>
			<AppButton
				v-if="leftBtn.show"
				:label="leftBtn.title"
				:disabled="leftBtn.disabled"
				:icon="leftBtn.icon || null"
				:left_icon="true"
				@click="onLeftBtnClick()"
			></AppButton>
		</v-col>

		<!-- Step Progress -->
		<v-col align="center">
			<AppStepsProgress
				v-model="progress_step"
				:progress_step="progress_step"
				:steps="steps"
			></AppStepsProgress>
		</v-col>

		<!-- Right Button -->
		<v-col class="text-end">
			<AppButton
				v-if="rightBtn.show"
				:label="rightBtn.title"
				:disabled="rightBtn.disabled"
				:icon="rightBtn.icon || null"
				@click="$eventBus.$emit(current_step.next_event)"
			></AppButton>
		</v-col>
	</v-row>
</template>

<script>
import { mapFields } from "vuex-map-fields";
import {
	queryToolSteps,
	queryMindmapSteps,
	queryTOCSteps,
	queryKeywordsSteps,
} from "@/utils/steps";
import { footerStepsMixin } from "@/mixins/footerStepsMixin";
import { isArrayHavingItem } from "@/utils/helpers";

export default {
	name: "QueryFooter",

	mixins: [footerStepsMixin],

	computed: {
		...mapFields("query", {
			mindmap_current_step: "mindmap.current_step",
			choose_question_current_step: "choose_question.current_step",
			toc_current_step: "toc.current_step",
			keywords_current_Step: "keywords.current_step",
		}),

		isChooseQuestionQueryRoute() {
			return isArrayHavingItem(
				["ChooseQuestionQuery", "rt6lio_AIChooseQuestion"],
				this.$route.name,
			);
		},

		isMindmapQueryRoute() {
			return isArrayHavingItem(
				["MindmapQuery", "rt6lio_AIMindmap"],
				this.$route.name,
			);
		},

		isTOCQueryRoute() {
			return isArrayHavingItem(["TOCQuery"], this.$route.name);
		},

		isKeywordsQueryRoute() {
			return isArrayHavingItem(["KeywordsQuery"], this.$route.name);
		},

		current_step() {
			if (this.isChooseQuestionQueryRoute) {
				return this.choose_question_current_step;
			}
			if (this.isMindmapQueryRoute) {
				return this.mindmap_current_step;
			}
			if (this.isTOCQueryRoute) {
				return this.toc_current_step;
			}
			if (this.isKeywordsQueryRoute) {
				return this.keywords_current_Step;
			}
		},

		steps() {
			if (this.isChooseQuestionQueryRoute) {
				return queryToolSteps;
			}
			if (this.isMindmapQueryRoute) {
				return queryMindmapSteps;
			}
			if (this.isTOCQueryRoute) {
				return queryTOCSteps;
			}
			if (this.isKeywordsQueryRoute) {
				return queryKeywordsSteps;
			}
		},

		leftBtn() {
			let btnObj = {
				title: "app.back",
				disabled: false,
				show: true,
				icon: this.$vuetify.rtl ? "right_chevron" : "left_chevron",
			};
			return btnObj;
		},

		rightBtn() {
			let btnObj = {
				title: "app.next",
				disabled: false,
				show: this.current_step && this.current_step.next_event,
				icon: this.$vuetify.rtl ? "left_chevron" : "right_chevron",
			};
			return btnObj;
		},
	},

	mounted() {
		if (!this.current_step) {
			this.updateCurrentStep(0);
		}
	},

	methods: {
		updateCurrentStep(index) {
			let step_name;
			if (this.isChooseQuestionQueryRoute) {
				step_name = "choose_question_current_step";
			}
			if (this.isMindmapQueryRoute) {
				step_name = "mindmap_current_step";
			}
			if (this.isTOCQueryRoute) {
				step_name = "toc_current_step";
			}
			if (this.isKeywordsQueryRoute) {
				step_name = "keywords_current_Step";
			}
			this[step_name] = this.steps[index];
		},

		onLeftBtnClick() {
			if (this.current_step.value == 1) {
				this.$router.push({
					name: !isArrayHavingItem(
						this.$defines.UNAUTHORIZED_ROUTES,
						this.$route.name,
					)
						? "Query"
						: "rt6lio_AI",
				});
			} else {
				this.updateCurrentStep(this.current_step.value - 2);
			}
		},
	},
};
</script>
