const path_prefix = "/research";

export const chooseTheme = [
	{
		path: `${path_prefix}/choose_theme`,
		name: "ChooseTheme",
		component: () =>
			import(
				/* webpackChunkName:"page.dq-ct"*/ "@/pages/research/modules/defineQuestion/stages/chooseTheme/Index"
			),
		meta: {
			layout: "AppLayout",
			requiresAuth: true,
			header: {
				title: "app.choose_theme.title",
				subtitle: "app.choose_theme.subtitle",
			},
		},
	},
	{
		path: `${path_prefix}/choose_theme_database`,
		name: "ChooseThemeDatabase",
		component: () =>
			import(
				/* webpackChunkName:"page.dq-ct-td"*/ "@/pages/research/modules/defineQuestion/stages/chooseTheme/alternatives/ThemeDatabase"
			),
		meta: {
			layout: "AppLayout",
			requiresAuth: true,
			header: {
				title: "app.choose_theme.theme_database.route.title",
				subtitle: "app.choose_theme.theme_database.subtitle",
			},
			footer: {
				component: "Buttons",
				data: {
					rightBtn: {
						title: "app.I_already_know",
						event_name: "redirect-next",
					},
				},
			},
		},
	},
	{
		path: `${path_prefix}/choose_theme_summary`,
		name: "ChooseThemeSummary",
		component: () =>
			import(
				/* webpackChunkName:"page.dq-ct-s"*/ "@/pages/research/modules/defineQuestion/stages/chooseTheme/alternatives/Summary"
			),
		meta: {
			layout: "AppLayout",
			requiresAuth: true,
			header: {
				title: "app.choose_theme.summary.route.title",
				subtitle: "app.choose_theme.summary.subtitle",
			},
			footer: {
				component: "Questionnaire",
			},
		},
	},
];
