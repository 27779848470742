import Vue from "vue";

// Custom Icons
import { CUSTOM_ICONS } from "@/utils/icons";

// Ensure you are using css-loader
import "@mdi/font/css/materialdesignicons.css";

// Reference- https://vuetifyjs.com/en/features/treeshaking/
import Vuetify from "vuetify/lib";
Vue.use(Vuetify);

const opts = {
	icons: {
		iconfont: "mdi", // default - only for display purposes
		values: CUSTOM_ICONS,
	},
	rtl: false,
	theme: {
		options: {
			// To use colors names in components style hooks, i.e var(--v-primary-base)
			customProperties: true,
			// We don't need 9 variations of each color, so set it to false
			variations: false,
		},
		themes: {
			light: Vue.prototype.$defines.THEME,
		},
	},
};

export const vuetify = new Vuetify(opts);
