const path_prefix = "/research/agora";

export const agoraRoutes = [
	{
		path: `${path_prefix}/index/channel/:channel_name/:dummy_window`,
		name: "AgoraIndexPage",
		component: () =>
			import(/* webpackChunkName:"page.a"*/ "@/pages/agora/Index"),
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: `${path_prefix}/recording/questionnaire/:questionnaire_id/channel/:channel_name`,
		name: "AgoraRecordingPage",
		component: () =>
			import(
				/* webpackChunkName:"page.a-r"*/ "@/pages/agora/Recording"
			),
		meta: {
			requiresAuth: true,
		},
	},
];
