<template>
	<div>
		<v-img
			:src="require('@/assets/images/Agora-Interactive-Whiteboard.png')"
		></v-img>

		<!-- Show finish dialog -->
		<StageFinishDialog
			v-if="dialog"
			v-model="dialog"
			current_step="app.lessons_for_life.title"
			:dialog="dialog"
			@close="dialog = false"
		></StageFinishDialog>
	</div>
</template>

<script>
import StageFinishDialog from "@/components/research/modules/_common/stages/FinishDialog";

export default {
	name: "LifeLessonsPage",

	data() {
		return {
			dialog: false,
		};
	},

	components: {
		StageFinishDialog,
	},

	created() {
		this.readyToListenEvents();
	},

	methods: {
		readyToListenEvents() {
			this.$eventBus.$on("finish-life-lessons", (payload) => {
				this.dialog = true;
			});
		},
	},

	beforeDestroy() {
		/**
		 * Vue does not destroy listeners until we don't load the page and that's why
		 * whenever any event emit the previously present listeners can also
		 * listen the emitted event and there could be a problem of duplicate
		 * triplicate listeners. So destroy those manually.
		 */
		this.$eventBus.$off("finish-life-lessons");
	},
};
</script>
