import Vue from "vue";
import { customColors } from "@/utils/colors.js";
import { languages } from "@/utils/languages.js";

export const DEFINES = {
	// To use while access another module' functionality
	USE_ROOT: { root: true },

	// Custom theme constants
	THEME: customColors,

	ROOT_CATEGORY_ID: 0,

	QUESTIONNAIRE_STATUS_DRAFT_STRING: "draft",
	QUESTIONNAIRE_STATUS_WAIT_FOR_APPROVAL_STRING: "wait_for_approval",

	// Whiteboard Types
	WHITEBOARD_TYPES: {
		RESEARCH_QUESTION: "research_question",
		WIZARD_MINDMAP: "wizard_mindmap",
		RESEARCH_POSTER: "research_poster",
	},

	// White board's questions' labeling
	OPEN_QUESTION_VALUE: 1,
	CLOSED_QUESTION_VALUE: -1,

	DAYS_OF_WEEK: [
		{ id: 0, key: "sun", value: "Sunday" },
		{ id: 1, key: "mon", value: "Monday" },
		{ id: 2, key: "tue", value: "Tuesday" },
		{ id: 3, key: "wed", value: "Wednesday" },
		{ id: 4, key: "thu", value: "Thursday" },
		{ id: 5, key: "fri", value: "Friday" },
		{ id: 6, key: "sat", value: "Saturday" },
	],

	COLORS_OF_MODULES: ["teal", "orange", "purple", "pink", "indigo", "green"],

	RESEARCH_ACTIVITY_INTERVIEW: "100",
	INTERACT_NETWORK_INTERVIEW: "200",

	NOTIFICATION_TYPE_INBOX: "inbox",
	NOTIFICATION_TYPE_OUTBOX: "outbox",

	SENDING_TO_MENTOR: 1,
	SENDING_TO_OTHERS: 2,
	SENDING_TO_SELF: 3,
	SENDING_TO_STUDENT: 4,

	USER_ROLES: {
		SYS_ADMIN: "sysadmin",
		MENTOR: "mentor",
		STUDENT: "student",
	},

	DELAYED_STATUS_STRING: "delayed",
	EARLIER_STATUS_STRING: "earlier",
	TIMELY_STATUS_STRING: "on_time",

	CREATE_MEETING: 1,
	JOIN_MEETING: 2,

	ENGLISH_BCP_LANG_CODE: "en-US",
	HEBREW_BCP_LANG_CODE: "iw-EL",
	ARABIC_BCP_LANG_CODE: "ar-EL",

	LOCALES: [
		{
			id: 1,
			code: "en",
			name: "English",
			direction: "ltr",
		},
		{
			id: 2,
			code: "he",
			name: "עברית",
			direction: "rtl",
		},
		{
			id: 3,
			code: "ar",
			name: "عربيه",
			direction: "rtl",
		},
	],

	RESEARCH_PAPER_TABS_COLORS: [
		"teal accent-3",
		"amber",
		"blue lighten-3",
		"deep-orange accent-1",
		"lime",
	],

	// translator languages
	TRANSLATOR_LANGUAGES: languages,

	// school types
	SCHOOL_TYPES: {
		1: "elementary",
		2: "middle",
		3: "high",
	},

	PROGRAMS_DESIGNED_FOR: {
		1: "students",
		2: "teachers",
	},

	QUESTIONNAIRE: {
		q10: "m2_topic_selection",
		q20: "m2_subtopic_selection_p1",
		q30: "m2_subtopic_selection_p2",
		q40: "m2_subtopic_selection_p3",
		q50: "m2_research_question_p20",
		q51: "m2_research_question_p21",
		q60: "m2_congratulations end module 2_p1",
		q70: "m3_congratulations end module 3_p1",
		q80: "m4_congratulations end module 4_p1",
		q81: "m5_achievements and challenges module 5_p1",
		q90: "m5_congratulations end module 5_p1",
		q100: "m6_congratulations end module 6_p1",
		q110: "Idea_m3_document sources",
		q120: "Idea_m3_evaluate sources",
		q130: "Idea_m6_end of program",
	},

	POSTER_BACKGROUND: "poster_background",
	POSTER_HEADER: "header",

	RESEARCH_PAPER_HELP_MENU: [
		{
			id: 1,
			title: "app.tips",
			dialog_model: "tips",
			tips: false,
			tip_type: "tip",
		},
		{
			id: 2,
			title: "app.writing_suggestions",
			dialog_model: "suggestion",
			suggestion: false,
			tip_type: "suggestions",
		},
		{
			id: 3,
			title: "app.previous_task_text",
			dialog_model: "previous_task",
			previous_task: false,
		},
	],

	USER_ROLES_ARR: [
		{
			name: "app.admin",
			value: "sysadmin",
		},
		{
			name: "app.mentor",
			value: "mentor",
		},
		{
			name: "app.student",
			value: "student",
		},
	],

	GENDER: [
		{
			name: "app.male",
			value: "male",
		},
		{
			name: "app.female",
			value: "female",
		},
	],

	// Words
	WORDS: {
		10: [
			"app.what",
			"app.which",
			"app.where",
			"app.who",
			"app.why",
			"app.how",
			"app.when",
			"app.whose",
		],
		20: [
			"up and running",
			"app.running",
			"app.working",
			"app.functioning",
			"app.operative",
			"app.in_action",
			"app.going",
			"app.workable",
			"app.serviceable",
		],
		30: [
			"app.additionally",
			"app.also",
			"app.moreover",
			"app.furthermore",
			"app.again",
			"app.further",
			"app.then",
			"app.besides",
			"app.too",
			"app.similarly",
		],
		40: [
			"app._as_well_as",
			"app.first_of_all",
			"app.second",
			"app.in_fact",
			"app.as_a_result",
			"app.consequently",
			"app.for_example",
			"app.for_instance",
			"app.likewise",
		],
		50: ["app.anger", "app.fear", "app.joy", "app.sadness"],
		60: ["app.anticipate", "app.antecede", "app.antedate", "app.guide"],
		70: ["app.emplacement", "app.locale", "app.locality", "app.location"],
		80: [
			"app.a",
			"app.an",
			"app.the",
			"app.am",
			"app.is",
			"app.are",
			"app.was",
			"app.were",
			"app.be",
			"app.been",
			"app.being",
			"app.do",
			"app.does",
			"app.did",
			"app.have",
			"app.has",
			"app.had",
		],
	},

	SYSTEM_CATEGORY: "system",
	TOOLS_CATEGORY: "tools",

	PLAN_STATUS: [
		{
			label: "earlier",
			color: "info",
			value: 20,
		},
		{
			label: "on_time",
			color: "success",
			value: 10,
		},
		{
			label: "delayed",
			color: "error",
			value: 5,
		},
	],

	BRANCO_WEISS_MAP_NAME: "branco_weiss_er4tfv",
	UNAUTHORIZED_ROUTES: ["rt6lio_AIChooseQuestion", "rt6lio_AIMindmap"],
};

Vue.prototype.$defines = DEFINES;
