<template>
	<v-dialog v-model="dialog" scrollable width="800" persistent>
		<v-card>
			<v-container>
				<v-card-text>
					<div
						class="wistia_responsive_padding"
						style="padding:100.0% 0 0 0;position:relative;"
					>
						<div
							class="wistia_responsive_wrapper"
							style="height:100%;left:0;position:absolute;top:0;width:100%;"
						>
							<iframe
								:src="introVideoLink"
								title="SKILLSX"
								allow="autoplay; fullscreen"
								allowtransparency="true"
								frameborder="0"
								scrolling="no"
								class="wistia_embed"
								name="wistia_embed"
								msallowfullscreen
								width="100%"
								height="100%"
							></iframe>
						</div>
					</div>
				</v-card-text>

				<v-card-actions class="justify-center">
					<AppButton
						label="app.welcome_desc"
						color="greenAccent2"
						:icon="$vuetify.rtl ? 'left_chevron' : 'right_chevron'"
						@click="setProcessStatus()"
					></AppButton>
				</v-card-actions>
			</v-container>
		</v-card>
	</v-dialog>
</template>

<script>
import { mapState, mapGetters } from "vuex";
export default {
	name: "AppIntroVideo",

	data() {
		return {
			dialog: false,
		};
	},

	computed: {
		...mapState({
			process_status: (state) => state.process.process_status,
		}),

		...mapGetters({
			isAuthorized: "auth/isAuthorized",
			userLang: "user/getLanguage",
		}),

		introVideoLink() {
			return "https://fast.wistia.net/embed/iframe/394n3hr630?videoFoam=true";
		},
	},

	watch: {
		isAuthorized: {
			handler(newVal, oldVal) {
				if (newVal) {
					this.getProcessStatus();
				}
			},
			immediate: true,
		},
	},

	methods: {
		async getProcessStatus() {
			try {
				this.$loader.start();

				await this.$store.dispatch("process/getProcessStatus", {
					process: "M1",
					step: 11,
				});

				this.dialog = this.process_status.user_first_login;
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		async setProcessStatus() {
			try {
				this.$loader.start();

				await this.$store.dispatch("process/setProcessStatus", {
					process_status_id: this.process_status.id,
					user_first_login: false,
				});

				this.dialog = false;
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},
	},
};
</script>

<style></style>
