import { notifications } from "./notifications";
import { calendar } from "./calendar";
import { dashboard } from "./dashboard";
import { toolsRoutes } from "./tools";
import { settings } from "./settings";
import { student } from "./student";
import { group } from "./group";

export const mentorRoutes = [
	...notifications,
	...calendar,
	...dashboard,
	...toolsRoutes,
	...settings,
	...student,
	...group
];
