export const createSourceList = [
	{
		path: "/research/create_source_list",
		name: "CreateSourceList",
		component: () =>
			import(
				/* webpackChunkName:"page.gs-cs"*/ "@/pages/research/modules/gatherSources/stages/CreateSourceList.vue"
			),
		meta: {
			layout: "AppLayout",
			requiresAuth: true,
			header: {
				title: "app.create_source_list.title",
				subtitle: "app.create_source_list.subtitle",
			},
			footer: {
				component: "Buttons",
				data: {
					rightBtn: {
						title: "app.to_next_stage",
						event_name: "finish-create-source-list",
					},
				},
			},
		},
	},
];
