import { etherpad as API } from "@/services/api";

// INITIATE STATE
function initialState() {
	return {};
}

// STATE
const state = initialState();

// GETTERS
const getters = {};

// ACTIONS
const actions = {
	async getHTML(context, payload) {
		return await API.getHTML(payload);
	},

	async getText(context, payload) {
		return await API.getText(payload);
	},

	async appendText(context, payload) {
		return await API.appendText(payload);
	},

	async createAuthorIfNotExistsFor(context, payload) {
		return await API.createAuthorIfNotExistsFor(payload);
	},

	async getAuthor(context, payload) {
		return await API.getAuthor(payload);
	},

	async doesPadExists(context, payload) {
		return await API.doesPadExists(payload);
	},

	async createPad(context, payload) {
		return await API.createPad(payload);
	},

	async deletePad(context, payload) {
		return await API.deletePad(payload);
	},

	async saveReferences(context, payload) {
		return await API.saveReferences(payload);
	},

	async addReference(context, payload) {
		return await API.addReference(payload);
	},

	async getUsedReferences(context, payload) {
		return await API.getUsedReferences(payload);
	},

	async updateReference(context, payload) {
		return await API.updateReference(payload);
	},
};

// MUTATIONS
const mutations = {
	RESET_STATE: (state) => {
		const s = initialState();
		Object.keys(s).forEach((key) => {
			state[key] = s[key];
		});
	},
};

// Export module
export const etherpad = {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
