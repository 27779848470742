import Vue from "vue";
import { notification as API } from "@/services/api";
import { DEFINES } from "@/utils/defines";
import {
	findElIndex,
	sortArrayOfObjectsByDate,
	filterArray,
} from "@/utils/helpers";

// INITIATE STATE
function initialState() {
	return {
		inbox: [],
		outbox: [],
		preview_mode: false,
		selected_id: null,
	};
}

// STATE
const state = initialState();

// GETTERS
const getters = {
	getMessageSymbol: (state) => (type) => {
		if (type == DEFINES.NOTIFICATION_TYPE_INBOX) {
			return "INBOX";
		}
		if (type == DEFINES.NOTIFICATION_TYPE_OUTBOX) {
			return "OUTBOX";
		}
		return null;
	},

	getRequiredInfo: (state) => (response) => {
		return {
			id: response.id,
			create_date: response.create_date,
			msg_content: response.msg_content,
			msg_new: response.msg_new,
			msg_title: response.msg_title,
			sender: {
				id: response.sender.id,
				name: response.sender.username,
			},
			reciever: {
				id: response.reciever.id,
				name: response.reciever.username,
			},
		};
	},

	getUnReadNotificationsSum: (state) => {
		let result = filterArray(state.inbox, "msg_new", true, true);
		return result.length;
	},
};

// ACTIONS
const actions = {
	async list(context, payload) {
		let response = await API.list(payload);
		let improved_response = [];

		response.forEach((item) => {
			improved_response.push(context.getters.getRequiredInfo(item));
		});

		let msg_symbol = context.getters.getMessageSymbol(payload.type);
		if (msg_symbol) {
			context.commit(`SET_${msg_symbol}`, improved_response);
		}
	},

	async get(context, payload) {
		return await API.get(payload);
	},

	async send(context, payload) {
		return await API.send(payload);
	},

	async update(context, payload) {
		let response = await API.update(payload);

		let msg_symbol = context.getters.getMessageSymbol(payload.type);
		if (msg_symbol) {
			payload.operation === "delete"
				? context.commit(`DELETE_FROM_${msg_symbol}`, {
						id: payload.id,
				  })
				: context.commit(
						`UPDATE_${msg_symbol}`,
						context.getters.getRequiredInfo(response),
				  );
		}
	},
};

// MUTATIONS
const mutations = {
	SET_INBOX: (state, payload) => {
		state.inbox = sortArrayOfObjectsByDate(payload, "create_date");
	},

	SET_OUTBOX: (state, payload) => {
		state.outbox = sortArrayOfObjectsByDate(payload, "create_date");
	},

	PUSH_TO_INBOX: (state, payload) => {
		let objIndex = findElIndex(state.inbox, "id", payload.notification.id);

		// first check if notification should not already exists
		if (objIndex === -1) {
			// Push at the top because it's a new notification
			state.inbox.unshift(payload.notification);
		}
	},

	PUSH_TO_OUTBOX: (state, payload) => {
		let objIndex = findElIndex(state.outbox, "id", payload.notification.id);

		// first check if notification should not already exists
		if (objIndex === -1) {
			// Push at the top because it's a new notification
			state.outbox.unshift(payload.notification);
		}
	},

	UPDATE_INBOX: (state, payload) => {
		let objIndex = findElIndex(state.inbox, "id", payload.id);

		if (objIndex !== -1) {
			Vue.set(state.inbox, objIndex, payload);
		}
	},

	UPDATE_OUTBOX: (state, payload) => {
		let objIndex = findElIndex(state.outbox, "id", payload.id);

		if (objIndex !== -1) {
			Vue.set(state.outbox, objIndex, payload);
		}
	},

	UPDATE_NOTIFICATION_MODE: (state, payload) => {
		state.preview_mode = payload.preview_mode;
		state.selected_id = payload.selected_id;
	},

	DELETE_FROM_INBOX: (state, payload) => {
		let objIndex = findElIndex(state.inbox, "id", payload.id);
		if (objIndex !== -1) {
			state.inbox.splice(objIndex, 1);
		}
	},

	DELETE_FROM_OUTBOX: (state, payload) => {
		let objIndex = findElIndex(state.outbox, "id", payload.id);
		if (objIndex !== -1) {
			state.outbox.splice(objIndex, 1);
		}
	},

	RESET_STATE: (state) => {
		const s = initialState();
		Object.keys(s).forEach((key) => {
			state[key] = s[key];
		});
	},
};

// Export module
export const notification = {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
