import { questionnaire as API } from "@/services/api";
import Vue from "vue";
import { findFromArray, findElIndex } from "@/utils/helpers";
import { getField, updateField } from "vuex-map-fields";
import { DEFINES } from "@/utils/defines";

// INITIATE STATE
function initialState() {
	return {
		list: null,
		choose_topic_current_questionnaire: DEFINES.QUESTIONNAIRE.q20,
		sub_questionnaires: [],
		sub_questionnaires_edit_modes: {},
		prioritization_selected_subtopic_id: null,
		prioritization_current_step: null,
	};
}

// STATE
const state = initialState();

// GETTERS
const getters = {
	getField,
	getById: (state) => (questionnaire_id) => {
		return findFromArray(state.list.answers, "id", questionnaire_id);
	},

	getByQuestionType: (state) => (type) => {
		return state.list.answers.find((item) => item.question.type == type);
	},

	getAnswerByQuestionType: (state, getters) => (type) => {
		let questionnaire = getters.getByQuestionType(type);
		if (questionnaire == undefined) {
			return null;
		}
		let answer = findFromArray(
			questionnaire.question.options,
			"id",
			questionnaire.formated_answer,
		);

		if (answer == undefined) {
			return null;
		}
		return answer;
	},
};

// ACTIONS
const actions = {
	async list(context, payload) {
		let response = await API.list(payload);
		context.commit("SET_LIST", response);
	},

	async get(context, payload) {
		return await API.list(payload);
	},

	async submitAnswer(context, payload) {
		let response = await API.submitAnswer(payload);
		context.commit("SET_ANSWER", response);
		return response;
	},

	async submit(context, payload) {
		let response = await API.submit(payload);
		context.commit("SET_LIST", response);
	},

	async getSubQuestionnaires(context, payload) {
		let response = await API.getSubQuestionnaires(payload);
		context.commit("SET_SUB_QUESTIONNAIRES", response);
	},

	async addQuestionnaire(context, payload) {
		let response = await API.addQuestionnaire(payload);
		context.commit("PUSH_INTO_SUB_QUESTIONNAIRES", response);
		return response;
	},

	async removeQuestionnaire(context, payload) {
		await API.removeQuestionnaire(payload);
		context.commit("REMOVE_FROM_SUB_QUESTIONNAIRES", payload);
	},
};

// MUTATIONS
const mutations = {
	updateField,
	SET_LIST: (state, payload) => {
		state.list = payload;
	},

	SET_ANSWER: (state, payload) => {
		if (!state.list || !state.list.answers || !state.list.answers.length) {
			return;
		}

		//Find index of specific object using findIndex method.
		let objIndex = findElIndex(state.list.answers, "id", payload.id);

		// If index found then update the data at index.
		if (objIndex !== -1) {
			Vue.set(state.list.answers, objIndex, payload);
		}
	},

	SET_CHOOSE_TOPIC_CURRENT_QUESTIONNAIRE: (state, payload) => {
		state.choose_topic_current_questionnaire = payload.questionnaire;
	},

	SET_SUB_QUESTIONNAIRES: (state, payload) => {
		state.sub_questionnaires = payload;
	},

	PUSH_INTO_SUB_QUESTIONNAIRES: (state, payload) => {
		state.sub_questionnaires.push(payload);
	},

	REMOVE_FROM_SUB_QUESTIONNAIRES: (state, payload) => {
		//Find index of specific object using findIndex method.
		let objIndex = findElIndex(
			state.sub_questionnaires,
			"id",
			payload.questionnaire_id,
		);

		// If index found then update the data at index.
		if (objIndex !== -1) {
			state.sub_questionnaires.splice(objIndex, 1);
		}
		// Also remove the edit mode of this questionnaire
		delete state.sub_questionnaires_edit_modes[payload.questionnaire_id];
	},

	MANAGE_SUB_QUESTIONNAIRE_EDIT_MODE: (state, payload) => {
		Vue.set(state.sub_questionnaires_edit_modes, payload.id, payload.status);
	},

	MARK_SUBTOPIC_SELECTED: (state, payload) => {
		state.prioritization_selected_subtopic_id = payload.subtopic_id;
	},

	UPDATE_PRIORITIZATION_CURRENT_STEP: (state, payload) => {
		state.prioritization_current_step = payload.step;
	},

	RESET_STATE: (state) => {
		const s = initialState();
		Object.keys(s).forEach((key) => {
			state[key] = s[key];
		});
	},
};

// Export module
export const questionnaire = {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
