import { callApi } from "./manager";

export const process = {
	getProcessStatus: (payload) => {
		return callApi(
			`/infra/general?process=${payload.process}&step=${payload.step}`,
			"get",
		);
	},

	setProcessStatus: (payload) => {
		return callApi(
			`/infra/process_instance/${payload.process_status_id}/`,
			"put",
			payload,
		);
	},

	getRscGeneral: () => {
		return callApi(`/rsc/general`, "get");
	},

	getRqGeneral: () => {
		return callApi(`/rq/general`, "get");
	},

	convertHTMLToWord: (payload) => {
		return callApi(`/infra/html2doc/`, "post", payload);
	},
};
