export const interactNetw = [
	{
		path: "/research/interaction_and_networking",
		name: "InteractionsAndNetworking",
		component: () =>
			import(
				/* webpackChunkName:"page.rj-in"*/ "@/pages/research/modules/reflectJourney/stages/InteractAndNetw"
			),
		meta: {
			layout: "AppLayout",
			requiresAuth: true,
			header: {
				component: "InteractionAndNetworking",
			},
			footer: {
				component: "InteractionAndNetworking",
			},
		},
	},
];
