var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"value":_vm.dialog,"scrollable":"","width":"850px","persistent":""}},[_c('AppDialog',{attrs:{"heading":{
			label: 'app.book_searched_by_isbn',
		},"action":{
			label: 'app.import',
			event: 'import',
		}},on:{"close":function($event){return _vm.$emit('close')},"import":function($event){return _vm.importBook()}},scopedSlots:_vm._u([{key:"dialog-content",fn:function(){return [_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"sm":"3","align":"center"}},[_c('v-img',{attrs:{"src":_vm.volumeInfo.imageLinks && _vm.volumeInfo.imageLinks.thumbnail
								? _vm.volumeInfo.imageLinks.thumbnail
								: require("@/assets/images/error.png"),"contain":"","aspect-ratio":"1.1"}})],1),_c('v-col',[_c('v-card-text',[_c('div',{staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.volumeInfo.title)+" ")]),_c('div',{staticClass:"subtitle-1"},[_c('read-more',{attrs:{"more-str":_vm.$t('app.read_more'),"text":_vm.volumeInfo.description && '  ',"less-str":_vm.$t('app.read_less'),"max-chars":100}})],1)])],1)],1),_c('v-row',{staticClass:"mt-3",attrs:{"no-gutters":""}},_vm._l((_vm.bookData),function(item,index){return _c('v-col',{key:index,attrs:{"sm":"4"}},[_c('v-card',{attrs:{"outlined":"","tile":""}},[_c('v-card-text',{staticClass:"subtitle-1 black--text"},[_c('b',[_vm._v(_vm._s(_vm.$t(item.key)))]),_c('div',{domProps:{"innerHTML":_vm._s(item.value)}})])],1)],1)}),1),_c('v-alert',{staticClass:"mt-10 font-weight-bold",attrs:{"type":"warning","text":"","border":"top"}},[_vm._v(" "+_vm._s(_vm.$t("app.msg.book_info_override"))+" ")])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }