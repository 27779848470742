<template>
	<v-dialog :value="value" persistent max-width="600">
		<v-card flat height="500">
			<v-container fill-height fluid>
				<v-row align="center" justify="center">
					<v-col align="center">
						<v-card-text>
							<v-img
								width="80"
								:src="require('@/assets/images/clock.png')"
							></v-img>
						</v-card-text>
						<v-card-text class="text-h4 font-weight-bold" align="center">
							{{ $t("app.your_session_expired") }}
						</v-card-text>
						<v-card-text class="title py-0">
							{{ $t("app.about_to_logout") }}
						</v-card-text>
						<v-card-text class="text-h4 error--text">
							{{ timerCount }} {{ $t("app.seconds") }}
						</v-card-text>
					</v-col>
				</v-row>
			</v-container>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	name: "TheIdle",

	props: {
		value: {
			required: true,
		},
	},

	data() {
		return {
			timerCount: null,
		};
	},

	watch: {
		value(newVal) {
			if (newVal) {
				this.timerCount = 5;
			}
		},

		timerCount: {
			handler(value) {
				if (value != null) {
					if (value > 0) {
						setTimeout(() => {
							this.timerCount--;
						}, 1000);
					} else {
						this.logout();
					}
				}
			},
			immediate: true, // This ensures the watcher is triggered upon creation
		},
	},

	methods: {
		async logout() {
			this.$emit("close");
			await this.$store.dispatch("auth/logout");
		},
	},
};
</script>

<style></style>
