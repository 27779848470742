import { researchPaper as API } from "@/services/api";
import { getField, updateField } from "vuex-map-fields";

// INITIATE STATE
function initialState() {
	return {
		template: [],
		current_tab: "tab-toc",
	};
}

// STATE
const state = initialState();

// GETTERS
const getters = {
	getField,
};

// ACTIONS
const actions = {
	async getTemplate(context, payload) {
		let response = await API.getOfStudent(payload);
		context.commit("SET_TEMPLATE", response);
		return response;
	},
};

// MUTATIONS
const mutations = {
	updateField,
	SET_TEMPLATE: (state, payload) => {
		state.template = payload;
	},

	RESET_STATE: (state) => {
		const s = initialState();
		Object.keys(s).forEach((key) => {
			state[key] = s[key];
		});
	},
};

// Export module
export const toolsResearchPaper = {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
