import { chooseResearchQuestionPlan } from "./stages/confirm_timeline";
import { chooseTheme } from "./stages/choose_theme";
import { chooseTopic } from "./stages/choose_topic";
import { gatherInformation } from "./stages/gather_information";
import { chooseQuestion } from "./stages/choose_question";
import { defineQuestionSummary } from "./stages/summary";

export const defineQuestionRoutes = [
	...chooseResearchQuestionPlan,
	...chooseTheme,
	...chooseTopic,
	...gatherInformation,
	...chooseQuestion,
	...defineQuestionSummary,
];
