<template>
	<v-card flat color="transparent" align="end">
		<v-icon
			v-if="show_img"
			color="wr_indigo_2"
			class="cursor-wr"
			@click="dialog = true"
		>
			$vuetify.icons.values.logout
		</v-icon>

		<AppButton
			v-else
			label="app.logout"
			icon="power"
			color="red lighten-5"
			:prop_class="['error--text', 'font-weight-bold']"
			@click="dialog = true"
		></AppButton>

		<!-- Confirmation box for delete operation -->
		<TheConfirmation
			v-if="dialog"
			v-model="dialog"
			:dialog="dialog"
			warning_desc="app.sure_logout_warning"
			yes_btn_label="app.yes"
			no_btn_label="app.no"
			@cancel="dialog = false"
			@confirm="logout()"
		></TheConfirmation>
	</v-card>
</template>

<script>
import TheConfirmation from "@/components/layout/TheConfirmation";
import { mapGetters } from "vuex";

export default {
	name: "TheLogout",

	props: {
		show_img: {
			default: false,
		},
	},

	data() {
		return {
			dialog: false,
		};
	},

	components: {
		TheConfirmation,
	},

	computed: {
		...mapGetters({
			userLang: "user/getLanguage",
		}),
	},

	methods: {
		async logout() {
			try {
				this.$loader.start();

				// Complete logout action
				await this.$store.dispatch("auth/logout");

				// Clear cookies
				this.$cookies.set("token", null, location.hostname);
				this.$cookies.set(
					location.protocol == "http" ? "prefsHttp" : "prefs",
					null,
					location.hostname,
				);

				// Redirect to home route with language query param
				this.$router.push({
					name: "Home",
					query: {
						lang: this.userLang,
					},
				});
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},
	},
};
</script>
