export const settings = [
	{
		path: "/research/user/settings",
		name: "UserSettings",
		component: () =>
			import(
				/* webpackChunkName:"page.settings"*/ "@/pages/users/student/settings/Index.vue"
			),
		meta: {
			layout: "AppLayout",
			requiresAuth: true,
			header: false, // This case is handled in header component itself.
		},
	},
];
