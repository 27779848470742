import AppStepsProgress from "@/components/ui/AppStepsProgress";

export const footerStepsMixin = {
	data() {
		return {
			progress_step: null,
		};
	},

	components: {
		AppStepsProgress,
	},

	mounted() {
		this.updateProgressStep();
	},

	watch: {
		current_step(newVal, oldVal) {
			if (newVal && newVal !== oldVal) {
				this.updateProgressStep();
			}
		},
	},

	methods: {
		updateProgressStep() {
			try {
				if (this.current_step) {
					this.progress_step = this.current_step.value;
				}
			} catch (error) {
				this.$announce.error(error);
			}
		},
	},
};
