import { gatherSourceResearchPlan } from "./stages/confirm_timeline";
import { planSearchStrategy } from "./stages/plan_search_strategy";
import { createSourceList } from "./stages/create_source_list";
import { sourceDocumentation } from "./stages/source_documentation";
import { sourceEvaluation } from "./stages/source_evaluation";
import { sourceSelection } from "./stages/source_selection";
import { gatherSourcesSummary } from "./stages/summary";

export const gatherSourcesRoutes = [
	...gatherSourceResearchPlan,
	...planSearchStrategy,
	...createSourceList,
	...sourceDocumentation,
	...sourceEvaluation,
	...sourceSelection,
	...gatherSourcesSummary,
];
