<template>
	<v-container fill-height fluid>
		<v-row justify="center">
			<v-col align="center">
				<v-card width="900" height flat>
					<v-card-text>
						<v-row no-gutters>
							<v-col sm="4">
								<v-container class="py-0" fill-height fluid>
									<v-row justify="center">
										<v-col align="center">
											<v-img
												:src="require('@/assets/images/access-denied.png')"
											></v-img>
										</v-col>
									</v-row>
								</v-container>
							</v-col>
							<v-col>
								<v-card-text>
									<div
										class="headline wr_indigo_2--text text--darken-3 font-weight-bold"
									>
										YOU SHALL NOT PASS
									</div>
									<div
										class="mt-2 display-4 info--text text-lighten-1 font-weight-bold"
									>
										403
									</div>
									<div class="mt-2 subtitle-1 brown--text text--darken-3">
										We are sorry, but you do not have access to this page.<br />
										Please ask your administration to access this feature.
									</div>
									<div class="mt-6">
										<AppButton
											label="Go Back"
											color="error"
											@click="redirectToDashboard()"
										></AppButton>
									</div>
								</v-card-text>
							</v-col>
						</v-row>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import { mapGetters } from "vuex";

export default {
	name: "AccessDenied",

	computed: {
		...mapGetters({
			isMentor: "user/isMentor",
		}),
	},

	methods: {
		redirectToDashboard() {
			try {
				this.$router.push({
					name: this.isMentor ? "MentorDashboard" : "UserDashboard",
				});
			} catch (error) {
				this.$announce.error(error);
			}
		},
	},
};
</script>
