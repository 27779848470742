import AppDialog from "@/components/ui/AppDialog.vue";
import { mapGetters } from "vuex";
import { removeHtmlTags } from "@/utils/helpers";

export const textToSpeechMixin = {
	props: {
		text: {
			required: true,
		},
		prop_lang: {
			required: false,
		},
	},

	data() {
		return {
			header_label: "Preparing",
			paused: false,
			end: false,
			audio: null,
		};
	},

	components: {
		AppDialog,
	},

	computed: {
		...mapGetters({
			locale: "globals/getLocale",
		}),

		textToSpeak() {
			// Remove HTML tags, and symbols other than ,.", etc.
			return removeHtmlTags(this.text).replace(/[&\/\\#()~'":*?<>{}]/g, "");
		},

		aptAction() {
			let label = null;
			let event = null;
			// If speech is end, show play again button
			if (this.end) {
				label = "app.play_again";
				event = "play-again";
			}
			// Else, show pause, resume button accordingly
			else {
				label = this.paused ? "app.resume" : "app.pause";
				event = this.paused ? "resume" : "pause";
			}
			return { label, event };
		},
	},

	methods: {
		getLocale() {
			switch (this.prop_lang ? this.prop_lang : this.locale) {
				case "en":
					return "en-US";
				case "he":
					return "he-IL";
				case "ar":
					return "ar-XA";
			}
		},

		async start(play_again = false) {
			try {
				this.end = false;

				// Play new audio only if its starting
				if (!play_again) {
					let response = await this.$store.dispatch("tts/create", {
						text: this.textToSpeak,
						lang: this.getLocale(),
					});

					this.audio = new Audio(
						this.$environments.base_api_url + response.attachment,
					);
				}

				this.audio.play();

				this.audio.addEventListener("ended", () => {
					this.end = true;
				});
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		pause() {
			try {
				this.audio.pause();
				this.paused = true;
			} catch (error) {
				this.$announce.error(error);
			}
		},

		resume() {
			try {
				this.audio.play();
				this.paused = false;
			} catch (error) {
				this.$announce.error(error);
			}
		},

		stop() {
			try {
				this.audio.pause();
				this.audio.currentTime = 0;
			} catch (error) {
				this.$announce.error(error);
			}
		},
	},
};
