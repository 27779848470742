import { getField, updateField } from "vuex-map-fields";
import { standardMindmap as API } from "@/services/api";
import { findElIndex, generateRandomColor } from "@/utils/helpers";

// INITIATE STATE
function initialState() {
	return {
		arrows: [],
		// FIXME:: Temporary data
		table_chart_title: null,
		table_chart_columns: [
			{
				title: "Important Dates",
				color: "#FFF2CC",
				terms: [
					{
						text: null,
						fav: false,
					},
					{
						text: null,
						fav: false,
					},
				],
			},
			{
				title: "Events",
				color: "#DDEBF7",
				terms: [
					{
						text: null,
						fav: false,
					},
					{
						text: null,
						fav: false,
					},
				],
			},
			{
				title: "People",
				color: "#E2EFDA",
				terms: [
					{
						text: null,
						fav: false,
					},
					{
						text: null,
						fav: false,
					},
				],
			},
		],
	};
}

// STATE
const state = initialState();

// GETTERS
const getters = {
	getField,
};

// ACTIONS
const actions = {
	async list(context, payload) {
		return await API.list(payload);
	},

	async create(context, payload) {
		return await API.create(payload);
	},

	async save(context, payload) {
		return await API.save(payload);
	},

	async delete(context, payload) {
		return await API.delete(payload);
	},
};

// MUTATIONS
const mutations = {
	updateField,
	// FIXME:: Those arrow functions are temporary for one time
	ADD_ARROW: (state, payload) => {
		state.arrows.push(payload.arrow);
	},

	UPDATE_ARROW: (state, payload) => {
		// Find particular note from board's data
		let el_index = findElIndex(state.arrows, "id", payload.arrow_id);
		if (el_index == -1) return;

		let item = state.arrows[el_index];

		// Measurement params
		let props = ["x", "y", "w", "h", "angle"];

		props.forEach((prop) => {
			if (item[prop] !== payload.event[prop]) {
				item[prop] = payload.event[prop];
			}
		});
	},

	REMOVE_ARROW: (state, payload) => {
		// Find particular note from board's data
		let el_index = findElIndex(state.arrows, "id", payload.arrow_id);
		if (el_index == -1) return;

		state.arrows.splice(el_index, 1);
	},

	RESET_ARROWS: (state) => {
		if (state.arrows.length) {
			state.arrows = [];
		}
	},

	// FIXME:: Those table chart functions are temporary for one time
	ADD_COLUMN: (state) => {
		let terms = [];
		for (
			let index = 0;
			index < state.table_chart_columns[0].terms.length;
			index++
		) {
			terms.push({
				text: null,
				fav: false,
			});
		}

		let column = {
			title: null,
			color: generateRandomColor(),
			terms: terms,
		};
		state.table_chart_columns.push(column);
	},

	REMOVE_COLUMN: (state, payload) => {
		state.table_chart_columns.splice(payload.index, 1);
	},

	ADD_ROW: (state) => {
		state.table_chart_columns.forEach((column) => {
			column.terms.push({
				text: null,
				fav: false,
			});
		});
	},

	REMOVE_ROW: (state, payload) => {
		state.table_chart_columns.forEach((column) => {
			column.terms.splice(payload.index, 1);
		});
	},

	UPDATE_COLUMN: (state, payload) => {
		state.table_chart_columns[payload.index].title = payload.title;
	},

	UPDATE_TERM: (state, payload) => {
		state.table_chart_columns[payload.colIndex].terms[payload.index][
			payload.property
		] = payload.value;
	},

	RESET_STATE: (state) => {
		const s = initialState();
		Object.keys(s).forEach((key) => {
			state[key] = s[key];
		});
	},
};

// Export module
export const mindmap = {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
