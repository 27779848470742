import { tag as API } from "@/services/api";
import { getField, updateField } from "vuex-map-fields";
import { findFromArray, filterArray, findElIndex } from "@/utils/helpers";

// INITIATE STATE
function initialState() {
	return {
		meetings: [],

		intr_netw_current_step: null,

		set_priority_current_step: null,

		aspects: [
			{
				title: "Difficulty level",
			},
			{
				title: "Interest level",
			},
		],

		subtopics: [
			{
				answers: [
					{
						value: null,
					},
					{
						value: null,
					},
					{
						value: null,
					},
				],
			},
			{
				answers: [
					{
						value: null,
					},
					{
						value: null,
					},
					{
						value: null,
					},
				],
			},
		],
	};
}

// STATE
const state = initialState();

// GETTERS
const getters = {
	getField,
	getMeeting: (state) => (meeting_id) => {
		return findFromArray(state.meetings, "id", meeting_id);
	},
};

// ACTIONS
const actions = {
	async list(context, payload) {
		let response = await API.list(payload);
		context.commit("SET_LIST", response);
		return response;
	},
};

// MUTATIONS
const mutations = {
	updateField,

	SAVE_PLAN_INTERVIEW: (state, payload) => {
		state.plan_interview = payload.data;
	},

	ADD_INTERVIEWEE: (state, payload) => {
		state.plan_interview.interviewees.push(payload);
	},

	ADD_QUESTION: (state, payload) => {
		state.plan_interview.interview_questions.push(payload);
	},

	ADD_ADDITIONAL_DETAIL: (state, payload) => {
		state.plan_interview.additional_details.push(payload);
	},

	REMOVE_QUESTION: (state, payload) => {
		state.plan_interview.interview_questions.splice(payload.index, 1);
	},

	REMOVE_ADDITIONAL_ID: (state, payload) => {
		let index = findElIndex(
			state.plan_interview.additional_details,
			"id",
			payload.id,
		);
		state.plan_interview.additional_details.splice(index, 1);
	},

	REMOVE_INTERVIEWEE: (state, payload) => {
		state.plan_interview.interviewees.splice(payload.index, 1);
	},

	ADD_MEETING_SUMMARY: (state, payload) => {
		state.meetings.push(payload.meeting);
	},

	UPDATE_MEETING: (state, payload) => {
		let meeting = findFromArray(state.meetings, "id", payload.meeting_id);
		meeting = payload.meeting;
	},

	ADD_TODO_TASK: (state, payload) => {
		let meeting = findFromArray(state.meetings, "id", payload.meeting_id);
		meeting.todos.push(payload.data);
	},

	ADD_AGENDA: (state, payload) => {
		let meeting = findFromArray(state.meetings, "id", payload.meeting_id);
		meeting.agendas.push(payload.agenda);
	},

	REMOVE_TODO_TASK: (state, payload) => {
		let meeting = findFromArray(state.meetings, "id", payload.meeting_id);
		meeting.todos.splice(payload.index, 1);
	},

	REMOVE_AGENDA: (state, payload) => {
		let meeting = findFromArray(state.meetings, "id", payload.meeting_id);
		meeting.agendas.splice(payload.index, 1);
	},

	DELETE_MEETING: (state, payload) => {
		state.meetings = filterArray(state.meetings, "id", payload.meeting_id);
	},

	ADD_SUBTOPIC: (state, payload) => {
		let arr = [];
		for (let index = 0; index < state.aspects.length + 1; index++) {
			arr.push({
				value: null,
			});
		}
		state.subtopics.push({
			answers: arr,
		});
	},

	ADD_ASPECT: (state, payload) => {
		// Push aspect
		state.aspects.push({
			title: "New Aspect",
		});
		// Push one new answer in each subtopic
		state.subtopics.forEach((subtopic) => {
			subtopic.answers.push({
				value: null,
			});
		});
	},

	REMOVE_ASPECT: (state, payload) => {
		state.aspects.splice(payload.index, 1);

		// Remove one answer of that aspect from each subtopic too.
		state.subtopics.forEach((subtopic) => {
			subtopic.answers.splice(payload.index + 1, 1);
		});
	},

	REMOVE_SUBTOPIC: (state, payload) => {
		state.subtopics.splice(payload.index, 1);
	},

	SAVE_SUBTOPIC: (state, payload) => {
		state.subtopics[payload.index] = payload.subtopic;
	},

	RESET_STATE: (state) => {
		const s = initialState();
		Object.keys(s).forEach((key) => {
			state[key] = s[key];
		});
	},
};

// Export module
export const temporary = {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
