<template></template>

<script>
import Vue from "vue";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
Vue.use(Toast, {
	transition: "Vue-Toastification__fade",
	maxToasts: 20,
	newestOnTop: true,
});

export default {
	name: "LayoutEventBus",

	computed: {
		config() {
			return {
				position: "top-center",
				timeout: 5000,
				closeOnClick: true,
				pauseOnFocusLoss: true,
				pauseOnHover: true,
				draggable: false,
				draggablePercent: 0.6,
				showCloseButtonOnHover: false,
				hideProgressBar: true,
				closeButton: "button",
				icon: true,
				rtl: this.$vuetify.rtl,
			};
		},
	},

	created() {
		this.$eventBus.$on("announce", (payload) => {
			this.$toast(this.$t(payload.message), {
				type: payload.type,
				...this.config,
			});
		});
	},
};
</script>
