import { callApi } from "./manager";

export const sourceNote = {
	list: (payload) => {
		return callApi(
			`/rsc/user_source_note?source_id=${payload.source_id}`,
			"get",
		);
	},

	create: (payload) => {
		return callApi(`/rsc/user_source_note/`, "post", payload);
	},

	update: (payload) => {
		return callApi(
			`/rsc/user_source_note/${payload.source_note_id}/`,
			"put",
			payload,
		);
	},

	delete: (payload) => {
		return callApi(
			`/rsc/user_source_note/${payload.source_note_id}/`,
			"delete",
		);
	},
};
